'use client';

import {FC, memo, useCallback, useEffect} from 'react';
import {Button} from '@nextui-org/button';
import {Divider} from '@nextui-org/divider';
import {msg, Trans} from '@lingui/macro';
// import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
// import GoogleSvg from '@/assets/socials/auth/google.svg';
// import AppleSvg from '@/assets/socials/auth/apple.svg';
import MetaSvg from '@/assets/socials/auth/meta.svg';
import {motion} from 'framer-motion';
import {useAnalytics} from '@/common/analytics';
import {
  useAuthContext,
  useIsInAppBrowser,
  useSearchParamsContext,
} from '@/hooks';
import LoginEmail from './LoginEmail';
import {setParamsAfterLogin} from '@/utils';
import {META_LOGIN} from '@/common/mutations';
import {useLingui} from '@lingui/react';

// const GOOGLE_LOGIN = gql(`
//   mutation GoogleLogin {
//     href: googleLogin
//   }
// `);

// const APPLE_LOGIN = gql(`
//   mutation AppleLogin {
//     href: appleLogin
//   }
// `);

const LoginOptions: FC = memo(() => {
  const {_} = useLingui();
  const {reportMetaAuthStart} = useAnalytics();
  // const {reportAppleAuthStart, reportGoogleAuthStart, reportMetaAuthStart} =
  //   useAnalytics();
  const {setSignupEmail} = useAuthContext();
  const {searchParams} = useSearchParamsContext();
  const {isInApp} = useIsInAppBrowser();
  const onCompleted = (data: {href: string}) => {
    if (data.href) {
      window.location.href = data.href;
    }
  };
  // const [googleLoginMutation, {loading: googleLoading}] = useMutation(
  //   GOOGLE_LOGIN,
  //   {onCompleted},
  // );
  const [metaLoginMutation, {loading: metaLoading}] = useMutation(META_LOGIN, {
    onCompleted,
  });
  // const [appleLoginMutation, {loading: appleLoading}] = useMutation(
  //   APPLE_LOGIN,
  //   {onCompleted},
  // );

  const setInitialParams = useCallback(() => {
    setParamsAfterLogin(searchParams);
  }, [searchParams]);

  useEffect(() => {
    setSignupEmail('');
  }, []);

  return (
    <div className="flex w-full flex-col gap-8">
      {!isInApp && (
        <>
          <motion.div
            key="social-login"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className="flex w-full shrink-0 flex-col gap-4">
            {/* <Button
              aria-label={_(msg`Continue with Google`)}
              data-amp-track-label={`Continue with Google`}
              fullWidth
              variant="solid"
              color="primary"
              radius="sm"
              isDisabled={googleLoading}
              startContent={<img src={GoogleSvg} alt="Continue with Google" />}
              onPress={() => {
                setInitialParams();
                reportGoogleAuthStart().finally(() => {
                  googleLoginMutation();
                });
              }}
              className="h-auto items-center gap-3 border border-foreground/10 bg-background px-4 py-2 text-sm font-medium text-foreground">
              <span>
                <Trans>Continue with Google</Trans>
              </span>
            </Button> */}
            <Button
              aria-label={_(msg`Continue with Meta`)}
              data-amp-track-label={`Continue with Meta`}
              fullWidth
              variant="solid"
              color="primary"
              radius="sm"
              isDisabled={metaLoading}
              startContent={
                <div className="h-4 w-7">
                  <img
                    className="object-contain"
                    src={MetaSvg}
                    alt="Continue with Meta"
                  />
                </div>
              }
              onPress={() => {
                setInitialParams();
                reportMetaAuthStart().finally(() => {
                  metaLoginMutation();
                });
              }}
              className="h-auto items-center gap-3 border border-foreground/10 bg-background px-4 py-2 text-sm font-medium text-foreground">
              <span>
                <Trans>Continue with Meta</Trans>
              </span>
            </Button>
            {/* <Button
              aria-label={_(msg`Continue with Apple`)}
              data-amp-track-label={`Continue with Apple`}
              fullWidth
              variant="solid"
              color="primary"
              radius="sm"
              isDisabled={appleLoading}
              startContent={<img src={AppleSvg} alt="Continue with Apple" />}
              onPress={() => {
                setInitialParams();
                reportAppleAuthStart().finally(() => {
                  appleLoginMutation();
                });
              }}
              className="h-auto items-center gap-3 border border-foreground/10 bg-background px-4 py-2 text-sm font-medium text-foreground">
              <span>
                <Trans>Continue with Apple</Trans>
              </span>
            </Button> */}
          </motion.div>
          <div className="flex items-center gap-3">
            <Divider className="flex-1 bg-foreground/[0.08]" />
            <span className="text-sm text-foreground/50">or</span>
            <Divider className="flex-1 bg-foreground/[0.08]" />
          </div>
        </>
      )}
      <LoginEmail />
    </div>
  );
});

LoginOptions.displayName = 'LoginMain';

export default LoginOptions;
