'use client';

import {memo, useCallback, useEffect, useState} from 'react';
import {Button} from '@nextui-org/button';
import {msg, Trans} from '@lingui/macro';
import {useMutation} from '@apollo/client';
import {INIT_SIGNUP} from '@/common/mutations';
import {useAuthContext} from '@/hooks';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import PortalSpinner from '@/components/PortalSpinner';
import {LS_USER_COUPON_TYPE} from '@/config';
import {useLingui} from '@lingui/react';

const RESEND_DELAY = 59;

const LoginSignUpInvited = memo(() => {
  const {_} = useLingui();
  const [seconds, setSeconds] = useState(RESEND_DELAY);
  const location = useLocation();
  const {signupEmail, setSignupEmail} = useAuthContext();
  const [initSignup, {loading}] = useMutation(INIT_SIGNUP);
  const navigate = useNavigate();
  const isCurrentPath = location.pathname.endsWith('/login/invited');

  const onSubmit = useCallback(async () => {
    await initSignup({
      variables: {
        input: {
          email: signupEmail,
          qs: location.search,
          basePath: location.pathname.replace('/login/invited', ''),
          stripeCouponType: localStorage.getItem(LS_USER_COUPON_TYPE),
        },
      },
    });
  }, [signupEmail, location.search, location.pathname, initSignup]);

  const resendEmail = useCallback(async () => {
    if (signupEmail) {
      setSeconds(RESEND_DELAY);
      await onSubmit();
    }
  }, [signupEmail, onSubmit]);

  const goBack = useCallback(async () => {
    navigate('../login');
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0 && signupEmail) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [signupEmail, seconds]);

  useEffect(() => {
    return () => {
      setSignupEmail('');
    };
  }, []);

  if (!signupEmail && isCurrentPath) {
    return <Navigate to="../login" />;
  }

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="flex flex-col items-center gap-3">
        <div className="text-xl font-medium text-foreground">
          <Trans>Check your email</Trans>
        </div>
        <div className="flex w-full flex-col text-sm text-foreground">
          <div className="text-center text-foreground/60">
            <Trans>
              We just sent you an email to{' '}
              <span className="font-medium text-foreground">{signupEmail}</span>
              . It has a magic link to sign you up.
            </Trans>
          </div>
        </div>
      </div>
      <div className="flex min-h-[5.25rem] w-full flex-col items-center gap-12">
        <div className="flex w-full justify-center gap-2 text-foreground/60">
          {seconds > 0 ? (
            <Trans>
              Didn’t get the email? Resend in 0:
              {seconds.toString().padStart(2, '0')}
            </Trans>
          ) : (
            <div className="flex w-full flex-col gap-4">
              <Button
                aria-label={_(msg`Resend`)}
                data-amp-track-label={`Resend`}
                fullWidth
                variant="solid"
                color="primary"
                radius="sm"
                disableAnimation
                disableRipple
                className="font-medium text-background"
                spinner={<PortalSpinner size="sm" />}
                isLoading={loading}
                isDisabled={loading}
                onPress={resendEmail}>
                <Trans>Resend</Trans>
              </Button>
              <Button
                aria-label={_(msg`Back`)}
                data-amp-track-label={`Back`}
                variant="solid"
                radius="sm"
                disableAnimation
                disableRipple
                className="w-auto self-center bg-transparent font-medium"
                spinner={<PortalSpinner size="sm" />}
                isLoading={loading}
                isDisabled={loading}
                onPress={goBack}>
                <Trans>Back</Trans>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

LoginSignUpInvited.displayName = 'LoginSignUpInvited';

export default LoginSignUpInvited;
