'use client';

import {useAnalyticsContext, useAppContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import {memo, useEffect, useRef} from 'react';
import {Navigate, useNavigate, useResolvedPath} from 'react-router-dom';
import {
  decodeAuthParam,
  deleteParamsAfterLogin,
  getParamsAfterLogin,
} from '@/utils';
import {useAnalytics} from '@/common/analytics';

const AuthRedirect = memo(() => {
  const instrumentTracker = useAnalyticsContext();
  const {
    reportAppleSignIn,
    reportAppleSignUp,
    reportGoogleSignIn,
    reportGoogleSignUp,
    reportMetaSignIn,
    reportMetaSignUp,
  } = useAnalytics();
  const navigate = useNavigate();
  const {isAuthenticated, login} = useAppContext();
  const paramsAfterAuthRef = useRef('');
  const {pathname} = useResolvedPath('../..');

  useEffect(() => {
    if (!instrumentTracker) {
      return;
    }

    const url = new URL(location.href);
    const urlParams = new URLSearchParams(url.search);
    const extraParams = getParamsAfterLogin();
    const errorParam = urlParams.get('error');

    if (errorParam) {
      console.error(errorParam);
      urlParams.delete('error');
    }

    const authParam = urlParams.get('auth');
    const authInfo = authParam ? decodeAuthParam(authParam) : null;

    (authInfo?.id
      ? instrumentTracker.setUserId(authInfo?.id)
      : Promise.resolve()
    ).then(() => {
      if (extraParams) {
        for (const [key, value] of extraParams.entries()) {
          urlParams.set(key, value);
        }
      }

      urlParams.delete('auth');

      paramsAfterAuthRef.current = urlParams.toString();

      deleteParamsAfterLogin();

      if (!authInfo) {
        if (isAuthenticated) {
          navigate({
            pathname,
            search: paramsAfterAuthRef.current,
          });
        } else {
          navigate('/');
        }

        return;
      }

      if (authInfo.signup) {
        switch (authInfo.provider) {
          case 'google':
            reportGoogleSignUp();
            break;
          case 'apple':
            reportAppleSignUp();
            break;
          case 'meta':
            reportMetaSignUp();
            break;
        }
      } else {
        switch (authInfo.provider) {
          case 'google':
            reportGoogleSignIn();
            break;
          case 'apple':
            reportAppleSignIn();
            break;
          case 'meta':
            reportMetaSignIn();
            break;
        }
      }

      login(authInfo);
    });
  }, [instrumentTracker]);

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname,
          search: paramsAfterAuthRef.current,
        }}
      />
    );
  }

  return (
    <PortalSpinner
      classNames={{
        base: 'backdrop-blur-sm fixed inset-0 z-40',
      }}
      size="lg"
    />
  );
});

AuthRedirect.displayName = 'AuthRedirect';

export default AuthRedirect;
