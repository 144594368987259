import {memo, useEffect, useState} from 'react';
import Particles, {initParticlesEngine} from '@tsparticles/react';
import {loadSnowPreset} from '@tsparticles/preset-snow';
import {loadImageShape} from '@tsparticles/shape-image';
import {loadEmittersPlugin} from '@tsparticles/plugin-emitters';
import type {ISourceOptions, Engine} from '@tsparticles/engine';
import confetti1 from './images/1.svg';
import confetti2 from './images/2.svg';
import confetti3 from './images/3.svg';
import confetti4 from './images/4.svg';
import confetti5 from './images/5.svg';
import confetti6 from './images/6.svg';
import confetti7 from './images/7.svg';
import confetti8 from './images/8.svg';
import {useMatchesTWBreakpoint} from '@/hooks';

const Confetti = memo(() => {
  const [init, setInit] = useState(false);
  const isMDScreen = useMatchesTWBreakpoint('md');

  useEffect(() => {
    initParticlesEngine(async (engine: Engine) => {
      await loadEmittersPlugin(engine);
      await loadSnowPreset(engine);
      await loadImageShape(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = {
    autoPlay: true,
    background: {
      opacity: 0,
    },
    particles: {
      size: {
        value: {min: 3, max: 8},
      },
      number: {
        value: 0,
        density: {
          enable: false,
        },
      },
      move: {
        enable: true,
        speed: 30,
        decay: isMDScreen
          ? {
              min: 0.004,
              max: 0.008,
            }
          : {
              min: 0.005,
              max: 0.01,
            },
        direction: 'bottom',
        outModes: {
          default: 'none',
        },
      },
      opacity: {
        value: {min: 0.8, max: 1},
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        animation: {
          enable: true,
          speed: 60,
        },
      },
      tilt: {
        direction: 'random',
        enable: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: 60,
        },
      },
      roll: {
        darken: {
          enable: true,
          value: 25,
        },
        enable: true,
        speed: {
          min: 15,
          max: 25,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        speed: {
          min: -15,
          max: 15,
        },
      },
      shape: {
        type: 'image',
        options: {
          image: [
            {src: confetti1},
            {src: confetti2},
            {src: confetti3},
            {src: confetti4},
            {src: confetti5},
            {src: confetti6},
            {src: confetti7},
            {src: confetti8},
          ],
        },
      },
    },
    detectRetina: true,
    emitters: [
      {
        direction: 'bottom',
        position: {
          x: 50,
          y: isMDScreen ? -75 : -50,
        },
        rate: {
          quantity: 8,
          delay: 0.05,
          animation: {
            enable: true,
            speed: 10,
            destroy: 'none',
            startValue: 'max',
            endValue: 'min',
            minimumValue: 3,
            sync: false,
          },
        },
        life: {
          duration: 0.5,
          count: 5,
          delay: 0,
        },
        particles: {
          inherit: true,
        },
      },
    ],
  };

  if (!init) {
    return null;
  }

  return <Particles id="tsparticles" options={options} />;
});

Confetti.displayName = 'Confetti';

export default Confetti;
