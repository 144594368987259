'use client';

import {ReactNode, useMemo} from 'react';
import {
  AmplitudeTransport,
  InstrumentTracker,
} from '@/common/instrumentTracker';
import {AnalyticsContext} from '@/contexts/AnalyticsContext';

export function AnalyticsProvider({children}: {children: ReactNode}) {
  const instrumentTracker = useMemo(
    () => new InstrumentTracker([new AmplitudeTransport()]),
    [],
  );

  return (
    <AnalyticsContext.Provider value={instrumentTracker}>
      {children}
    </AnalyticsContext.Provider>
  );
}
