'use client';

import {FC} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useToastQueue} from '@/hooks';
import Toast from './Toast';

const ToastQueue: FC = () => {
  const {toasts, removeToast} = useToastQueue();

  return (
    <ul className="fixed bottom-0 right-0 z-[1000] flex max-h-screen w-screen flex-col-reverse gap-3 md:w-full md:max-w-md">
      <AnimatePresence>
        {toasts.map(toast => (
          <motion.li
            className="shrink-0 overflow-hidden p-3"
            key={toast.id}
            initial={{height: '0', opacity: 0}}
            animate={{height: 'auto', opacity: 1}}
            exit={{height: '0', opacity: 0}}>
            <Toast toast={toast} onClose={() => removeToast(toast.id)} />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  );
};

export default ToastQueue;
