'use client';

import {PropsWithChildren, memo, useEffect, useMemo, useState} from 'react';
import {AuthContext} from '@/contexts';
import {gql} from '@/__generated__';
import {useQuery} from '@apollo/client';
import {useAnalyticsContext, useAppContext} from '@/hooks';
import {useAnalytics} from '@/common/analytics';

const GET_USER = gql(`
  query GetUser($userId: ID) {
    user: getUser(id: $userId) {
      id
      createdAt
      updatedAt
      deletedAt
      firstName
      lastName
      email
      phone
      newsletter
      description
      avatar
      verified
      stripeCustomerId
      authProvider
      authProviderId
      stripeCouponType
      stripeSubscription {
        id
        status
        nextPaymentAt
        name
        image
        currency
        amount
        interval
        intervalCount
      }
    }
  }
`);

export const AuthProvider = memo(({children}: PropsWithChildren) => {
  const instrumentTracker = useAnalyticsContext();
  const {identifyHSUser} = useAnalytics();
  const {isAuthenticated} = useAppContext();
  const [signupEmail, setSignupEmail] = useState('');
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const {data, loading} = useQuery(GET_USER, {
    skip: !isAuthenticated,
    fetchPolicy: 'network-only',
  });
  const value = useMemo(
    () => ({
      isLoading: loading,
      user: data?.user,
      isDemo: false,
      resetPasswordEmail,
      setResetPasswordEmail,
      signupEmail,
      setSignupEmail,
    }),
    [data?.user, loading, resetPasswordEmail, signupEmail],
  );

  useEffect(() => {
    if (!data?.user) {
      return;
    }

    identifyHSUser(data.user);
  }, [data?.user, instrumentTracker]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

AuthProvider.displayName = 'AuthProvider';
