'use client';

import {SVGProps} from 'react';

export default function FlipBackwardIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}>
      <path
        d="M4.49565 3.33175C4.72346 3.10394 4.72346 2.7346 4.49565 2.50679C4.26784 2.27899 3.8985 2.27899 3.67069 2.50679L1.33736 4.84013C1.10955 5.06793 1.10955 5.43728 1.33736 5.66508L3.67069 7.99842C3.8985 8.22622 4.26784 8.22622 4.49565 7.99842C4.72346 7.77061 4.72346 7.40126 4.49565 7.17346L3.15813 5.83594H9.62484C10.7524 5.83594 11.6665 6.75002 11.6665 7.8776C11.6665 9.00519 10.7524 9.91927 9.62484 9.91927H6.99984C6.67767 9.91927 6.4165 10.1804 6.4165 10.5026C6.4165 10.8248 6.67767 11.0859 6.99984 11.0859H9.62484C11.3968 11.0859 12.8332 9.64952 12.8332 7.8776C12.8332 6.10569 11.3968 4.66927 9.62484 4.66927H3.15813L4.49565 3.33175Z"
        fill="currentColor"
      />
    </svg>
  );
}
