// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/react';
import {SENTRY_DSN, SENTRY_OPT_OUT, ENVIRONMENT} from './config';
import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  enabled: !SENTRY_OPT_OUT,

  // Add optional integrations for additional features
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
    Sentry.browserProfilingIntegration(),
  ],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 0.01,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/\//, /https:\/\/app.portal\.ai/],

  // Set sampling rate for profiling
  // This is relative to tracesSampleRate
  profilesSampleRate: 0.5,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.01,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Ignore errors that match any of the following strings or regular expressions
  ignoreErrors: [
    // There's a setting in Instagram's WebView browser that autofills contact
    // forms, and users have been encountering an issue related to the
    // _AutofillCallbackHandler and __AutoFillPopupClose__.
    // The problem seems to arise because Instagram injects a script while
    // loading the website, which triggers an error on certain devices.
    // While some comments mention that this issue only occurs on pages with
    // forms, it can happen even if the page doesn’t have any forms.
    // Fortunately, this error does not affect user functionality.
    `Can't find variable: __AutoFillPopupClose__`,
    `Can't find variable: _AutofillCallbackHandler`,
  ],
});
