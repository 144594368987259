import {memo, useMemo} from 'react';
import {SingleNumberMetric as SingleNumberMetricType} from '../types';
import {twMerge} from 'tailwind-merge';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';
import TriangleSolidIcon from '@/components/icons/TriangleSolidIcon';

export const SingleNumberMetric = memo(
  ({
    metric,
    className,
  }: {
    metric: SingleNumberMetricType;
    className?: string;
  }) => {
    const {i18n, _} = useLingui();
    const unit = useMemo(() => {
      switch (metric.valueUnit) {
        case 'pcs':
          return _(msg`pcs.`);
        default:
          return null;
      }
    }, [metric.valueUnit, _]);
    const isOkTrend = metric.isInvertedTrend
      ? metric.trend <= 0
      : metric.trend >= 0;

    return (
      <div
        className={twMerge(
          'flex flex-col justify-between gap-3 text-sm',
          className,
        )}>
        <h1 className="text-xs text-foreground/50">{metric.title}</h1>
        <div className="flex flex-col gap-1">
          <div className="flex items-end gap-1">
            <span className="text-xl">
              {i18n.number(metric.value, metric.valueFormat)}
            </span>
            {unit && <span className="text-foreground/50">{unit}</span>}
          </div>
          <div
            className={twMerge(
              'flex items-center gap-1',
              isOkTrend ? 'text-success' : 'text-danger',
            )}>
            <TriangleSolidIcon
              className={twMerge(
                'h-2 w-2 shrink-0',
                metric.trend === 0
                  ? 'hidden'
                  : metric.trend < 0
                    ? 'rotate-180'
                    : '',
              )}
            />
            <span>
              {i18n.number(metric.trend, {
                style: 'percent',
                maximumFractionDigits: 1,
                signDisplay: 'never',
              })}
            </span>
          </div>
        </div>
      </div>
    );
  },
);

SingleNumberMetric.displayName = 'SingleNumberMetric';
