import {memo, useMemo} from 'react';
import {ComingSoonMetric as ComingSoonMetricType} from '../types';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import {twMerge} from 'tailwind-merge';
import {getIntegrationIcon} from '@/common/integrations';

export const ComingSoonMetric = memo(
  ({metric, className}: {metric: ComingSoonMetricType; className?: string}) => {
    const {_} = useLingui();
    const title = useMemo(() => {
      switch (metric.value) {
        case 'tiktok':
          return _(msg`TikTok`);
        default:
          return metric.value;
      }
    }, [metric.value, _]);

    return (
      <div
        className={twMerge(
          'flex flex-col items-center justify-center gap-2 text-sm',
          className,
          'border-[length:thin] border-[#19191A] bg-[#0D0D0D]',
        )}>
        <div className="h-5 w-5 shrink-0">
          <img
            className="object-contain"
            src={getIntegrationIcon(metric.value, 'small')}
            alt={metric.value}
          />
        </div>
        <div className="text-foreground/50">
          <h1 className="text-center">
            {title}
            <br />
            <Trans>Coming Soon!</Trans>
          </h1>
        </div>
      </div>
    );
  },
);

ComingSoonMetric.displayName = 'ComingSoonMetric';
