import {memo} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import AlertTriangle2Icon from '@/components/icons/AlertTriangle2Icon';

interface Props {
  isShown?: boolean;
  title: string;
  message: string | undefined;
}

const ErrorBanner = memo(({title, message, isShown = true}: Props) => {
  return (
    <AnimatePresence mode="wait">
      {isShown ? (
        <motion.div
          initial={{opacity: 0, scale: 0.9}}
          animate={{opacity: 1, scale: 1}}
          exit={{opacity: 0, scale: 0.9}}
          className="flex w-full shrink-0 gap-4 overflow-hidden rounded-2xl border border-[rgba(255,133,51,0.24)] bg-[rgba(255,128,0,0.10)] p-4">
          <AlertTriangle2Icon className="flex h-5 w-5 shrink-0 text-[rgb(255,81,0)]" />
          <div className="flex grow flex-col gap-1 text-sm">
            <div className="font-medium text-foreground">{title}</div>
            {message ? (
              <div className="text-foreground/60">{message}</div>
            ) : null}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
});

ErrorBanner.displayName = 'ErrorBanner';

export default ErrorBanner;
