import {memo} from 'react';
import {twMerge} from 'tailwind-merge';

type Props = {
  children: string | undefined;
  className?: string;
};

const FlowSettingLabel = memo(({children, className}: Props) => {
  return children ? (
    <div className={twMerge('text-sm text-foreground/50', className)}>
      {children}
    </div>
  ) : null;
});

FlowSettingLabel.displayName = 'FlowSettingLabel';

export default FlowSettingLabel;
