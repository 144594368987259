'use client';

import {useApollo} from '@/hooks/useApollo';
import {ReactNode, useMemo} from 'react';
import {ApolloProvider as ApolloProviderBase} from '@apollo/client';

export function ApolloProvider({children}: {children: ReactNode}) {
  const apolloClient = useApollo();
  const makeClient = useMemo(() => apolloClient, [apolloClient]);

  return (
    <ApolloProviderBase client={makeClient}>{children}</ApolloProviderBase>
  );
}
