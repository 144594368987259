'use client';

import {memo, useState} from 'react';
import {Button, ButtonProps} from '@nextui-org/button';
import {useAnalytics} from '@/common/analytics';
import {PressEvent} from '@react-types/shared';

const DELAY = 200;

const TrackButton = memo(({onPress, ...props}: ButtonProps) => {
  const {flushQueue} = useAnalytics();
  const [isPressing, setIsPressing] = useState(!onPress);
  const onPressHandler = (e: PressEvent) => {
    if (isPressing) {
      return;
    }

    setIsPressing(true);
    setTimeout(() => {
      flushQueue().finally(() => {
        setIsPressing(false);
        onPress!(e);
      });
    }, DELAY);
  };

  return <Button {...props} onPress={onPress ? onPressHandler : undefined} />;
});

TrackButton.displayName = 'TrackButton';

export default TrackButton;
