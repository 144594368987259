import {SkeletonProps, Skeleton as NextSkeleton} from '@nextui-org/react';
import {memo} from 'react';

interface Props extends SkeletonProps {
  direction?: 'vertical' | 'horizontal';
}

const Skeleton = memo(
  ({direction = 'vertical', className = '', ...props}: Props) => {
    if (direction === 'vertical') {
      return (
        <NextSkeleton
          className={`h-full w-full rounded-2xl bg-foreground/[0.08] before:-translate-y-full before:translate-x-0 before:animate-[shimmer-y_2s_infinite] before:border-none before:bg-gradient-to-b before:from-transparent before:via-foreground/[0.1] before:to-transparent after:bg-transparent ${className}`}
          {...props}
        />
      );
    }

    return (
      <NextSkeleton
        className={`h-full w-full rounded-2xl bg-foreground/[0.08] before:border-none before:from-transparent before:via-foreground/[0.1] before:to-transparent after:bg-transparent ${className}`}
        {...props}
      />
    );
  },
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
