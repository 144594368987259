import {memo} from 'react';
import {Metric as MetricType} from './types';
import {SingleNumberMetric} from './metrics/SingleNumberMetric';
import {ComingSoonMetric} from './metrics/ComingSoonMetric';
import {ConnectMetric} from './metrics/ConnectMetric';
import {AreaChartMetric} from './metrics/AreaChartMetric';
import {ConnectWhatsappMetric} from './metrics/ConnectWhatsappMetric';

const LiveMetric = memo(
  ({
    metric,
    ...props
  }: {
    className?: string;
    metric: MetricType;
    isCompact?: boolean;
  }) => {
    switch (metric.type) {
      case 'area_chart':
        return <AreaChartMetric {...props} metric={metric} />;
      case 'single_number':
        return <SingleNumberMetric {...props} metric={metric} />;
      case 'coming_soon':
        return <ComingSoonMetric {...props} metric={metric} />;
      case 'connect':
        return <ConnectMetric {...props} metric={metric} />;
      case 'connect_whatsapp':
        return <ConnectWhatsappMetric {...props} metric={metric} />;
      default:
        throw new Error('Unknown metric type');
    }
  },
);

LiveMetric.displayName = 'LiveMetric';

export default LiveMetric;
