'use client';

import {gql} from '@/__generated__';
import {useMutation} from '@apollo/client';
import PortalSpinner from '@/components/PortalSpinner';
import {FC, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {IntegrationType} from '@/common/types';
import {useAnalytics} from '@/common/analytics';

const CREATE_TIKTOK_INTEGRATION = gql(`
  mutation CreateTiktokIntegration($input: TikTokIntegrationCreateInput!) {
    createTiktokIntegration(input: $input) {
      href
    }
  }
`);

const TikTokAuth: FC = () => {
  const {reportIntegration3rdPartyServiceCallback} = useAnalytics();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [mutate, {loading}] = useMutation(CREATE_TIKTOK_INTEGRATION, {
    onCompleted: data => {
      reportIntegration3rdPartyServiceCallback(IntegrationType.tiktok);
      window.location.href = data.createTiktokIntegration.href;
    },
    onError: () => {
      navigate('/');
    },
  });

  useEffect(() => {
    mutate({
      variables: {
        input: {
          state: searchParams.get('state') ?? '',
          code: searchParams.get('code'),
          error: searchParams.get('error'),
        },
      },
    });
  }, [mutate, searchParams]);

  if (loading) {
    return (
      <div className="relative flex h-[100dvh] max-h-[100dvh] min-h-[100dvh] w-[100dvw] min-w-[100dvw] max-w-[100dvw] flex-col bg-default-100">
        <PortalSpinner
          classNames={{
            base: 'backdrop-blur-sm fixed inset-0 z-40',
          }}
          size="lg"
        />
      </div>
    );
  }

  return null;
};

export default TikTokAuth;
