import {
  motionValue,
  useMotionValueEvent,
  useTransform,
  animate,
} from 'framer-motion';
import {useEffect} from 'react';
import {useModalRegistry} from './useModalRegistry';
import {mix} from 'color2k';

export type StatusBarColor = 'default' | 'primary';

const statusBarColors: Record<StatusBarColor, string> = {
  default: '#000000',
  primary: '#25005A',
};

const modalOverlayColor = 'rgb(0, 0, 0)'; // Semi-transparent black

// Global motion values shared across all usages of the hook
const statusBarColor = motionValue(statusBarColors.default);
const scrollValue = motionValue(0);
const modalAlpha = motionValue(0);

const scrollThreshold = 180;

interface UseStatusBarOptions {
  color?: StatusBarColor;
}

export function useStatusBar({color = 'default'}: UseStatusBarOptions = {}) {
  const {size} = useModalRegistry();
  const isModalOpen = size > 0;

  // 1. Setup global scroll listener
  useEffect(() => {
    const onScroll = () => scrollValue.set(window.scrollY);
    window.addEventListener('scroll', onScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  // 2. Update statusBarColor instantly when `color` changes or on mount
  useEffect(() => {
    const currentScroll = scrollValue.get();

    if (color === 'default') {
      // Always default color
      statusBarColor.set(statusBarColors.default);
    } else {
      // "primary" page: interpolate based on current scroll
      const t = Math.min(Math.max(currentScroll / scrollThreshold, 0), 1);
      statusBarColor.set(
        mix(statusBarColors.primary, statusBarColors.default, t),
      );
    }
  }, [color]);

  // 3. On scroll changes, update statusBarColor if primary
  useMotionValueEvent(scrollValue, 'change', currentScroll => {
    if (color === 'primary') {
      const t = Math.min(Math.max(currentScroll / scrollThreshold, 0), 1);
      statusBarColor.set(
        mix(statusBarColors.primary, statusBarColors.default, t),
      );
    } else {
      statusBarColor.set(statusBarColors.default);
    }
  });

  // 4. Animate modalAlpha when modal state changes
  useEffect(() => {
    animate(modalAlpha, isModalOpen ? 0.5 : 0, {duration: 0.2});
  }, [isModalOpen]);

  // 5. Use useTransform to derive finalColor by blending statusBarColor and modalOverlayColor
  const finalColor = useTransform(() => {
    const baseColor = statusBarColor.get();
    const alpha = modalAlpha.get();

    return mix(baseColor, modalOverlayColor, alpha);
  });

  // 6. Update meta tag whenever finalColor changes
  useMotionValueEvent(finalColor, 'change', latestColor => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', latestColor);
    }
  });

  return {statusBarColor, scrollValue, modalAlpha, finalColor};
}
