import {MotionValue, useTransform, motion} from 'framer-motion';
import {memo} from 'react';

const ProgressSegment = memo(
  ({
    index,
    segmentProgress,
  }: {
    index: number;
    segmentProgress: MotionValue<number>;
  }) => {
    const width = useTransform(segmentProgress, latest => {
      const isPartiallyFilled = Math.floor(latest) === index;
      const partialWidth = (latest % 1) * 100;

      return isPartiallyFilled
        ? `${partialWidth}%`
        : index < Math.floor(latest)
          ? '100%'
          : '0%';
    });

    return (
      <div className="flex-1 overflow-hidden rounded-full bg-foreground/25">
        <motion.div
          className="h-full rounded-full"
          style={{
            background: 'linear-gradient(90deg, #7378ff, #a966ff)',
            width,
          }}
        />
      </div>
    );
  },
);

ProgressSegment.displayName = 'ProgressSegment';

const SegmentedProgressBar = memo(
  ({
    progress,
    segments,
    gap = 4,
  }: {
    progress: MotionValue<number>;
    segments: number;
    gap?: number;
  }) => {
    const segmentProgress = useTransform(
      progress,
      latest => segments * (Math.min(Math.max(latest, 0), 100) / 100),
    );

    return (
      <div className="relative flex h-1 w-full shrink-0 overflow-hidden rounded-full">
        <div className="absolute inset-0 flex w-full" style={{gap}}>
          {Array.from({length: segments}, (_, index) => (
            <ProgressSegment
              key={index}
              index={index}
              segmentProgress={segmentProgress}
            />
          ))}
        </div>
      </div>
    );
  },
);

SegmentedProgressBar.displayName = 'SegmentedProgressBar';

export default SegmentedProgressBar;
