import {memo} from 'react';
import {Navigate, Link as RouterLink, useNavigate} from 'react-router-dom';
import {msg, Trans} from '@lingui/macro';
import PortalSpinner from '@/components/PortalSpinner';
import {useWhatsappNotifications} from '@/hooks/useWhatsappNotifications';
import {ModalBody, ModalHeader} from '@/components/Modal';
import TrackButton from '@/components/buttons/TrackButton';
import whatsappLogo from '@/assets/whatsapp-logo.svg';
import {useLingui} from '@lingui/react';

const WhatsappNotificationsDisable = memo(() => {
  const {_} = useLingui();
  const navigate = useNavigate();
  const {isInitialLoading, isLoading, optedInPhoneNumbers, setOptInStatus} =
    useWhatsappNotifications();

  if (isInitialLoading) {
    return <PortalSpinner />;
  }

  if (!optedInPhoneNumbers.length) {
    return <Navigate to={'..'} replace />;
  }

  return (
    <>
      <ModalHeader>
        <div className="flex w-full items-center gap-3 text-base font-medium">
          <img src={whatsappLogo} alt="WhatsApp" className="h-14 w-14" />
          <div className="flex flex-col">
            <div className="text-sm font-medium uppercase text-foreground/50">
              <Trans>WhatsApp</Trans>
            </div>
            <div className="text-lg font-semibold">
              <Trans>Disable Notifications</Trans>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="grow flex-col gap-4">
        <div className="flex w-full flex-col items-center gap-8">
          <div className="flex w-full flex-col gap-2 text-center">
            <div className="text-base font-medium">
              <Trans>
                Are you sure you want to disable WhatsApp notifications?
              </Trans>
            </div>
            <div className="text-sm text-foreground/50">
              <Trans>You will no longer receive alerts about your ads.</Trans>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <TrackButton
              aria-label={_(msg`Disable WhatsApp notifications`)}
              data-amp-track-label="Disable WhatsApp notifications"
              fullWidth
              variant="solid"
              radius="sm"
              color="primary"
              isLoading={isLoading}
              isDisabled={isLoading}
              className={'text-sm font-medium text-foreground'}
              spinner={
                <PortalSpinner
                  classNames={{
                    circle1: 'border-b-foreground',
                  }}
                  size="sm"
                />
              }
              onPress={() =>
                setOptInStatus(false, success => {
                  if (success) {
                    navigate('..');
                  }
                })
              }>
              <Trans>Disable WhatsApp Notifications</Trans>
            </TrackButton>
            <TrackButton
              aria-label={_(msg`Cancel disabling WhatsApp notifications`)}
              data-amp-track-label="Cancel disabling WhatsApp notifications"
              as={RouterLink}
              // @ts-expect-error typing issue
              to={'..'}
              fullWidth
              variant="solid"
              radius="sm"
              className={'bg-primary/25 text-sm font-medium text-primary'}>
              <Trans>Cancel</Trans>
            </TrackButton>
          </div>
        </div>
      </ModalBody>
    </>
  );
});

WhatsappNotificationsDisable.displayName = 'WhatsappNotificationsDisable';

export default WhatsappNotificationsDisable;
