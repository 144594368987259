'use client';

import {DetailedHTMLProps, HTMLAttributes, memo, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

const ErrorMessage = memo(
  ({
    message,
    className = '',
    ...restProps
  }: {
    message?: ReactNode;
    className?: string;
  } & Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'children'
  >) => {
    if (!message) {
      return null;
    }

    return (
      <div
        className={twMerge(
          `whitespace-pre-wrap text-sm text-[#F00]`,
          className,
        )}
        {...restProps}>
        {message}
      </div>
    );
  },
);

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
