import {
  FlowRunAssetStatus,
  FlowRunAssetTextType,
} from '@/__generated__/graphql';
import {
  REGENERATE_FLOW_RUN_ASSET_TEXT,
  REGENERATE_FLOW_RUN_ASSET_VIDEO,
  UPDATE_ASSET,
} from '@/common/mutations';
import {GET_ASSET} from '@/common/queries';
import TrackButton from '@/components/buttons/TrackButton';
import AlignLeftIcon from '@/components/icons/AlignLeftIcon';
import MusicIcon from '@/components/icons/MusicIcon';
import RefreshCcw04Icon from '@/components/icons/RefreshCcw04Icon';
import VideoRecorderOffIcon from '@/components/icons/VideoRecorderOffIcon';
import {Modal, ModalBody, ModalContent, ModalHeader} from '@/components/Modal';
import PortalSpinner from '@/components/PortalSpinner';
import {useFlowContext} from '@/hooks';
import usePresignedLink from '@/hooks/usePresignedLink';
import {isSafari} from '@/utils';
import {useMutation, useQuery} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Textarea} from '@nextui-org/react';
import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {twJoin} from 'tailwind-merge';
import EditAdPrompt, {TextPromptType} from './components/EditAdPrompt';

const EditAsset = memo(() => {
  const {_} = useLingui();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isSavedRef = useRef(false);
  const {id} = useParams();
  const [editPropmptType, setEditPromptType] = useState<TextPromptType | null>(
    null,
  );
  const assetData = useQuery(GET_ASSET, {
    variables: {
      id: id!,
    },
    skip: !id,
  });
  const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);
  const navigate = useNavigate();
  const [updateAsset] = useMutation(UPDATE_ASSET, {
    ignoreResults: true,
  });
  const storedAsset = useMemo(() => assetData.data?.getAsset, [assetData.data]);
  const {generatedVideosState, updateGeneratedVideoState} = useFlowContext();
  const asset = generatedVideosState.find(
    ({id: generatedVideoId}) => generatedVideoId === id,
  );
  const [regenerateVideo] = useMutation(REGENERATE_FLOW_RUN_ASSET_VIDEO, {
    refetchQueries: [GET_ASSET],
    variables: {id: id!},
    ignoreResults: true,
  });

  useEffect(() => {
    if (
      assetData.data?.getAsset.status !== FlowRunAssetStatus.GeneratingVideo
    ) {
      setIsGeneratingVideo(false);
      return;
    }

    setIsGeneratingVideo(true);

    const interval = setInterval(() => {
      assetData.refetch();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [assetData.data?.getAsset.status]);

  const [regenerateFlowRunCaption, regenerateFlowRunCaptionResult] =
    useMutation(REGENERATE_FLOW_RUN_ASSET_TEXT, {
      refetchQueries: [GET_ASSET],
      variables: {
        input: {
          id: id!,
          textType: FlowRunAssetTextType.Caption,
          prompt: asset?.captionPrompt,
        },
      },
    });
  const [regenerateFlowRunHeadline, regenerateFlowRunHeadlineResult] =
    useMutation(REGENERATE_FLOW_RUN_ASSET_TEXT, {
      refetchQueries: [GET_ASSET],
      variables: {
        input: {
          id: id!,
          textType: FlowRunAssetTextType.Headline,
          prompt: asset?.headlinePrompt,
        },
      },
    });
  const assetUrl = usePresignedLink(asset?.name);
  const hasChanged = useMemo(() => {
    if (!storedAsset || !asset) {
      return false;
    }

    const captionHasChanged = storedAsset.caption !== asset.caption;
    const headlineHasChanged = storedAsset.headline !== asset.headline;

    return captionHasChanged || headlineHasChanged;
  }, [storedAsset, asset]);
  const canSave = useMemo(() => {
    return Boolean(asset?.caption && asset?.headline && storedAsset);
  }, [asset, storedAsset]);

  const updateHeadline = useCallback((value: string) => {
    updateGeneratedVideoState({
      id: id!,
      data: {headline: value},
    });
  }, []);

  const updateCaption = useCallback((value: string) => {
    updateGeneratedVideoState({
      id: id!,
      data: {caption: value},
    });
  }, []);

  const onSave = useCallback(() => {
    if (!storedAsset) {
      return;
    }

    if (!hasChanged) {
      navigate('..');
      return;
    }

    updateAsset({
      variables: {
        id: id!,
        input: {
          headline: asset?.headline,
          caption: asset?.caption,
        },
      },
      onCompleted: () => {
        isSavedRef.current = true;
        navigate('..');
      },
    });
  }, [hasChanged, asset, storedAsset, id, navigate, updateAsset]);

  useEffect(() => {
    return () => {
      if (!isSavedRef.current && storedAsset) {
        updateGeneratedVideoState({
          id: id!,
          data: {
            headline: storedAsset.headline,
            caption: storedAsset.caption,
          },
        });
      }
    };
  }, []);

  if (!id) {
    return <Navigate to=".." />;
  }

  if (!asset) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
        }}
        size="lg"
      />
    );
  }

  let musicParts = [asset.musicTitle, asset.musicAuthor].filter(Boolean);

  if (musicParts.length === 2) {
    musicParts = [musicParts[0], 'by', musicParts[1]];
  }

  return (
    <>
      <Modal
        isOpen={assetData.data?.getAsset.status === FlowRunAssetStatus.Failed}
        hideCloseButton>
        <ModalContent>
          <ModalHeader className="p-4">
            <div className="flex w-full text-base font-medium">
              <Trans>Video Generation Failed</Trans>
            </div>
          </ModalHeader>
          <ModalBody className="flex-col items-center justify-center gap-8 p-4">
            <div className="flex w-full flex-col items-center gap-4">
              <VideoRecorderOffIcon className="flex h-11 w-11 shrink-0 text-foreground/60" />
              <div className="text-center text-sm text-foreground/60">
                <Trans>Something went wrong. Please try again.</Trans>
              </div>
            </div>
            <div className="flex w-full flex-col gap-3">
              <Button
                aria-label={_(msg`Edit assets`)}
                data-amp-track-label="Edit assets"
                className="w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
                onPress={() =>
                  updateAsset({
                    variables: {
                      id: id!,
                      input: {errorMessage: null, errorStep: null},
                    },
                    refetchQueries: [GET_ASSET],
                  })
                }>
                <Trans>Close</Trans>
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ModalHeader>
        <div className="flex w-full text-base font-medium">
          <Trans>Edit Asset</Trans>
        </div>
      </ModalHeader>
      <ModalBody className="mx-auto w-full max-w-lg gap-8">
        <div className="flex w-full flex-col gap-4 border-b border-b-foreground/10 pb-8 text-center">
          <div className="relative flex w-full justify-center">
            <video
              ref={videoRef}
              className="mx-auto h-auto max-h-[min(24rem,50dvh)] rounded-lg"
              preload="metadata"
              controls
              loop
              playsInline
              autoPlay
              muted // NOTE mute until we have the sound to avoid autoplay issues https://developer.chrome.com/blog/autoplay/
              src={
                assetUrl
                  ? isSafari
                    ? `${assetUrl}#t=0.01`
                    : assetUrl
                  : undefined
              }
            />
          </div>
          <div className="flex justify-center gap-2 truncate text-sm">
            <MusicIcon className="h-4 w-4 text-foreground/50" />
            {musicParts.map((part, index) => (
              <span
                key={index}
                className={
                  index === 1 ? 'text-foreground/50' : 'text-foreground'
                }>
                {part + ' '}
              </span>
            ))}
          </div>
          <TrackButton
            aria-label={_(msg`Regenerate video`)}
            data-amp-track-label="Regenerate video"
            variant="light"
            color="primary"
            className="mx-auto h-9 w-full max-w-fit bg-primary/20 px-3 data-[hover=true]:bg-primary/10"
            disableRipple
            onPress={() => {
              setIsGeneratingVideo(true);
              regenerateVideo();
            }}
            isDisabled={!asset.videoRegenerationsLeft || isGeneratingVideo}
            startContent={
              <RefreshCcw04Icon
                className={twJoin(
                  'h-[0.875rem] w-[0.875rem] shrink-0',
                  isGeneratingVideo ? 'animate-spin' : '',
                )}
              />
            }>
            {isGeneratingVideo ? (
              <Trans>Regenerating...</Trans>
            ) : (
              <Trans>Regenerate video</Trans>
            )}
          </TrackButton>
          <div className="flex w-full justify-center">
            <p className="text-xs text-foreground/50">
              <Trans>Regenerations left: </Trans>{' '}
              <span className="text-foreground">
                {asset.videoRegenerationsLeft}
              </span>
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="text-xs text-foreground/50">
              <Trans>Headline</Trans>
            </div>
            <Textarea
              classNames={{
                inputWrapper: `relative w-full !shadow-none !opacity-100 px-3 py-2 rounded-lg bg-foreground/10 data-[hover=true]:bg-foreground/10 group-data-[focus=true]:bg-foreground/10`,
                input: '!text-foreground',
              }}
              placeholder={_(msg`Video headline...`)}
              value={asset.headline ?? ''}
              minRows={1}
              maxRows={5}
              onValueChange={updateHeadline}
            />
          </div>
          <div className="flex gap-6">
            <Button
              aria-label={_(msg`Regenerate caption`)}
              data-amp-track-label="Regenerate headline"
              disableAnimation
              disableRipple
              className="h-auto min-h-0 w-auto min-w-0 gap-1 rounded-none bg-transparent p-0 text-xs text-foreground/50"
              onPress={() => regenerateFlowRunHeadline()}
              isDisabled={regenerateFlowRunHeadlineResult.loading}
              startContent={
                <RefreshCcw04Icon
                  className={twJoin(
                    'h-[0.875rem] w-[0.875rem] shrink-0',
                    regenerateFlowRunHeadlineResult.loading
                      ? 'animate-spin'
                      : '',
                  )}
                />
              }>
              <Trans>Regenerate</Trans>
            </Button>
            <Button
              aria-label={_(msg`Edit headline prompt`)}
              data-amp-track-label="Edit headline prompt"
              disableAnimation
              disableRipple
              className="h-auto min-h-0 w-auto min-w-0 gap-1 rounded-none bg-transparent p-0 text-xs text-foreground/50"
              startContent={
                <AlignLeftIcon className="h-[0.875rem] w-[0.875rem]" />
              }
              onPress={() => setEditPromptType('headline')}>
              <Trans>Edit prompt</Trans>
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="text-xs text-foreground/50">
              <Trans>Caption</Trans>
            </div>
            <Textarea
              classNames={{
                inputWrapper: `relative w-full !shadow-none !opacity-100 px-3 py-2 rounded-lg bg-foreground/10 data-[hover=true]:bg-foreground/10 group-data-[focus=true]:bg-foreground/10`,
                input: '!text-foreground',
              }}
              placeholder={_(msg`Video caption...`)}
              value={asset.caption ?? ''}
              minRows={1}
              maxRows={5}
              onValueChange={updateCaption}
            />
          </div>
          <div className="flex gap-6">
            <Button
              aria-label={_(msg`Regenerate caption`)}
              data-amp-track-label="Regenerate caption"
              disableAnimation
              disableRipple
              className="h-auto min-h-0 w-auto min-w-0 gap-1 rounded-none bg-transparent p-0 text-xs text-foreground/50"
              onPress={() => regenerateFlowRunCaption()}
              isDisabled={regenerateFlowRunCaptionResult.loading}
              startContent={
                <RefreshCcw04Icon
                  className={twJoin(
                    'h-[0.875rem] w-[0.875rem] shrink-0',
                    regenerateFlowRunCaptionResult.loading
                      ? 'animate-spin'
                      : '',
                  )}
                />
              }>
              <Trans>Regenerate</Trans>
            </Button>
            <Button
              aria-label={_(msg`Edit caption prompt`)}
              data-amp-track-label="Edit caption prompt"
              disableAnimation
              disableRipple
              className="h-auto min-h-0 w-auto min-w-0 gap-1 rounded-none bg-transparent p-0 text-xs text-foreground/50"
              startContent={
                <AlignLeftIcon className="h-[0.875rem] w-[0.875rem]" />
              }
              onPress={() => setEditPromptType('caption')}>
              <Trans>Edit prompt</Trans>
            </Button>
          </div>
        </div>

        <Button
          aria-label={_(msg`Save edited asset`)}
          data-amp-track-label="Save edited asset"
          className="mt-8 w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
          isDisabled={!canSave}
          onPress={onSave}>
          <Trans>Save</Trans>
        </Button>
      </ModalBody>

      <EditAdPrompt
        asset={asset}
        promptType={editPropmptType}
        setPromptType={setEditPromptType}
      />
    </>
  );
});

EditAsset.displayName = 'EditAsset';

export default EditAsset;
