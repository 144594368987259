import {memo} from 'react';
import {LiveHeader} from './LiveHeader';
import {PerformanceSection} from './PerformanceSection';
import {AdsSection} from './AdsSection';
import {useAuthContext} from '@/hooks';
import {useQuery} from '@apollo/client';
import PortalSpinner from '@/components/PortalSpinner';
import {Navigate} from 'react-router-dom';
import {GET_FLOW_RUN_CONNECTION} from '@/common/queries';
import ModalOutlet from '@/components/ModalOutlet';

const subpath = [
  'connect-shopify',
  'enable-whatsapp-notifications',
  'disable-whatsapp-notifications',
];

export const Live = memo(() => {
  const {user} = useAuthContext();
  const flowRuns = useQuery(GET_FLOW_RUN_CONNECTION, {
    variables: {
      filter: {
        userId: user?.id,
        campaignId: {$ne: null},
        errorMessage: {$eq: null},
      },
      first: 1,
      order: {createdAt: 'asc'},
    },
    skip: !user,
    fetchPolicy: 'network-only',
  });
  const firstFlowRun = flowRuns.data?.getFlowRunConnection.edges[0]?.node;

  if (!flowRuns.data) {
    return (
      <PortalSpinner
        classNames={{base: 'backdrop-blur-sm fixed inset-0 z-40'}}
        size="lg"
      />
    );
  }

  if (!flowRuns.data.getFlowRunConnection.edges.length) {
    return <Navigate to="/create" replace />;
  }

  return (
    <>
      <ModalOutlet basePath="/live" subPath={subpath} />
      <div className="flex w-full max-w-[42rem] flex-col px-5">
        <LiveHeader />
        <div className="flex flex-col gap-8 pt-5">
          <PerformanceSection startTime={firstFlowRun!.createdAt} />
          <AdsSection />
        </div>
      </div>
    </>
  );
});

Live.displayName = 'Live';
