'use client';

import {blobToBase64, JSONSafeParse} from '@/utils';
import {LS_FLOW_STATE_CACHE} from '@/config';

export const storeFlowState = async (state: Record<string, any>) => {
  const cache: Record<string, any> = {};
  const prevRaw = localStorage.getItem(LS_FLOW_STATE_CACHE);
  const prev = prevRaw
    ? (JSONSafeParse<Record<string, any>>(prevRaw) ?? {})
    : {};

  try {
    for (const key of Object.keys(state)) {
      const value = state[key];
      const prevValue = prev[key];

      if (!prevValue && !value) {
        continue;
      }

      if (key === 'selectedImage') {
        cache[key] = value
          ? {
              base64: await blobToBase64(value.file),
              filename: value.file.name,
              type: value.file.type,
            }
          : undefined;
      } else {
        cache[key] = value;
      }
    }

    localStorage.setItem(LS_FLOW_STATE_CACHE, JSON.stringify(cache));
  } catch (e) {
    console.log(e);
  }
};

export const getStoredFlowState = async () => {
  const rawCache = localStorage.getItem(LS_FLOW_STATE_CACHE);

  if (!rawCache) {
    return undefined;
  }

  const cache = JSONSafeParse<Record<string, any>>(rawCache);

  if (!cache) {
    return undefined;
  }

  const state: Record<string, any> = {};

  for (const [key, value] of Object.entries(cache)) {
    if (key === 'selectedImage') {
      const response = await fetch(value.base64);
      const blob = await response.blob();

      state[key] = {
        url: URL.createObjectURL(blob),
        file: new File([blob], value.filename, {type: value.type}),
      };
    } else {
      state[key] = value;
    }
  }

  return state;
};

export const clearStoredFlowState = () => {
  localStorage.removeItem(LS_FLOW_STATE_CACHE);
};
