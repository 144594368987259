import {GeneratedVideoState} from '@/common/types';
import PlayIcon from '@/components/icons/PlayIcon';
import usePresignedLink from '@/hooks/usePresignedLink';
import {motion} from 'framer-motion';
import {memo} from 'react';
import {twMerge} from 'tailwind-merge';

const IMAGE_OFFSET = 12;

const ThumbnailImage = memo(
  ({
    index,
    thumbnailName,
    name,
    className,
  }: GeneratedVideoState & {
    index: number;
    className?: string;
  }) => {
    const thumbnailURL = usePresignedLink(thumbnailName);
    const extraTransform = {
      translateX: -index * IMAGE_OFFSET,
      rotate: -10,
    };

    return (
      <motion.div
        initial={{scale: 0.5, ...extraTransform}}
        animate={{scale: 1, ...extraTransform}}
        exit={{scale: 0.5, ...extraTransform}}
        transition={{
          type: 'spring',
          stiffness: 600,
          damping: 12,
          restDelta: 0.01,
          restSpeed: 2,
        }}
        className={twMerge(
          'relative flex h-8 w-8 shrink-0 grow-0 rounded-lg',
          className,
        )}
        style={{
          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.75)',
          zIndex: index,
        }}>
        {thumbnailURL ? (
          <img
            src={thumbnailURL}
            alt={name}
            className="h-full w-full rounded-lg object-cover"
          />
        ) : (
          <div />
        )}
        <div className="absolute inset-0 flex items-center justify-center rounded-lg border-[2px] border-foreground/75">
          <PlayIcon className="h-4 w-4 text-foreground" />
        </div>
      </motion.div>
    );
  },
);

ThumbnailImage.displayName = 'ThumbnailImage';

export default ThumbnailImage;
