import {useAuthContext} from '@/hooks';
import {msg, Trans} from '@lingui/macro';
import {memo, useCallback, useEffect, useMemo, useRef} from 'react';
import Confetti from './Confetti';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import CheckCircleSuccessIcon from '@/components/icons/CheckCircleSuccessIcon';
import {gql} from '@/__generated__';
import {useQuery} from '@apollo/client';
import PortalSpinner from '@/components/PortalSpinner';
import CampaignSVG from './CampaignSVG';
import useCanvasToShare from './useCanvasToShare';
import {Button} from '@nextui-org/react';
import ShareIcon from '@/components/icons/ShareIcon';
import CloseButton from '@/components/Modal';
import {useLingui} from '@lingui/react';

const GET_COMPLETION_TIME = gql(`
  query GetFlowCompletionTimeInfo($id: ID!) {
    getFlowCompletionTimeInfo(id: $id) {
      createdAd
      completedAt
      diffMs
      isRecord
    }
  }
`);

const CampaignCreationSuccess = memo(() => {
  const {_} = useLingui();
  const timerRef = useRef<number | undefined>();
  const navigate = useNavigate();
  const {runId} = useParams<{runId: string}>();
  const {user} = useAuthContext();
  const onShareEnd = useCallback(() => {
    timerRef.current = window.setTimeout(() => {
      navigate('/');
    }, 6000);
  }, []);
  const {svgRef, shareImage} = useCanvasToShare(onShareEnd);

  const {data, loading, error} = useQuery(GET_COMPLETION_TIME, {
    variables: {id: runId!},
    skip: !runId,
  });

  useEffect(() => {
    timerRef.current = window.setTimeout(() => {
      navigate('/');
    }, 6000);
  }, [navigate]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const {diffMs, isRecord} = data?.getFlowCompletionTimeInfo || {};

  const timeParts = useMemo(() => {
    if (!diffMs) {
      return {};
    }

    const totalMilliseconds = diffMs;
    const minutes = Math.floor(totalMilliseconds / 60000);
    const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
    const milliseconds = totalMilliseconds % 1000;

    return {
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
      milliseconds: milliseconds.toString().padStart(3, '0'),
    };
  }, [diffMs]);

  if (!runId || error) {
    return <Navigate to="/" replace />;
  }

  if (loading || !data) {
    return <PortalSpinner />;
  }

  const {minutes, seconds, milliseconds} = timeParts;
  const showDate = Number.isFinite(diffMs);

  const renderDatePart = (value: string, label: string) =>
    value ? (
      <>
        <div className="flex items-end gap-1 rounded-lg bg-[#262626] px-1 py-2">
          <div className="text-3xl font-semibold text-foreground">{value}</div>
          <div className="pb-0.5 text-small text-foreground/50">{label}</div>
        </div>
        <div className="text-xl text-foreground/50 last:hidden">:</div>
      </>
    ) : null;

  return (
    <div className="flex w-full grow flex-col items-center justify-center">
      <div className="pointer-events-none absolute inset-0 z-10">
        <Confetti />
      </div>
      <CloseButton
        className="transition-foreground absolute right-5 top-5 z-20 bg-foreground/35 backdrop-blur data-[hover=true]:bg-foreground/35 [&>svg]:stroke-foreground/75 [&>svg]:data-[hover=true]:stroke-foreground/75"
        onPress={() => {
          clearTimeout(timerRef.current);
          navigate('/');
        }}
      />
      <div className="relative z-10 flex flex-col items-center justify-center gap-6 p-8">
        <div className="relative text-[4.5rem]/[4.5rem] text-foreground">
          {isRecord ? <span>🦄</span> : <span>🚀</span>}
          <div className="absolute -bottom-1 -right-1">
            <CheckCircleSuccessIcon className="h-5 w-5 text-primary" />
          </div>
        </div>
        <div className="flex w-full flex-col gap-2 text-center">
          <div className="text-xl text-foreground">
            {isRecord ? (
              <div>
                <Trans>New Personal Best!</Trans>
              </div>
            ) : user?.firstName ? (
              <div>
                <Trans>Congrats, {user.firstName}!</Trans>
              </div>
            ) : (
              <div>
                <Trans>Congrats!</Trans>
              </div>
            )}
          </div>
          <div className="text-base text-foreground/50">
            <div>
              <Trans>You’ve Successfully</Trans>
            </div>
            {showDate ? (
              <div>
                <Trans>Launched the Campaign in just:</Trans>
              </div>
            ) : (
              <div>
                <Trans>Launched the Campaign</Trans>
              </div>
            )}
          </div>
        </div>
        {showDate && (
          <div className="flex items-center gap-2 empty:hidden">
            {minutes && renderDatePart(minutes, 'min')}
            {seconds && renderDatePart(seconds, 's')}
            {milliseconds && renderDatePart(milliseconds, 'ms')}
          </div>
        )}
      </div>

      <CampaignSVG
        ref={svgRef}
        firstName={user?.firstName}
        isRecord={isRecord ?? false}
        showDate={showDate}
        minutes={minutes}
        seconds={seconds}
        milliseconds={milliseconds}
      />

      <Button
        aria-label={_(msg`Share`)}
        data-amp-track-label={`Share`}
        disableAnimation
        disableRipple
        className="flex h-auto min-h-0 w-auto min-w-0 gap-2 bg-transparent p-0 text-primary"
        onPress={() => {
          clearTimeout(timerRef.current);
          shareImage();
        }}>
        <Trans>Share</Trans>
        <ShareIcon className="h-5 w-5" />
      </Button>
    </div>
  );
});

CampaignCreationSuccess.displayName = 'CampaignCreationSuccess';

export default CampaignCreationSuccess;
