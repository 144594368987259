import {FlowSetting as FlowSettingType} from '@/common/types';
import FlowSetting from '@/components/FlowSetting';
import {useRecommendedSettingsHandler} from '@/components/FlowSetting/hooks';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import Stars02Icon from '@/components/icons/Stars02Icon';
import {useFlowContext} from '@/hooks';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {memo, useCallback} from 'react';
import {Link as RouterLink} from 'react-router-dom';

interface Props {
  settings: FlowSettingType[];
}

const RecommendedSettings = memo(({settings}: Props) => {
  const {_} = useLingui();
  const {updateSettingValue} = useFlowContext();
  const {hasChanged, getRevertedSettings} =
    useRecommendedSettingsHandler(settings);

  const revertSettings = useCallback(() => {
    const revertedSettings = getRevertedSettings();

    for (const setting of revertedSettings) {
      updateSettingValue(setting.id, setting.value);
    }
  }, [getRevertedSettings, updateSettingValue]);

  if (!settings.length) {
    return null;
  }

  return (
    <div className="relative flex w-full">
      <div
        style={{
          background: hasChanged
            ? '#323233'
            : 'linear-gradient(90deg, rgba(71, 78, 255, 0.10) 0%, rgba(148, 63, 255, 0.10) 100%)',
          maskImage:
            'linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.5) 100%)',
          WebkitMaskImage:
            'linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.5) 100%)',
        }}
        className="flex w-full flex-col gap-4 rounded-2xl p-3 pb-12 transition-all">
        {hasChanged ? (
          <div className="flex items-center justify-between gap-2">
            <div className="text-sm font-medium text-foreground">
              <Trans>Custom Setup</Trans>
            </div>
            <Button
              aria-label={_(msg`Revert settings to recommended`)}
              data-amp-track-label="Revert settings to recommended"
              disableAnimation
              disableRipple
              className="text h-auto min-h-0 w-auto min-w-0 rounded-none bg-transparent p-0"
              startContent={<Stars02Icon className="h-4 w-4" />}
              onPress={revertSettings}>
              <div
                style={{
                  background:
                    'linear-gradient(90deg, #7378FF 0%, #A966FF 100%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                <Trans>{'<- Revert'}</Trans>
              </div>
            </Button>
          </div>
        ) : (
          <div className="flex items-center justify-between gap-2">
            <div
              style={{
                background: 'linear-gradient(90deg, #7378FF 0%, #A966FF 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
              className="text-sm font-medium text-foreground">
              <Trans>Recommended Settings</Trans>
            </div>
            <Stars02Icon className="h-5 w-5" />
          </div>
        )}
        <div className="pointer-events-none flex w-full flex-col gap-4">
          {settings.map(setting => {
            return (
              <FlowSetting
                key={setting.id}
                setting={setting}
                settings={settings}
                onSettingChange={updateSettingValue}
              />
            );
          })}
        </div>
      </div>
      <Button
        aria-label={_(msg`Fine-tune`)}
        data-amp-track-label="Fine-tune"
        as={RouterLink}
        to={'fine-tune'}
        disableAnimation
        disableRipple
        className="absolute bottom-0 left-0 right-0 z-10 flex h-[calc(100%-3rem)] max-h-none min-h-0 min-w-0 max-w-none items-end rounded-none bg-transparent text-primary">
        <div className="flex items-center gap-2 p-4">
          <Trans>Fine-tune</Trans>
          <ChevronLeftIcon className="h-4 w-4 rotate-180" />
        </div>
      </Button>
    </div>
  );
});

RecommendedSettings.displayName = 'RecommendedSettings';

export default RecommendedSettings;
