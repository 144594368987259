import {FlowSetting} from '@/common/types';
import {useCallback, useMemo} from 'react';

export const useRecommendedSettingsHandler = (settings: FlowSetting[]) => {
  const hasChanged = useMemo(
    () =>
      settings.some(setting => {
        switch (setting.type) {
          case 'text':
          case 'url':
            return false;
          case 'options':
          case 'dropdown': {
            const hasRecommended = setting.data.options.some(
              option => option.recommended && option.value !== setting.value,
            );

            return (
              hasRecommended &&
              setting.data.options.some(
                option => option.recommended && option.value !== setting.value,
              )
            );
          }
          case 'slider':
            return (
              Number.isFinite(setting.data.recommended) &&
              setting.data.recommended !== setting.value
            );
          default:
            return false;
        }
      }),
    [settings],
  );

  const getRevertedSettings = useCallback(() => {
    return settings.map(setting => {
      switch (setting.type) {
        case 'text':
        case 'url':
          return setting;
        case 'options':
        case 'dropdown': {
          const recommendedOption = setting.data.options.find(
            option => option.recommended,
          );

          return {
            ...setting,
            value: recommendedOption?.value ?? setting.value,
          };
        }
        case 'slider':
          return {
            ...setting,
            value: setting.data.recommended,
          };
        default:
          return setting;
      }
    });
  }, [settings]);

  return {hasChanged, getRevertedSettings};
};
