'use client';

import {SVGProps} from 'react';

export default function CoinsStacked03SolidIcon(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2C5.89597 2 4.4 2.23478 3.26917 2.64599C3.23409 2.65875 3.19915 2.67175 3.16438 2.685C2.64277 2.88375 2.15808 3.13843 1.78029 3.46322C1.38057 3.80688 1 4.32275 1 5V6.5C1 7.17725 1.38057 7.69312 1.78029 8.03678C2.18326 8.38322 2.70787 8.6499 3.26917 8.85401C4.4 9.26522 5.89597 9.5 7.5 9.5C7.73211 9.5 7.96196 9.49508 8.18877 9.48543C8.3287 9.47947 8.39867 9.47649 8.46138 9.45682C8.52137 9.438 8.56538 9.41518 8.61533 9.37701C8.66756 9.3371 8.72084 9.26799 8.8274 9.12978C9.04064 8.85322 9.26856 8.62538 9.47645 8.44665C10.1287 7.88587 10.8971 7.51681 11.5857 7.26641C12.1181 7.07278 12.7039 6.91568 13.3195 6.79529C13.5724 6.74583 13.6988 6.7211 13.785 6.66135C13.8635 6.60688 13.9195 6.53886 13.9579 6.45133C14 6.35533 14 6.23303 14 5.98841C14 5.65073 14 5.2617 14 5C14 4.32275 13.6194 3.80688 13.2197 3.46322C12.8167 3.11678 12.2921 2.8501 11.7308 2.64599C10.6 2.23478 9.10403 2 7.5 2Z"
        fill="currentColor"
      />
      <path
        d="M7.99936 11.9924C7.99957 11.7189 7.77351 11.5 7.5 11.5C5.89597 11.5 4.4 11.2652 3.26917 10.854C2.70787 10.6499 2.18326 10.3832 1.78029 10.0368C1.63341 9.91049 1.48911 9.76095 1.36533 9.5887C1.32625 9.53432 1.26436 9.5 1.1974 9.5C1.08838 9.5 1 9.58838 1 9.6974V11.5C1 12.1772 1.38057 12.6931 1.78029 13.0368C2.18326 13.3832 2.70787 13.6499 3.26917 13.854C4.4 14.2652 5.89597 14.5 7.5 14.5C7.50402 14.5 7.50604 14.5 7.5122 14.4998C7.77295 14.4934 7.99342 14.2728 7.99973 14.0121C7.99988 14.0059 7.99988 14.0014 7.99987 13.9923C7.99956 13.3257 7.99884 12.659 7.99936 11.9924Z"
        fill="currentColor"
      />
      <path
        d="M8 16.9924C8 16.7188 7.77366 16.5 7.5 16.5C5.89597 16.5 4.4 16.2652 3.26917 15.854C2.70787 15.6499 2.18326 15.3832 1.78029 15.0368C1.63341 14.9105 1.48911 14.761 1.36533 14.5887C1.32625 14.5343 1.26436 14.5 1.1974 14.5C1.08838 14.5 1 14.5884 1 14.6974V17C1 17.6772 1.38057 18.1931 1.78029 18.5368C2.18326 18.8832 2.70787 19.1499 3.26917 19.354C4.4 19.7652 5.89597 20 7.5 20C7.51912 20 7.52947 20 7.52947 20C7.84952 19.999 8.05536 19.7724 8.02575 19.4537C8.02575 19.4537 8.02296 19.4247 8.01753 19.3682C8.00605 19.2488 8 19.1261 8 19V16.9924Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2071 8.16879C14.923 8.0587 15.698 8 16.5 8C18.104 8 19.6 8.23478 20.7308 8.64599C20.7659 8.65875 20.8008 8.67175 20.8356 8.685C21.3572 8.88375 21.8419 9.13843 22.2197 9.46322C22.6194 9.80688 23 10.3228 23 11V13C23 13.6772 22.6194 14.1931 22.2197 14.5368C21.8167 14.8832 21.2921 15.1499 20.7308 15.354C19.6 15.7652 18.104 16 16.5 16C14.896 16 13.4 15.7652 12.2692 15.354C11.7079 15.1499 11.1833 14.8832 10.7803 14.5368C10.3806 14.1931 10 13.6772 10 13C10 12.3304 10 11.6592 10 11C10 10.3228 10.3806 9.80688 10.7803 9.46322C11.1833 9.11678 11.7079 8.8501 12.2692 8.64599C12.3398 8.62029 12.4119 8.59528 12.4854 8.57097C12.9425 8.41973 13.4524 8.29571 14 8.20236C14.0685 8.19069 14.1375 8.1795 14.2071 8.16879Z"
        fill="currentColor"
      />
      <path
        d="M10.1974 16C10.0884 16 10 16.0884 10 16.1974V18.5C10 19.1772 10.3806 19.6931 10.7803 20.0368C11.1833 20.3832 11.7079 20.6499 12.2692 20.854C13.4 21.2652 14.896 21.5 16.5 21.5C18.104 21.5 19.6 21.2652 20.7308 20.854C21.2921 20.6499 21.8167 20.3832 22.2197 20.0368C22.6194 19.6931 23 19.1772 23 18.5V16.1974C23 16.0884 22.9116 16 22.8026 16C22.7356 16 22.6737 16.0343 22.6347 16.0887C22.5109 16.261 22.3666 16.4105 22.2197 16.5368C21.8167 16.8832 21.2921 17.1499 20.7308 17.354C19.6 17.7652 18.104 18 16.5 18C14.896 18 13.4 17.7652 12.2692 17.354C11.7079 17.1499 11.1833 16.8832 10.7803 16.5368C10.6334 16.4105 10.4891 16.261 10.3653 16.0887C10.3263 16.0343 10.2644 16 10.1974 16Z"
        fill="currentColor"
      />
    </svg>
  );
}
