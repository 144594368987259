'use client';

import {forwardRef} from 'react';
import {Spinner, SpinnerProps} from '@nextui-org/spinner';
import {twMerge} from 'tailwind-merge';

const PortalSpinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({classNames, ...props}, ref) => (
    <Spinner
      ref={ref}
      color="default"
      classNames={{
        ...classNames,
        circle2: twMerge('hidden', classNames?.circle2),
      }}
      {...props}
    />
  ),
);

PortalSpinner.displayName = 'PortalSpinner';

export default PortalSpinner;
