import {memo} from 'react';
import {TypedFlowSetting} from '@/common/types/flowSettings';
import URLSettingComponent from './components/URLSetting';
import TextSettingComponent from './components/TextSetting';
import OptionsSettingComponent from './components/OptionsSetting';
import DropdownSettingComponent from './components/DropdownSetting';
import SliderSettingComponent from './components/SliderSetting';
import {FlowSetting as FlowSettingType} from '@/common/types';
import MetaPresetSetting from './components/MetaPreset';

type Props = {
  setting: TypedFlowSetting;
  settings: TypedFlowSetting[];
  onSettingChange: (id: string, value: FlowSettingType['value']) => void;
};

const FlowSetting = memo(({setting, settings, onSettingChange}: Props) => {
  switch (setting.type) {
    case 'url':
      return (
        <URLSettingComponent
          key={setting.key}
          setting={setting}
          onChange={onSettingChange}
        />
      );
    case 'text':
      return (
        <TextSettingComponent
          key={setting.key}
          setting={setting}
          onChange={onSettingChange}
        />
      );
    case 'options':
      return (
        <OptionsSettingComponent
          key={setting.key}
          setting={setting}
          onChange={onSettingChange}
        />
      );
    case 'dropdown': {
      const filterKey = setting.data.filtered_by;
      const filteredBySetting = filterKey
        ? settings.find(s => s.key === filterKey)
        : undefined;
      const filterValue = filteredBySetting?.value;

      return (
        <DropdownSettingComponent
          key={setting.key}
          setting={setting}
          filterValue={filterValue}
          onChange={onSettingChange}
        />
      );
    }
    case 'slider':
      return (
        <SliderSettingComponent
          key={setting.key}
          setting={setting}
          onChange={onSettingChange}
        />
      );
    case 'meta_preset':
      return <MetaPresetSetting setting={setting} onChange={onSettingChange} />;
    default:
      return null;
  }
});

FlowSetting.displayName = 'FlowSetting';

export default FlowSetting;
