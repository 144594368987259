import {FlowPlatform} from '@/__generated__/graphql';
import {GET_PLATFORM_USER_CONNECTION} from '@/common/queries';
import {ModalBody, ModalHeader} from '@/components/Modal';
import PortalSpinner from '@/components/PortalSpinner';
import {useAuthContext} from '@/hooks';
import {useMutation, useQuery} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {memo, useEffect} from 'react';
import FacebookLogo from '@/assets/facebook-logo.svg';
import {twMerge} from 'tailwind-merge';
import {getUserName} from '@/utils';
import {useAnalytics} from '@/common/analytics';
import {CONNECT_META_ADS} from '@/common/mutations';
import {IntegrationType} from '@/common/types';
import {useLocation} from 'react-router-dom';

const INTEGRATION_TYPE = IntegrationType.metaAds;

const MetaUsers = memo(() => {
  const {_} = useLingui();
  const {user} = useAuthContext();
  const location = useLocation();
  const {reportIntegrationStart, reportIntegration3rdPartyServiceRedirect} =
    useAnalytics();
  const [connect, {loading: connectLoading}] = useMutation(CONNECT_META_ADS, {
    onCompleted: data => {
      reportIntegration3rdPartyServiceRedirect(
        INTEGRATION_TYPE,
        data.href,
      ).finally(() => {
        window.location.href = data.href;
      });
    },
  });
  const {data, loading, fetchMore} = useQuery(GET_PLATFORM_USER_CONNECTION, {
    variables: {
      filter: {
        platform: FlowPlatform.Meta,
        userId: user?.id,
      },
    },
    skip: !user?.id,
  });
  const pageInfo = data?.connection?.pageInfo;

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      fetchMore({variables: {after: pageInfo.endCursor}});
    }
  }, [pageInfo?.hasNextPage, pageInfo?.endCursor, fetchMore]);

  const renderAvatar = (avatar: string, className?: string) => (
    <div className={twMerge('relative flex h-11 w-11 shrink-0', className)}>
      <img src={avatar} alt="Avatar" className="h-full w-full rounded-full" />
    </div>
  );

  const connectMetaAds = () => {
    if (connectLoading) {
      return;
    }

    reportIntegrationStart(INTEGRATION_TYPE).finally(() => {
      connect({
        variables: {
          input: {
            callbackPath: location.pathname.slice(1) + location.search,
          },
        },
      });
    });
  };

  return (
    <>
      <ModalHeader>
        <div className="flex w-full items-center gap-3 text-base font-medium">
          <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-[0.625rem] bg-[rgba(0,140,255,0.15)]">
            <img src={FacebookLogo} alt="Meta" className="h-6 w-6" />
          </div>
          <Trans>Connected Accounts</Trans>
        </div>
      </ModalHeader>
      <ModalBody className="grow flex-col gap-4">
        {!data && loading ? (
          <div className="flex w-full grow items-center justify-center py-4">
            <PortalSpinner />
          </div>
        ) : (
          data?.connection?.edges.map(({node}) => (
            <div key={node.id} className="flex w-full items-center gap-3">
              {node.avatar && renderAvatar(node.avatar)}
              <div className="flex grow flex-col">
                <div className="text-xs text-foreground">
                  {getUserName(node)}
                </div>
              </div>
            </div>
          ))
        )}
        <Button
          aria-label={_(msg`Add Meta Account`)}
          data-amp-track-label="Add Meta Account"
          className="w-full shrink-0 bg-primary/15 px-4 py-2 text-sm font-medium text-primary"
          onPress={connectMetaAds}>
          <Trans>Add Account</Trans>
        </Button>
      </ModalBody>
    </>
  );
});

MetaUsers.displayName = 'MetaUsers';

export default MetaUsers;
