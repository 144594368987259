'use client';

import {FlowRunErrorStep, MetaPresetUpsertInput} from '@/__generated__/graphql';
import {
  FileUpload,
  FlowRun,
  FlowSetting,
  GeneratedVideoState,
  PostPlatform,
} from '@/common/types';
import {FlowStep} from '@/components/providers/FlowProvider/types';
import {ApolloError} from '@apollo/client';
import {createContext} from 'react';

export interface FlowContextValue {
  runId: string | undefined;
  run: FlowRun | undefined;
  selectedImage: FileUpload | undefined;
  selectedPlatform: PostPlatform | undefined;
  promptCategory: string | undefined;
  promptText: string | undefined;
  promptCustomText: string | undefined;
  loaderProgress: number | undefined;
  uploadSourceLoading: boolean;
  uploadSourceError: ApolloError | undefined;
  runFlowLoading: boolean;
  runFlowError: ApolloError | undefined;
  generatedVideosState: GeneratedVideoState[];
  selectedImageIsLoading: boolean;
  currentStep: FlowStep;
  isGeneratingVideos: boolean;
  settings: FlowSetting[];
  errorMessage: string | undefined;
  errorStep: FlowRunErrorStep | undefined;
  metaPresetToCreate: MetaPresetUpsertInput | undefined;
  setSelectedImage: (value: FileUpload | undefined) => void;
  setSelectedPlatform: (value: PostPlatform | undefined) => void;
  setPromptCategory: (value: string | undefined) => void;
  setPromptText: (value: string | undefined) => void;
  setPromptCustomText: (value: string | undefined) => void;
  runFlow: () => Promise<void>;
  setGeneratedVideosState: (value: GeneratedVideoState[]) => void;
  updateGeneratedVideoState: (value: {
    id: string;
    data: Partial<GeneratedVideoState>;
  }) => void;
  setCurrentStep: (value: FlowStep) => void;
  setError: (value: {
    errorMessage: string | undefined;
    errorStep: FlowRunErrorStep | undefined;
  }) => void;
  updateSettingValue: (id: string, value: FlowSetting['value']) => void;
  setMetaPresetToCreate: (value: MetaPresetUpsertInput | undefined) => void;
}

export const FlowContext = createContext<FlowContextValue | undefined>(
  undefined,
);
