import {memo, useMemo} from 'react';
import {Button, Checkbox} from '@nextui-org/react';
import {OptionsSetting as OptionsSettingType} from '@/common/types/flowSettings';
import FlowSettingLabel from './Label';
import RecommendedBadge from './RecommendedBadge';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

type Props = {
  setting: OptionsSettingType;
  onChange: (id: string, value: string) => void;
};

const OptionsSetting = memo(({setting, onChange}: Props) => {
  const {_} = useLingui();
  const options = useMemo(
    () => setting.data.options ?? [],
    [setting.data.options],
  );

  return (
    <div className="flex w-full flex-col gap-3">
      <FlowSettingLabel>{setting.label}</FlowSettingLabel>
      <div className="flex w-full flex-col gap-3">
        {options.map(option => (
          <Button
            key={option.value}
            aria-label={_(msg`Option ${option.label}`)}
            data-amp-track-label={`Option ${option.label}`}
            disableAnimation
            disableRipple
            className={`flex h-auto max-h-none w-full flex-row items-center gap-4 rounded-lg bg-transparent p-0 !opacity-100`}
            onPress={() => onChange(setting.id, option.value)}>
            <div className="flex flex-[1_0_auto] items-center gap-4 text-sm">
              <div className="text-foreground">{option.label}</div>
              {option.recommended ? <RecommendedBadge /> : null}
            </div>
            <div className="flex items-center justify-center">
              <Checkbox
                aria-label={_(msg`Checkbox ${option.label}`)}
                data-amp-track-label={`Checkbox ${option.label}`}
                isSelected={setting.value === option.value}
                className="m-0 px-0 py-1 [&>*]:text-small"
                classNames={{
                  icon: 'text-background',
                }}
                radius="full"
                onValueChange={() => onChange(setting.id, option.value)}
              />
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
});

OptionsSetting.displayName = 'OptionsSetting';

export default OptionsSetting;
