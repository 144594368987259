'use client';

import {SVGProps} from 'react';

export default function WhatsappIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.5 8.22857C0.5 5.3483 0.5 3.90817 1.06054 2.80805C1.5536 1.84036 2.34036 1.0536 3.30805 0.560538C4.40817 0 5.8483 0 8.72857 0H16.2714C19.1517 0 20.5918 0 21.692 0.560538C22.6596 1.0536 23.4464 1.84036 23.9395 2.80805C24.5 3.90817 24.5 5.3483 24.5 8.22857V15.7714C24.5 18.6517 24.5 20.0918 23.9395 21.192C23.4464 22.1596 22.6596 22.9464 21.692 23.4395C20.5918 24 19.1517 24 16.2714 24H8.72857C5.8483 24 4.40817 24 3.30805 23.4395C2.34036 22.9464 1.5536 22.1596 1.06054 21.192C0.5 20.0918 0.5 18.6517 0.5 15.7714V8.22857Z"
        fill="#25D366"
      />
      <g clipPath="url(#clip0_5122_24922)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1805 6.75671C16.7304 5.30469 14.8017 4.50464 12.7467 4.50391C8.51219 4.50391 5.06593 7.94979 5.06446 12.1854C5.06372 13.5394 5.41771 14.8611 6.08994 16.0258L5 20.0069L9.07244 18.9387C10.1944 19.5509 11.4578 19.8732 12.7434 19.8735H12.7467C16.9805 19.8735 20.4271 16.4273 20.4286 12.1917C20.4293 10.1389 19.6311 8.20911 18.1805 6.75708V6.75671ZM12.7467 18.5762H12.7441C11.5985 18.5759 10.4747 18.2679 9.49421 17.6863L9.26104 17.5478L6.84431 18.1817L7.48929 15.8254L7.33753 15.5838C6.69845 14.5671 6.36067 13.3921 6.36141 12.1858C6.36288 8.66549 9.22715 5.80122 12.7493 5.80122C14.4547 5.80196 16.0578 6.46683 17.2634 7.6739C18.469 8.88061 19.1324 10.4851 19.1316 12.1909C19.1301 15.7116 16.2659 18.5759 12.7467 18.5759V18.5762ZM16.2489 13.7943C16.057 13.6982 15.1133 13.2341 14.9372 13.17C14.7612 13.1059 14.6334 13.0739 14.5055 13.2661C14.3777 13.4584 14.0098 13.8909 13.8978 14.0187C13.7858 14.1468 13.6738 14.1627 13.4819 14.0665C13.29 13.9704 12.6716 13.7678 11.9382 13.114C11.3676 12.6049 10.9823 11.9766 10.8704 11.7843C10.7584 11.592 10.8586 11.4881 10.9543 11.3927C11.0405 11.3065 11.1462 11.1684 11.2424 11.0564C11.3385 10.9444 11.3702 10.8642 11.4343 10.7363C11.4984 10.6081 11.4663 10.4962 11.4185 10.4C11.3705 10.3039 10.9868 9.35906 10.8265 8.97489C10.6707 8.60066 10.5123 8.65149 10.3948 8.64523C10.2828 8.6397 10.155 8.6386 10.0268 8.6386C9.89866 8.6386 9.69089 8.68648 9.51483 8.87876C9.33877 9.07102 8.84296 9.53551 8.84296 10.4799C8.84296 11.4244 9.53069 12.3375 9.62681 12.4657C9.72294 12.5939 10.9805 14.5325 12.9058 15.3642C13.3637 15.562 13.7213 15.6803 14.0002 15.7687C14.4599 15.9149 14.8783 15.8943 15.2091 15.8449C15.5778 15.7897 16.3447 15.3804 16.5046 14.9322C16.6644 14.4839 16.6644 14.0993 16.6166 14.0194C16.5687 13.9395 16.4405 13.8912 16.2486 13.7951L16.2489 13.7943Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5122_24922">
          <rect
            width="15.4286"
            height="15.5143"
            fill="white"
            transform="translate(5 4.50391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
