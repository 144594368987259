'use client';

import {SVGProps} from 'react';

export default function CheckRectEmptyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}>
      <path
        d="M3 8.47187C3 6.79172 3 5.95164 3.32698 5.3099C3.6146 4.74542 4.07354 4.28648 4.63803 3.99886C5.27976 3.67188 6.11984 3.67188 7.8 3.67188H16.2C17.8802 3.67188 18.7202 3.67188 19.362 3.99886C19.9265 4.28648 20.3854 4.74542 20.673 5.3099C21 5.95164 21 6.79172 21 8.47188V16.8719C21 18.552 21 19.3921 20.673 20.0338C20.3854 20.5983 19.9265 21.0573 19.362 21.3449C18.7202 21.6719 17.8802 21.6719 16.2 21.6719H7.8C6.11984 21.6719 5.27976 21.6719 4.63803 21.3449C4.07354 21.0573 3.6146 20.5983 3.32698 20.0338C3 19.3921 3 18.552 3 16.8719V8.47187Z"
        fill="black"
        fillOpacity="0.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
