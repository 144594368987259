'use client';

import {SVGProps} from 'react';

export default function VideoRecorderOffIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      {...props}>
      <path
        d="M9.66699 9.16797C6.62943 9.16797 4.16699 11.6304 4.16699 14.668V29.3346C4.16699 32.3722 6.62943 34.8346 9.66699 34.8346H26.167C28.6469 34.8346 30.7434 33.1934 31.4297 30.9379M31.667 22.0013L38.3299 15.3384C39.1153 14.553 39.5079 14.1604 39.8451 14.1338C40.1376 14.1108 40.4235 14.2292 40.614 14.4523C40.8337 14.7095 40.8337 15.2648 40.8337 16.3755V27.6271C40.8337 28.7378 40.8337 29.2931 40.614 29.5503C40.4235 29.7734 40.1376 29.8918 39.8451 29.8688C39.5079 29.8422 39.1153 29.4496 38.3299 28.6642L31.667 22.0013ZM31.667 22.0013V17.968C31.667 14.8877 31.667 13.3475 31.0675 12.171C30.5402 11.1361 29.6988 10.2947 28.6639 9.76743C27.4874 9.16797 25.9473 9.16797 22.867 9.16797H17.917M4.16699 3.66797L40.8337 40.3346"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
