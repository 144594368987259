import {memo} from 'react';
import {Button} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {GeneratedVideoState} from '@/common/types';
import usePresignedLink from '@/hooks/usePresignedLink';
import {FlowContextValue} from '@/contexts/FlowContext';
import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import PlayIcon from '@/components/icons/PlayIcon';
import VideoSelectorItemSkeleton from './VideoSelectorItemSkeleton';
import CheckRectIcon from '@/components/icons/CheckRectIcon';
import CheckRectEmptyIcon from '@/components/icons/CheckRectEmptyIcon';

const VideoSelectorItem = memo(
  ({
    id,
    isSelected,
    thumbnailName,
    caption,
    musicAuthor,
    musicTitle,
    updateGeneratedVideoState,
  }: GeneratedVideoState & {
    isLast: boolean;
    updateGeneratedVideoState: FlowContextValue['updateGeneratedVideoState'];
  }) => {
    const {_} = useLingui();
    const thumbnailURL = usePresignedLink(thumbnailName);
    let musicParts = [musicTitle, musicAuthor].filter(Boolean);

    if (musicParts.length === 2) {
      musicParts = [musicParts[0], 'by', musicParts[1]];
    }

    return (
      <div className="relative h-full w-full">
        <Button
          aria-label={_(msg`Preview video`)}
          data-amp-track-label="Preview video"
          as={RouterLink}
          to={`asset/edit/${id}`}
          disableAnimation
          disableRipple
          isIconOnly
          className="block h-auto max-h-none min-h-0 w-full min-w-0 max-w-none rounded-xl bg-transparent p-0 text-foreground">
          {thumbnailURL ? (
            <img
              src={thumbnailURL}
              alt={caption ?? 'Video thumbnail'}
              className="block h-auto min-h-[6rem] w-full"
            />
          ) : (
            <VideoSelectorItemSkeleton className="aspect-[9/16]" />
          )}
        </Button>
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <PlayIcon className="h-10 w-10 text-foreground" />
          <Button
            aria-label={_(msg`Toggle video selection`)}
            data-amp-track-label="Toggle video selection"
            disableAnimation
            disableRipple
            isIconOnly
            className="pointer-events-auto absolute right-0 top-0 z-10 h-auto min-h-0 w-auto min-w-0 rounded-none bg-transparent p-1"
            onPress={() =>
              updateGeneratedVideoState({
                id,
                data: {isSelected: !isSelected},
              })
            }>
            {isSelected ? (
              <CheckRectIcon className="h-6 w-6 text-primary" />
            ) : (
              <CheckRectEmptyIcon className="h-6 w-6 text-primary" />
            )}
          </Button>
        </div>
      </div>
    );
  },
);

VideoSelectorItem.displayName = 'VideoSelectorItem';

export default VideoSelectorItem;
