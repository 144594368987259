import {useAuthContext} from '@/hooks';
import {Avatar, Button} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import {memo} from 'react';
import PortalLogo from '@/components/icons/PortalLogo';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

export const ProfileHeader = memo(() => {
  const {_} = useLingui();
  const {user} = useAuthContext();

  return (
    <div className="flex w-full items-center justify-between gap-3 py-3">
      <Button
        aria-label={_(msg`Back to home`)}
        data-amp-track-label={`Back to home`}
        as={RouterLink}
        to={'..'}
        disableRipple
        disableAnimation
        className="flex h-auto max-h-none min-h-0 w-auto min-w-0 max-w-none shrink-0 flex-col rounded-none bg-transparent p-0">
        <div>
          <PortalLogo className="block h-auto min-w-[7rem] shrink-0 text-foreground" />
        </div>
      </Button>
      <div className="relative flex shrink-0">
        <Avatar
          as={RouterLink}
          to={'..'}
          src={user?.avatar ?? undefined}
          className="h-7 w-7 shrink-0"
        />
        <div className="pointer-events-none absolute inset-0 rounded-full border-foreground/15 [border-width:1.5px]" />
      </div>
    </div>
  );
});

ProfileHeader.displayName = 'ProfileHeader';
