'use client';

import {cloneElement, FC} from 'react';
import {useLocation, useNavigate, useOutlet} from 'react-router-dom';
import {Modal, ModalContent, ModalProps} from './Modal';
import {useAppContext, useAuthContext} from '@/hooks';
import PortalSpinner from './PortalSpinner';
import {twMerge} from 'tailwind-merge';

const ModalOutlet: FC<
  {basePath?: string; subPath: string | string[]; replace?: boolean} & Omit<
    ModalProps,
    'children'
  >
> = ({basePath: basePathProps = '', subPath, replace, ...props}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const outlet = useOutlet();
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();
  const showLoader = isAuthenticated && !user;
  const basePath =
    basePathProps && !basePathProps.startsWith('/')
      ? '/' + basePathProps
      : basePathProps;

  if (!Array.isArray(subPath)) {
    subPath = [subPath];
  }

  const isOpen = Boolean(
    outlet && subPath.some(i => pathname.startsWith(basePath + '/' + i)),
  );

  return (
    <>
      {showLoader ? (
        <PortalSpinner
          classNames={{base: 'backdrop-blur-sm fixed inset-0 z-[1000]'}}
          size="lg"
        />
      ) : null}
      <Modal
        isOpen={isOpen}
        isDismissable={false}
        onOpenChange={isOpen => {
          if (!isOpen) {
            navigate(basePath, {replace});
          }
        }}
        {...props}
        className={twMerge(props.className, showLoader ? 'invisible' : '')}>
        <ModalContent>
          {outlet && cloneElement(outlet, {key: pathname})}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalOutlet;
