import {memo, useCallback, useEffect, useMemo} from 'react';
import Select, {
  ClassNamesConfig,
  components,
  InputProps,
  MultiValueRemoveProps,
  OptionProps,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';
import {DropdownSetting as DropdownSettingType} from '@/common/types/flowSettings';
import FlowSettingLabel from './Label';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

type Props = {
  setting: DropdownSettingType;
  filterValue?: any;
  onChange: (id: string, value: DropdownSettingType['value']) => void;
};

const DropdownSetting = memo(({setting, filterValue, onChange}: Props) => {
  const {_} = useLingui();
  const options = useMemo(() => {
    const options = setting.data.options ?? [];

    if (filterValue) {
      return options.filter(
        option => !option.filter_value || option.filter_value === filterValue,
      );
    }

    if (setting.data.filtered_by) {
      return [];
    }

    return options;
  }, [setting, filterValue]);

  const multiselect = setting.data.multiselect ?? false;
  const searchable = setting.data.searchable ?? true;

  const value = setting.value;

  const selectedValue = useMemo(() => {
    if (multiselect) {
      return options.filter(option => value && value.includes(option.value));
    } else {
      return options.find(option => option.value === value) || null;
    }
  }, [value, options, multiselect]);

  const handleChange = useCallback(
    (selectedOption: any) => {
      let newValue;
      if (multiselect) {
        newValue = selectedOption
          ? selectedOption.map((option: any) => option.value)
          : [];
      } else {
        newValue = selectedOption ? selectedOption.value : undefined;
      }
      onChange(setting.id, newValue);
    },
    [onChange, setting, multiselect],
  );

  const classNames: ClassNamesConfig = useMemo(
    () => ({
      indicatorSeparator: () => 'hidden',
      clearIndicator: () => '!text-foreground',
      dropdownIndicator: () => '!text-foreground',
      option: ({isSelected, isFocused}) =>
        `!text-foreground ${isSelected || isFocused ? ' !bg-primary' : ''}`,
      input: () => '!text-foreground',
      singleValue: () => '!text-foreground',
      multiValueLabel: () => '!text-foreground',
      multiValue: () => '!text-foreground !rounded-lg !bg-primary',
      menu: () => '!bg-[#3c3c3c] !rounded-xl overflow-hidden',
      control: () =>
        '!bg-[#3c3c3c] !border-none !shadow-none !rounded-xl !py-[2px]',
      container: () => '!text-sm',
    }),
    [],
  );

  useEffect(() => {
    if (
      setting.data.set_default_if_single &&
      options.length === 1 &&
      !setting.value
    ) {
      onChange(setting.id, options[0].value);
    }
  }, [setting, options]);

  return (
    <div className="flex w-full flex-col gap-3">
      <FlowSettingLabel>{setting.label}</FlowSettingLabel>
      <Select
        aria-label={_(msg`Select setting ${setting.label}`)}
        placeholder={setting.data.placeholder ?? 'Select an option'}
        options={options}
        value={selectedValue}
        onChange={handleChange}
        isMulti={multiselect}
        isSearchable={searchable}
        classNames={classNames}
        components={{
          Option: (props: OptionProps) => {
            const {_} = useLingui();
            const innerProps = {
              ...props.innerProps,
              'aria-disabled': props.isDisabled,
              'aria-label': _(msg`Select setting ${setting.label} option`),
              'data-amp-track-label': `Select setting ${setting.label} option`,
            };

            return <components.Option {...props} innerProps={innerProps} />;
          },
          Input: (props: InputProps) => (
            <components.Input
              {...props}
              data-amp-track-label={`Select setting ${setting.label}`}
            />
          ),
          MultiValueRemove: (props: MultiValueRemoveProps) => {
            const innerProps = {
              ...props.innerProps,
              'aria-label': _(msg`Select setting ${setting.label} remove`),
              'data-amp-track-label': `Select setting ${setting.label} remove`,
            };

            return (
              <components.MultiValueRemove {...props} innerProps={innerProps} />
            );
          },
          DropdownIndicator: (props: DropdownIndicatorProps) => {
            const innerProps = {
              ...props.innerProps,
              'aria-label': _(msg`Select setting ${setting.label} toggle`),
              'data-amp-track-label': `Select setting ${setting.label} toggle`,
            };

            return (
              <components.DropdownIndicator
                {...props}
                innerProps={innerProps}
              />
            );
          },
          ClearIndicator: (props: ClearIndicatorProps) => {
            const innerProps = {
              ...props.innerProps,
              'aria-label': _(msg`Select setting ${setting.label} clear`),
              'data-amp-track-label': `Select setting ${setting.label} clear`,
            };

            return (
              <components.ClearIndicator {...props} innerProps={innerProps} />
            );
          },
        }}
      />
    </div>
  );
});

DropdownSetting.displayName = 'DropdownSetting';

export default DropdownSetting;
