'use client';

import {screens} from '@/common/tailwind';
import {useMatchesMedia} from './useMatchesMedia';

export const useMatchesTWBreakpoint = (breakpointKey: keyof typeof screens) => {
  const breakpoint = screens[breakpointKey];
  const query = `(min-width: ${breakpoint})`;

  return useMatchesMedia(query);
};
