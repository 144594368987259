'use client';

import {FC, useCallback, useMemo} from 'react';
import {ResponsiveContainer, Area, ComposedChart} from 'recharts';
import {AreaChartMetric} from '@/components/LiveMetric/types';

const colors = [
  {
    stroke: '#6B4AFF',
    fill: [
      {color: '#4927E1', offset: 0.15},
      {color: '#262626', offset: 0.85},
    ],
  },
  {
    stroke: '#FF7C00',
    fill: [
      {color: '#FF7C00', offset: 0.15},
      {color: '#262626', offset: 0.85},
    ],
  },
  {
    stroke: '#00C7FF',
    fill: [
      {color: '#00C7FF', offset: 0.15},
      {color: '#262626', offset: 0.85},
    ],
  },
];

const AreaChart: FC<{
  data: AreaChartMetric['chartData'];
  yDataKeys: AreaChartMetric['chartYDataKeys'];
}> = ({data, yDataKeys}) => {
  const linearGradientsMap = useMemo(() => {
    return new Map<string, {color: string; offset: number}[]>(
      yDataKeys.map((key, index) => [
        `${key}-${index}-fill`,
        colors[index].fill,
      ]),
    );
  }, [yDataKeys]);
  const linearGradients = useMemo(() => {
    return Array.from(linearGradientsMap.entries()).map(([id, colors]) => (
      <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
        {colors.map((color, index) => (
          <stop key={index} offset={color.offset} stopColor={color.color} />
        ))}
      </linearGradient>
    ));
  }, [linearGradientsMap]);
  const getColor = useCallback(
    (key: string, index: number, property: 'fill' | 'stroke') => {
      if (property === 'fill') {
        return `url(#${key}-${index}-${property})`;
      }

      return colors[index]?.stroke ?? 'transparent';
    },
    [],
  );

  return (
    <ResponsiveContainer height={84}>
      <ComposedChart
        margin={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        data={data}>
        <defs>{linearGradients}</defs>

        {yDataKeys.map((yDataKey, idx) => (
          <Area
            key={yDataKey}
            isAnimationActive={false}
            activeDot={({cx, cy, index}: any) => {
              const item = data[index][yDataKey];

              if (!Number.isFinite(item)) {
                return <></>;
              }

              return (
                <circle
                  cx={cx}
                  cy={cy}
                  r={4}
                  fill="background"
                  stroke={getColor(yDataKey, idx, 'stroke')}
                  strokeWidth={2}
                />
              );
            }}
            className={'cursor-default'}
            dataKey={yDataKey}
            fill={getColor(yDataKey, idx, 'fill')}
            stroke={getColor(yDataKey, idx, 'stroke')}
            strokeWidth={2}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
