import {memo} from 'react';
import {Input} from '@nextui-org/react';
import {TextSetting as TextSettingType} from '@/common/types/flowSettings';
import FlowSettingLabel from './Label';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

type Props = {
  setting: TextSettingType;
  onChange: (id: string, value: TextSettingType['value']) => void;
};

const TextSetting = memo(({setting, onChange}: Props) => {
  const {_} = useLingui();

  return (
    <div className="flex w-full flex-col gap-3">
      <FlowSettingLabel>{setting.label}</FlowSettingLabel>
      <Input
        aria-label={_(msg`Text setting ${setting.label}`)}
        data-amp-track-label={`Text setting ${setting.label}`}
        fullWidth
        required={setting.required}
        minLength={setting.data.min}
        maxLength={setting.data.max}
        placeholder={setting.data?.placeholder ?? ''}
        classNames={{
          inputWrapper:
            'bg-foreground/10 group-data-[focus=true]:bg-foreground/10 group-data-[hover=true]:bg-foreground/10',
        }}
        value={setting.value || ''}
        onChange={e => onChange(setting.id, e.target.value)}
      />
    </div>
  );
});

TextSetting.displayName = 'TextSetting';

export default TextSetting;
