'use client';

import {SVGProps} from 'react';

export default function Mail02SolidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2.06552 8.21149C1.82337 8.04056 1.70229 7.95509 1.59906 7.93423C1.37159 7.88825 1.13609 8.01028 1.04248 8.22263C0.999994 8.319 0.999996 8.46556 1 8.75869V15.2413C0.999988 16.0463 0.999978 16.7106 1.04419 17.2518C1.06792 17.5422 1.40639 17.659 1.62227 17.4634L6.81647 12.7581C7.06952 12.5289 7.19605 12.4142 7.23639 12.2826C7.27177 12.1673 7.26421 12.043 7.21513 11.9327C7.15914 11.807 7.01967 11.7085 6.74072 11.5116L2.06552 8.21149Z"
        fill="currentColor"
      />
      <path
        d="M2.69115 19.1938C2.48752 19.3782 2.38571 19.4705 2.3454 19.6023C2.31416 19.7045 2.32351 19.8551 2.36717 19.9527C2.42348 20.0785 2.51634 20.1437 2.70207 20.2741C2.85507 20.3815 3.01605 20.4784 3.18404 20.564C3.66937 20.8113 4.18608 20.9099 4.74818 20.9558C5.28937 21 5.95372 21 6.75868 21H17.2413C18.0463 21 18.7106 21 19.2518 20.9558C19.8139 20.9099 20.3306 20.8113 20.816 20.564C20.984 20.4784 21.1449 20.3814 21.2979 20.2741C21.4837 20.1437 21.5765 20.0785 21.6328 19.9527C21.6765 19.8551 21.6859 19.7045 21.6546 19.6023C21.6143 19.4705 21.5125 19.3782 21.3089 19.1938L15.1892 13.65C15.0722 13.5441 15.0138 13.4911 14.9505 13.4601C14.8306 13.4013 14.6922 13.3929 14.5661 13.4368C14.4995 13.4599 14.435 13.5055 14.3062 13.5965C13.7589 13.984 13.2783 14.3243 12.7303 14.4618C12.2509 14.5821 11.7493 14.5821 11.2699 14.4618C10.7218 14.3243 10.2413 13.984 9.694 13.5965C9.56505 13.5054 9.50057 13.4599 9.43394 13.4367C9.30788 13.3929 9.16954 13.4013 9.0497 13.46C8.98636 13.491 8.92785 13.544 8.81085 13.65L2.69115 19.1938Z"
        fill="currentColor"
      />
      <path
        d="M22.3777 17.4634C22.5936 17.659 22.9321 17.5421 22.9558 17.2518C23 16.7106 23 16.0463 23 15.2413V8.75868C23 8.46566 23 8.31916 22.9576 8.22282C22.864 8.0104 22.6284 7.88833 22.4009 7.93436C22.2977 7.95523 22.1766 8.04066 21.9346 8.21153L17.2594 11.5117C16.9804 11.7086 16.8409 11.807 16.785 11.9328C16.7359 12.043 16.7283 12.1673 16.7637 12.2827C16.804 12.4143 16.9306 12.5289 17.1836 12.7582L22.3777 17.4634Z"
        fill="currentColor"
      />
      <path
        d="M22.2796 4.70729C22.3888 4.86318 22.4434 4.94112 22.4602 5.05968C22.4733 5.15207 22.4508 5.2809 22.4072 5.36341C22.3513 5.46929 22.2568 5.53604 22.0676 5.66953L13.2688 11.8805C12.5361 12.3977 12.3807 12.4875 12.2435 12.5219C12.0837 12.562 11.9165 12.562 11.7567 12.5219C11.6195 12.4875 11.4641 12.3977 10.7314 11.8805L1.93241 5.66945C1.74329 5.53596 1.64873 5.46921 1.5928 5.36333C1.54921 5.28082 1.52676 5.15199 1.53986 5.05959C1.55667 4.94103 1.61126 4.86309 1.72044 4.7072C2.09472 4.17279 2.604 3.73152 3.18404 3.43598C3.66937 3.18868 4.18608 3.09012 4.74818 3.04419C5.28937 2.99998 5.95372 2.99999 6.75869 3H17.2413C18.0463 2.99999 18.7106 2.99998 19.2518 3.04419C19.8139 3.09012 20.3306 3.18868 20.816 3.43598C21.396 3.73154 21.9053 4.17284 22.2796 4.70729Z"
        fill="currentColor"
      />
    </svg>
  );
}
