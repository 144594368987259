'use client';

import {gql} from '@/__generated__';
import {CreateCheckoutSessionMutationVariables} from '@/__generated__/graphql';
import {useMutation} from '@apollo/client';

const CREATE_CHECKOUT_SESSION = gql(`
  mutation CreateCheckoutSession($input: CheckoutSessionCreateInput!) {
    checkoutSession: createStripeCheckoutSession(input: $input) {
      id
      url
    }
  }
`);

type Input =
  | CreateCheckoutSessionMutationVariables['input']
  | (() => CreateCheckoutSessionMutationVariables['input']);

export const useCheckoutSession = (input: Input) => {
  const [createCheckoutSession, {loading}] = useMutation(
    CREATE_CHECKOUT_SESSION,
    {
      variables: {
        input: typeof input === 'function' ? input() : input,
      },
      onCompleted: data => {
        window.location.href = data.checkoutSession.url;
      },
    },
  );

  return {createCheckoutSession, loading};
};
