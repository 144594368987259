'use client';

import snakeCase from 'lodash/snakeCase';
import merge from 'lodash/merge';
import {utmPropertiesVar, UTM_PROPERTY_NAMES} from '@/utmProperties';
import {getWindow, toBase64} from '@/utils';

export interface InstrumentEventMetadata {
  user_id?: string;
  user_properties?: Record<string, any>;
  event_properties?: Record<string, any>;
  event_id?: number;
  session_id?: number;
  device_id?: string;
  platform?: string;
  os_name?: string;
  os_version?: string;
  device_brand?: string;
  device_manufacturer?: string;
  device_model?: string;
  carrier?: string;
  country?: string;
  region?: string;
  city?: string;
  app_version?: string;
}

export enum InstrumentEventName {
  SIGNIN_SUCCESS = 'fe_sign_in_success',
  SIGNUP_SUCCESS = 'fe_sign_up_success',
  LOGOUT = 'fe_logout_start',
  AUTH_STARTED_EMAIL = 'fe_email_auth_start',
  AUTH_STARTED_GOOGLE = 'fe_google_auth_start',
  AUTH_STARTED_META = 'fe_meta_auth_start',
  AUTH_STARTED_APPLE = 'fe_apple_auth_start',
  SUBSCRIBE_STARTED = 'fe_stripe_subscribe_start',
  INTEGRATION_START = 'fe_integration_start',
  INTEGRATION_PROVIDED = 'fe_integration_provided',
  FE_INTEGRATION_REDIRECT = 'fe_integration_redirect',
  FE_INTEGRATION_CALLBACK = 'fe_integration_callback',
  FE_LAUNCH_AD_CAMPAIGN_START = 'fe_launch_ad_campaign_start',
}

export abstract class InstrumentTransport {
  abstract init(): Promise<void>;
  abstract track(
    eventName: string,
    eventProperties?: Record<string, any>,
    eventMetadata?: InstrumentEventMetadata,
  ): Promise<void>;

  abstract getMetadata(): Promise<InstrumentEventMetadata | undefined>;
  abstract flushQueue(): Promise<void>;
  abstract setUserId(userId?: string): Promise<void>;
}

export class InstrumentTracker {
  private initialization: Promise<void[]>;

  constructor(private readonly transports: InstrumentTransport[]) {
    this.initialization = Promise.all(
      transports.map(t => t.init().catch(console.error)),
    );
  }

  track = async (
    eventName: string,
    eventProperties?: Record<string, any>,
    eventMetadata?: InstrumentEventMetadata,
  ): Promise<void> => {
    await this.initialization;

    const defaultProperties: Record<string, any> = {
      ...this._getPageMetadata(),
    };
    eventProperties = eventProperties || {};

    const results = await Promise.allSettled(
      this.transports.map(transport => {
        return transport.track(
          eventName,
          {...defaultProperties, ...eventProperties},
          eventMetadata,
        );
      }),
    );
    const errors = results.filter(result => result.status === 'rejected');

    if (errors.length > 0) {
      throw new Error(
        `Failed to track event ${eventName} with properties ${JSON.stringify(
          eventProperties,
        )} and metadata ${JSON.stringify(eventMetadata)}: ${errors
          .map((error: any) => error.reason?.message || error.reason)
          .join(', ')}`,
      );
    }
  };

  flushQueue = async (): Promise<void> => {
    await this.initialization;

    await Promise.allSettled(
      this.transports.map(transport => transport.flushQueue()),
    );
  };

  getMetadata = async (): Promise<Record<string, InstrumentEventMetadata>> => {
    await this.initialization;

    const metadata = await Promise.all(
      this.transports.map(transport => transport.getMetadata()),
    );
    const extraMetadata: Partial<InstrumentEventMetadata> = {
      event_properties: this._getPageMetadata(),
    };

    return merge({}, ...metadata, extraMetadata);
  };

  setUserId = async (userId?: string): Promise<void> => {
    await this.initialization;

    await Promise.all(this.transports.map(t => t.setUserId(userId)));

    if (!userId) {
      utmPropertiesVar({});
    }
  };

  buildHeaders = async (): Promise<Record<string, string>> => {
    await this.initialization;

    const metadata = await this.getMetadata();

    return {'x-session-metadata': toBase64(JSON.stringify(metadata))};
  };

  private _getPageMetadata = (): Record<string, any> => {
    const pageSearchParams: Record<string, any> = {};
    const locationUtmParams: Record<string, any> = {};
    const window = getWindow();

    for (const [key, value] of new URLSearchParams(window?.location.search)) {
      if (UTM_PROPERTY_NAMES.has(key)) {
        locationUtmParams[key] = value;
      } else {
        pageSearchParams['page_search_' + snakeCase(key)] = value;
      }
    }

    return {
      page_domain: window?.location.hostname,
      page_location: window?.location.href,
      page_title: document.title,
      page_path: window?.location.pathname,
      page_url:
        (window?.location.origin ?? '') + (window?.location.pathname ?? ''),
      ...utmPropertiesVar(),
      ...pageSearchParams,
      ...locationUtmParams,
    };
  };
}
