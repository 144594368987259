import dayjs, {ConfigType} from 'dayjs';
import {useEffect, useState} from 'react';

const getDiffInMs = (endTime: ConfigType, startTime?: ConfigType) => {
  return dayjs(endTime).diff(startTime ?? dayjs(), 'milliseconds');
};
export const useCountdown = (endTime: ConfigType, stepMs: number = 10) => {
  const [duration, setDuration] = useState(
    dayjs.duration(Math.max(getDiffInMs(endTime), 0), 'milliseconds'),
  );

  useEffect(() => {
    if (dayjs().isAfter(endTime)) {
      return;
    }

    const interval = setInterval(() => {
      setDuration(dayjs.duration(getDiffInMs(endTime)));
    }, stepMs);

    return () => clearInterval(interval);
  }, [stepMs, endTime]);

  return duration;
};
