import {
  MetaAdAccount,
  MetaBusinessPage,
  MetaPixel,
  MetaPresetData as MetaPresetDataBase,
  MetaPreset as MetaPresetBase,
} from '@/__generated__/graphql';
import {useCallback, useEffect, useMemo, useReducer} from 'react';
import MetaPresetDropdown, {Option} from '@/components/MetaPresetDropdown';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

type MetaPresetData = MetaPresetDataBase;

type MetaPreset = Omit<MetaPresetBase, 'platformUser'>;

type ValidationStatus = {
  isMissing: boolean;
  originalValue: string | null;
};

type MetaPresetState = {
  selectedUserId: string | null;
  selectedPageId: string | null;
  selectedAdAccountId: string | null;
  selectedPixelId: string | null;
  availablePages: Array<MetaBusinessPage>;
  availableAdAccounts: Array<MetaAdAccount>;
  availablePixels: Array<MetaPixel>;
  data: MetaPresetData | null;
  validation: {
    user: ValidationStatus;
    page: ValidationStatus;
    adAccount: ValidationStatus;
    pixel: ValidationStatus;
  };
};

type MetaPresetAction =
  | {type: 'SELECT_USER'; payload: string}
  | {type: 'SELECT_PAGE'; payload: string}
  | {type: 'SELECT_AD_ACCOUNT'; payload: string}
  | {type: 'SELECT_PIXEL'; payload: string}
  | {type: 'SET_DATA'; payload: MetaPresetData | null}
  | {
      type: 'INIT_VALUES';
      payload: {
        userId: string | null;
        pageId: string | null;
        adAccountId: string | null;
        pixelId: string | null;
      };
    }
  | {type: 'RESET_ALL'};

const initialValidationStatus: ValidationStatus = {
  isMissing: false,
  originalValue: null,
};

const initialState: MetaPresetState = {
  selectedUserId: null,
  selectedPageId: null,
  selectedAdAccountId: null,
  selectedPixelId: null,
  availablePages: [],
  availableAdAccounts: [],
  availablePixels: [],
  data: null,
  validation: {
    user: {...initialValidationStatus},
    page: {...initialValidationStatus},
    adAccount: {...initialValidationStatus},
    pixel: {...initialValidationStatus},
  },
};

type FieldConfig = {
  field: keyof Pick<
    MetaPresetState,
    | 'selectedUserId'
    | 'selectedPageId'
    | 'selectedAdAccountId'
    | 'selectedPixelId'
  >;
  validationKey: keyof MetaPresetState['validation'];
  availableField: keyof Pick<
    MetaPresetState,
    'availablePages' | 'availableAdAccounts' | 'availablePixels'
  >;
  getAvailableItems: (state: MetaPresetState) => any[];
  dependentFields: Array<{
    field: keyof MetaPresetState;
    validationKey: keyof MetaPresetState['validation'];
    availableField: keyof MetaPresetState;
  }>;
};

const FIELD_HIERARCHY: Record<string, FieldConfig> = {
  user: {
    field: 'selectedUserId',
    validationKey: 'user',
    availableField: 'availablePages',
    getAvailableItems: state => state.data?.users || [],
    dependentFields: [
      {
        field: 'selectedPageId',
        validationKey: 'page',
        availableField: 'availablePages',
      },
      {
        field: 'selectedAdAccountId',
        validationKey: 'adAccount',
        availableField: 'availableAdAccounts',
      },
      {
        field: 'selectedPixelId',
        validationKey: 'pixel',
        availableField: 'availablePixels',
      },
    ],
  },
  page: {
    field: 'selectedPageId',
    validationKey: 'page',
    availableField: 'availablePages',
    getAvailableItems: state => {
      const user = state.data?.users.find(u => u.id === state.selectedUserId);
      return user?.pages || [];
    },
    dependentFields: [],
  },
  adAccount: {
    field: 'selectedAdAccountId',
    validationKey: 'adAccount',
    availableField: 'availableAdAccounts',
    getAvailableItems: state => {
      const user = state.data?.users.find(u => u.id === state.selectedUserId);
      return user?.adAccounts || [];
    },
    dependentFields: [
      {
        field: 'selectedPixelId',
        validationKey: 'pixel',
        availableField: 'availablePixels',
      },
    ],
  },
  pixel: {
    field: 'selectedPixelId',
    validationKey: 'pixel',
    availableField: 'availablePixels',
    getAvailableItems: state => {
      const user = state.data?.users.find(u => u.id === state.selectedUserId);
      const adAccount = user?.adAccounts.find(
        a => a.id === state.selectedAdAccountId,
      );
      return adAccount?.pixels || [];
    },
    dependentFields: [],
  },
};

const updateStateForField = (
  state: MetaPresetState,
  fieldKey: keyof typeof FIELD_HIERARCHY,
  newValue: string | null,
): Partial<MetaPresetState> & {
  validation: MetaPresetState['validation'];
} => {
  const config = FIELD_HIERARCHY[fieldKey];

  const updates: Partial<MetaPresetState> & {
    validation: MetaPresetState['validation'];
  } = {
    [config.field]: newValue,
    validation: {...state.validation},
  };

  // Get available items for the current field
  const availableItems = config.getAvailableItems(state);
  const itemExists = newValue
    ? availableItems.some(item => item.id === newValue)
    : true;

  updates.validation[config.validationKey] = {
    isMissing: Boolean(newValue && !itemExists),
    originalValue: newValue,
  };

  // Reset dependent fields
  config.dependentFields.forEach(dependentField => {
    updates[dependentField.field] = null as any;
    updates[dependentField.availableField] = [] as any;

    updates.validation[dependentField.validationKey] = {
      ...initialValidationStatus,
    };
  });

  // Update available items based on the new selection
  if (fieldKey === 'user' && newValue) {
    const selectedUser = state.data?.users.find(u => u.id === newValue);
    updates.availablePages = selectedUser?.pages || [];
    updates.availableAdAccounts = selectedUser?.adAccounts || [];
  } else if (fieldKey === 'adAccount' && newValue) {
    const selectedUser = state.data?.users.find(
      u => u.id === state.selectedUserId,
    );
    const selectedAdAccount = selectedUser?.adAccounts.find(
      a => a.id === newValue,
    );
    updates.availablePixels = selectedAdAccount?.pixels || [];
  }

  return updates;
};

const metaPresetReducer = (
  state: MetaPresetState,
  action: MetaPresetAction,
): MetaPresetState => {
  if (!state.data && action.type !== 'SET_DATA') {
    return state;
  }

  switch (action.type) {
    case 'SET_DATA': {
      // If there's only one user, auto-select it
      const singleUser =
        action.payload?.users.length === 1 ? action.payload.users[0] : null;

      if (singleUser) {
        const updates = updateStateForField(
          {...state, data: action.payload},
          'user',
          singleUser.id,
        );

        return {
          ...state,
          data: action.payload,
          ...updates,
        };
      }

      return {
        ...state,
        data: action.payload,
      };
    }

    case 'SELECT_USER': {
      // If there's only one user, prevent changing selection
      if (state.data?.users.length === 1) {
        return state;
      }

      return {
        ...state,
        ...updateStateForField(state, 'user', action.payload),
      };
    }

    case 'SELECT_PAGE':
      return {
        ...state,
        ...updateStateForField(state, 'page', action.payload),
      };

    case 'SELECT_AD_ACCOUNT':
      return {
        ...state,
        ...updateStateForField(state, 'adAccount', action.payload),
      };

    case 'SELECT_PIXEL':
      return {
        ...state,
        ...updateStateForField(state, 'pixel', action.payload),
      };

    case 'INIT_VALUES': {
      return ['user', 'page', 'adAccount', 'pixel'].reduce(
        (accState, fieldKey) => {
          const value = {
            user: action.payload.userId,
            page: action.payload.pageId,
            adAccount: action.payload.adAccountId,
            pixel: action.payload.pixelId,
          }[fieldKey];

          const updates = updateStateForField(
            accState,
            fieldKey as keyof typeof FIELD_HIERARCHY,
            value as string | null,
          );
          return {...accState, ...updates};
        },
        state,
      );
    }

    case 'RESET_ALL':
      return initialState;

    default:
      return state;
  }
};

const useMetaPresetFields = (
  metaPresetData: MetaPresetData | null,
  initialPreset: MetaPreset | null,
) => {
  const [state, dispatch] = useReducer(metaPresetReducer, initialState);
  const {_} = useLingui();

  const selectUser = useCallback((userId: string) => {
    dispatch({type: 'SELECT_USER', payload: userId});
  }, []);

  const selectPage = useCallback((pageId: string) => {
    dispatch({type: 'SELECT_PAGE', payload: pageId});
  }, []);

  const selectAdAccount = useCallback((adAccountId: string) => {
    dispatch({type: 'SELECT_AD_ACCOUNT', payload: adAccountId});
  }, []);

  const selectPixel = useCallback((pixelId: string) => {
    dispatch({type: 'SELECT_PIXEL', payload: pixelId});
  }, []);

  const reset = useCallback(() => {
    dispatch({type: 'RESET_ALL'});
  }, []);

  const {
    selectedUserId,
    selectedPageId,
    selectedAdAccountId,
    selectedPixelId,
    data,
    availablePages,
    availableAdAccounts,
    availablePixels,
  } = state;

  const usersOptions: Option[] = useMemo(
    () =>
      data?.users.map(user => ({
        label: [user.firstName, user.lastName].filter(Boolean).join(' '),
        value: user.id as string,
        iconPrimary: user.avatar ?? undefined,
      })) ?? [],
    [data],
  );
  const pagesOptions: Option[] = useMemo(
    () =>
      availablePages.map(page => ({
        label: [
          page.name,
          page.instagramAccount?.username
            ? `@${page.instagramAccount.username}`
            : '',
        ]
          .filter(Boolean)
          .join(', '),
        value: page.id,
        iconPrimary: page.avatar ?? undefined,
        iconSecondary: page.instagramAccount?.avatar ?? undefined,
      })),
    [availablePages],
  );
  const adAccountsOptions: Option[] = useMemo(
    () =>
      availableAdAccounts.map(adAccount => ({
        label: adAccount.name,
        value: adAccount.id,
      })),
    [availableAdAccounts],
  );
  const pixelsOptions: Option[] = useMemo(
    () =>
      availablePixels.map(pixel => ({
        label: pixel.name,
        value: pixel.id,
      })),
    [availablePixels],
  );

  const fields = useMemo(() => {
    return (
      <>
        {usersOptions.length > 1 ? (
          <MetaPresetDropdown
            title={_(msg`Facebook Profile`)}
            value={selectedUserId}
            options={usersOptions}
            onChange={selectUser}
          />
        ) : null}
        <MetaPresetDropdown
          title={_(msg`Facebook Page & Instagram Account`)}
          value={selectedPageId}
          options={pagesOptions}
          onChange={selectPage}
        />
        <MetaPresetDropdown
          title={_(msg`Ad Account`)}
          value={selectedAdAccountId}
          options={adAccountsOptions}
          onChange={selectAdAccount}
        />
        <MetaPresetDropdown
          title={_(msg`Meta Pixel`)}
          value={selectedPixelId}
          options={pixelsOptions}
          onChange={selectPixel}
        />
      </>
    );
  }, [
    _,
    selectedUserId,
    selectedPageId,
    selectedAdAccountId,
    selectedPixelId,
    usersOptions,
    pagesOptions,
    adAccountsOptions,
    pixelsOptions,
    selectUser,
    selectPage,
    selectAdAccount,
    selectPixel,
  ]);

  // Set initial meta preset data
  useEffect(() => {
    if (metaPresetData) {
      dispatch({type: 'SET_DATA', payload: metaPresetData});
    }
  }, [metaPresetData]);

  // Initialize form with values from existing preset
  useEffect(() => {
    if (initialPreset && metaPresetData) {
      dispatch({
        type: 'INIT_VALUES',
        payload: {
          userId: initialPreset.platformUserId,
          pageId: initialPreset.pageId,
          adAccountId: initialPreset.adAccountId,
          pixelId: initialPreset.pixelId || null,
        },
      });
    }
  }, [initialPreset, metaPresetData]);

  const selectedPage = useMemo(
    () => state.availablePages.find(p => p.id === state.selectedPageId),
    [state.availablePages, state.selectedPageId],
  );
  const selectedAdAccount = useMemo(
    () =>
      state.availableAdAccounts.find(a => a.id === state.selectedAdAccountId),
    [state.availableAdAccounts, state.selectedAdAccountId],
  );
  const selectedPixel = useMemo(
    () => state.availablePixels.find(p => p.id === state.selectedPixelId),
    [state.availablePixels, state.selectedPixelId],
  );
  const inputIsValid = useMemo(
    () =>
      Boolean(
        selectedUserId && selectedPage && selectedAdAccount && selectedPixel,
      ),
    [selectedUserId, selectedPage, selectedAdAccount, selectedPixel],
  );
  const inputData = useMemo(
    () =>
      inputIsValid
        ? {
            platformUserId: selectedUserId,
            adAccountId: selectedAdAccount!.id,
            adAccountName: selectedAdAccount!.name,
            adAccountCurrency: selectedAdAccount!.currency,
            adAccountTimezone: selectedAdAccount!.timezone,
            instagramActorId: selectedPage!.instagramAccount?.actorId,
            instagramAvatar: selectedPage!.instagramAccount?.avatar,
            instagramUsername: selectedPage!.instagramAccount?.username,
            pageId: selectedPage!.id,
            pageName: selectedPage!.name,
            pageAvatar: selectedPage!.avatar,
            pixelId: selectedPixel!.id,
            pixelName: selectedPixel!.name,
          }
        : undefined,
    [
      inputIsValid,
      selectedUserId,
      selectedAdAccount,
      selectedPage,
      selectedPixel,
    ],
  );

  return {
    state,
    fields,
    inputData,
    selectUser,
    selectPage,
    selectAdAccount,
    selectPixel,
    reset,
  };
};

export default useMetaPresetFields;
