'use client';

import {useContext} from 'react';
import {
  AppProviderContext,
  AuthContext,
  SearchParamsContext,
  ErrorParamHandlerContext,
} from '@/contexts';
import {AnalyticsContext} from '@/contexts/AnalyticsContext';
import {FlowContext} from '@/contexts/FlowContext';

export const useAppContext = () => {
  const context = useContext(AppProviderContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within an AnalyticsProvider',
    );
  }

  return context;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};

export const useSearchParamsContext = () => {
  const context = useContext(SearchParamsContext);

  if (!context) {
    throw new Error(
      'useSearchParamsContext must be used within a SearchParamsProvider',
    );
  }

  return context;
};

export const useErrorParamHandlerContext = () => {
  const context = useContext(ErrorParamHandlerContext);

  if (!context) {
    throw new Error(
      'useErrorParamHandlerContext must be used within a SearchParamsProvider',
    );
  }

  return context;
};

export const useFlowContext = () => {
  const context = useContext(FlowContext);

  if (!context) {
    throw new Error('useFlowContext must be used within a FlowProvider');
  }

  return context;
};
