import {memo, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import UploadPhoto from './steps/UploadPhoto';
import ConfirmCampaign from './steps/ConfirmCampaign';
import {useFlowContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import Banner from './components/Banner';
import VideogenError from './components/VideogenError';
import {useWakeLock} from 'react-screen-wake-lock';

interface Props {
  showBanner?: boolean;
  className?: string;
}

const GenerateCampaign = memo(({showBanner, className}: Props) => {
  const {runId, run, isGeneratingVideos} = useFlowContext();
  const {isSupported, released, request, release} = useWakeLock();
  const [time, setTime] = useState(0);

  // use this to trigger a wake lock request every 10 seconds, because afret a
  // manual screen on event, the wake lock is released and the first lock event
  // always fails
  useEffect(() => {
    let timeout: any;

    if (isSupported && run && isGeneratingVideos) {
      timeout = setTimeout(() => {
        setTime(Date.now());
      }, 1000 * 10); // 10 seconds
    }

    return () => clearTimeout(timeout);
  }, [time, run, isGeneratingVideos, isSupported]);

  // request/release the wake lock based on the run state
  useEffect(() => {
    if (!runId || !isSupported) {
      return;
    }

    if (run && isGeneratingVideos) {
      if (released !== false) {
        request();
      }
    } else {
      if (released === false) {
        release();
      }
    }
  }, [time]);

  // release the wake lock when the component is unmounted
  useEffect(() => {
    return () => {
      released === false && release();
    };
  }, []);

  if (runId && !run) {
    return <PortalSpinner />;
  }

  return (
    <div className={twMerge('flex w-full flex-col gap-4', className)}>
      <VideogenError />
      <Banner isShown={!runId && showBanner} />
      <UploadPhoto />
      <ConfirmCampaign />
    </div>
  );
});

GenerateCampaign.displayName = 'GenerateCampaign';

export default GenerateCampaign;
