import {memo, useEffect, useRef, useState} from 'react';
import useRenderAnimatedBadgeIcon, {
  IconType,
} from './useRenderAnimatedBadgeIcon';

const AnimatedBadge = memo(
  ({
    iconType,
    text,
    backgroundColor,
    delay = 0,
  }: {
    iconType: IconType;
    text: string;
    backgroundColor: string;
    delay?: number; // Delay in seconds
  }) => {
    const renderIconContent = useRenderAnimatedBadgeIcon(iconType);
    const uniqueId = useRef(`badge-${Math.random().toString(36).slice(2)}`);
    const gradientId = useRef(`gradient-${uniqueId.current}`);
    const textRef = useRef<SVGTextElement>(null);
    const height = 28;
    const iconSize = 16;
    const iconPadding = 8;
    const iconSpace = iconSize + iconPadding;
    const padding = 12;
    const duration = 2.5;
    const [dimensions, setDimensions] = useState({
      width: 0,
      height: height,
    });

    useEffect(() => {
      if (textRef.current) {
        const bbox = textRef.current.getBBox();
        setDimensions({
          width: bbox.width + iconSpace + padding * 2,
          height: height,
        });
      }
    }, [text]);

    const gradientWidth = dimensions.width * 2;

    return (
      <div className="inline-flex">
        <svg
          width={dimensions.width}
          height={dimensions.height}
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          className="overflow-visible">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            rx={height / 2}
            fill={backgroundColor}
          />

          {/* Gradient definition */}
          <defs>
            <linearGradient
              id={gradientId.current}
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1="0"
              x2={gradientWidth}
              y2="0">
              <stop offset="0%" stopColor="#808080" stopOpacity="1" />
              <stop offset="50%" stopColor="#CBCBCB" stopOpacity="1" />
              <stop offset="100%" stopColor="#808080" stopOpacity="1" />
              {/* Initial position setter */}
              <animate
                attributeName="x1"
                from={-gradientWidth}
                to={-gradientWidth}
                dur="0.001s"
                begin={`0s; ${uniqueId.current}-animation1.end`}
                id={`${uniqueId.current}-init1`}
                fill="freeze"
              />
              <animate
                attributeName="x2"
                from="0"
                to="0"
                dur="0.001s"
                begin={`0s; ${uniqueId.current}-animation2.end`}
                id={`${uniqueId.current}-init2`}
                fill="freeze"
              />

              {/* Main animation */}
              <animate
                id={`${uniqueId.current}-animation1`}
                attributeName="x1"
                values={`${-gradientWidth}; ${dimensions.width}; ${dimensions.width}; ${-gradientWidth}`}
                dur={`${duration}s`}
                keyTimes="0; 0.6; 1; 1"
                begin={`${uniqueId.current}-init1.end + ${delay}s`}
                repeatCount="indefinite"
                fill="freeze"
              />
              <animate
                id={`${uniqueId.current}-animation2`}
                attributeName="x2"
                values={`0; ${dimensions.width + gradientWidth}; ${dimensions.width + gradientWidth}; 0`}
                dur={`${duration}s`}
                keyTimes="0; 0.6; 1; 1"
                begin={`${uniqueId.current}-init2.end + ${delay}s`}
                repeatCount="indefinite"
                fill="freeze"
              />
            </linearGradient>
          </defs>
          <g
            style={{
              transform: `translateX(${padding}px) translateY(5px)`,
              mixBlendMode: 'color-dodge',
            }}
            fill={`url(#${gradientId.current})`}>
            {renderIconContent(gradientId.current)}
          </g>
          <text
            ref={textRef}
            x={iconSpace + padding}
            y="50%"
            dominantBaseline="middle"
            className="translate-y-[1px] text-sm"
            fill={`url(#${gradientId.current})`}
            style={{mixBlendMode: 'color-dodge'}}>
            {text}
          </text>
        </svg>
      </div>
    );
  },
);

AnimatedBadge.displayName = 'AnimatedBadge';

export default AnimatedBadge;
