'use client';

import {CSSProperties, memo} from 'react';
import {Link as RouterLink, To} from 'react-router-dom';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import {msg, Trans} from '@lingui/macro';
import {Button} from '@nextui-org/button';
import {twMerge} from 'tailwind-merge';
import {useLingui} from '@lingui/react';

const BackButton = memo(
  ({
    to = -1,
    className,
    style,
    isIconOnly,
  }: {
    to?: To | number;
    className?: string;
    isIconOnly?: boolean;
    style?: CSSProperties;
  }) => {
    const {_} = useLingui();

    return (
      <Button
        style={style}
        aria-label={_(msg`Back`)}
        data-amp-track-label={`Back`}
        as={RouterLink}
        // @ts-expect-error - typing issue
        to={to}
        disableAnimation
        disableRipple
        className={twMerge(
          'h-auto min-h-0 w-auto min-w-0 gap-0 rounded-none bg-transparent p-0 text-medium font-medium text-primary',
          className,
        )}
        startContent={
          isIconOnly ? null : <ChevronLeftIcon className="h-7 w-7 shrink-0" />
        }>
        {isIconOnly ? (
          <ChevronLeftIcon className="h-6 w-6" />
        ) : (
          <Trans>Back</Trans>
        )}
      </Button>
    );
  },
);

BackButton.displayName = 'BackButton';

export default BackButton;
