import CoinsStacked03SolidIcon from '@/components/icons/CoinsStacked03SolidIcon';
import CurrencyDollarIcon from '@/components/icons/CurrencyDollarIcon';
import EyeSolidIcon from '@/components/icons/EyeSolidIcon';
import ShoppingCart03SolidIcon from '@/components/icons/ShoppingCart03SolidIcon';
import UserPlus02SolidIcon from '@/components/icons/UserPlus02SolidIcon';
import {Trans} from '@lingui/macro';
import {memo} from 'react';

const chipClassName =
  'rounded-full bg-background/10 flex gap-2 [&>svg]:h-4 [&>svg]:w-4 text-xs font-normal text-foreground/20 min-h-6 px-3 flex items-center whitespace-nowrap';

export const CountdownWidget = memo(
  ({min, s, ms}: {min: number; s: number; ms: number}) => {
    return (
      <div className="flex w-full flex-col items-center gap-6 rounded-2xl bg-foreground/15 py-4 backdrop-blur">
        <div className="flex w-full flex-col items-center gap-2 overflow-hidden">
          <div className="flex w-fit gap-2">
            <div className={chipClassName}>
              <CoinsStacked03SolidIcon />
              <Trans>Gross Profit</Trans>
            </div>
            <div className={chipClassName}>
              <ShoppingCart03SolidIcon />
              <Trans># of Purchases</Trans>
            </div>
            <div className={chipClassName}>
              <CoinsStacked03SolidIcon />
              <Trans>Gross Profit</Trans>
            </div>
          </div>
          <div className="flex gap-2">
            <div className={chipClassName}>
              <EyeSolidIcon />
              <Trans>Impressions</Trans>
            </div>
            <div className={chipClassName}>
              <UserPlus02SolidIcon />
              <Trans>Customer Acq. Cost</Trans>
            </div>
            <div className={chipClassName}>
              <EyeSolidIcon />
              <Trans>Impressions</Trans>
            </div>
          </div>
          <div className="flex w-fit gap-2">
            <div className={chipClassName}>
              <ShoppingCart03SolidIcon />
              <Trans># of Purchases</Trans>
            </div>
            <div className={chipClassName}>
              <CurrencyDollarIcon />
              <Trans>Total Sales</Trans>
            </div>
            <div className={chipClassName}>
              <CoinsStacked03SolidIcon />
              <Trans>Gross Profit</Trans>
            </div>
          </div>
        </div>
        <div className="flex min-h-24 flex-col justify-center px-4">
          <div className="flex w-full flex-col items-center gap-2">
            <h3 className="text-sm text-foreground/50">
              <Trans>Performance ready in:</Trans>
            </h3>
            <div className="text-sm font-medium tabular-nums">
              <Trans>
                ⏳ {min} min : {String(s).padStart(2, '0')} s :{' '}
                {String(ms).padStart(3, '0')} ms
              </Trans>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

CountdownWidget.displayName = 'CountdownWidget';
