import {memo, useCallback, useEffect, useRef, useState} from 'react';
import StepContainer from '../components/StepContainer';
import {Button} from '@nextui-org/button';
import {Link} from '@nextui-org/link';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
  useAppContext,
  useAuthContext,
  useFlowContext,
  useSearchParamsContext,
} from '@/hooks';
import Camera01SolidIcon from '@/components/icons/Camera01SolidIcon';
import {msg, Trans} from '@lingui/macro';
import {Textarea} from '@nextui-org/input';
import {useLingui} from '@lingui/react';
import CreateAssetsSVG from '@/components/icons/flow/createAssets.svg';
import AssetsUploadedSVG from '@/components/icons/flow/assetsUploaded.svg';
import Trash04SolidIcon from '@/components/icons/Trash04SolidIcon';
import {twJoin, twMerge} from 'tailwind-merge';
import MetaAdsSVG from '@/assets/publishOptions/meta-ads.svg';
import TikTokAdsSVG from '@/assets/publishOptions/tiktok-ads.svg';
import {Modal, ModalBody, ModalContent, ModalHeader} from '@/components/Modal';
import {useMutation, useQuery} from '@apollo/client';
import {CLEAR_FLOW_RUN, CONNECT_META_ADS, META_LOGIN} from '@/common/mutations';
import {setParamsAfterLogin} from '@/utils';
import {useAnalytics} from '@/common/analytics';
import {GET_FLOW_RUN, GET_INTEGRATIONS} from '@/common/queries';
import {FileUpload, IntegrationType} from '@/common/types';
import {gql} from '@/__generated__';
import {
  FlowPrompt,
  FlowRunErrorStep,
  FlowRunStatus,
} from '@/__generated__/graphql';
import PortalSpinner from '@/components/PortalSpinner';
import {LEGACY_AUTH_ENABLED} from '@/config';
import ErrorBanner from '../components/ErrorBanner';
import TrackButton from '@/components/buttons/TrackButton';
import {useAwsTranscribe} from '../../../hooks/useAwsTranscribe';
import Microphone01SolidIcon from '@/components/icons/Microphone01SolidIcon';
import StopSolidIcon from '@/components/icons/StopSolidIcon';
import RefreshCcw04Icon from '@/components/icons/RefreshCcw04Icon';
import ErrorMessage from '@/components/ErrorMessage';

const GET_FLOW_PROMPTS = gql(`
  query GetFlowPrompts {
    getFlowPrompts {
      icon
      title
      category
      prompt
    }
  }
`);

const IMPROVE_PROMPT_WITH_AI = gql(`
  mutation ImprovePromptWithAi($input: ImprovePromptInput!) {
    improvedPrompt: improvePromptWithAi(input: $input)
  }
`);

const LS_PROMOTE_ON_META = 'promoteOnMeta';

const setPromoteOnMetaFilename = (img?: FileUpload) => {
  if (!img?.file.name) {
    return;
  }

  localStorage.setItem(LS_PROMOTE_ON_META, img?.file.name);
};
const getPromoteOnMetaFilename = () => localStorage.getItem(LS_PROMOTE_ON_META);
const removePromoteOnMetaFilename = () => {
  localStorage.removeItem(LS_PROMOTE_ON_META);
};

const UploadPhoto = memo(() => {
  const customPromptCategoryRef = useRef<HTMLButtonElement>(null);

  const scrollToCustomPromptCategory = () => {
    if (customPromptCategoryRef.current) {
      customPromptCategoryRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const [improvedPromptCooldown, setImprovedPromptCooldown] = useState(0);
  const isImprovedPromptCooldownRunning = improvedPromptCooldown > 0;
  const [improvePromptWithAi, improvePromptWithAiResult] = useMutation(
    IMPROVE_PROMPT_WITH_AI,
    {
      context: {
        headers: {
          'apollo-require-preflight': 'true',
        },
      },
      onCompleted: data => {
        setPromptCategory('custom');
        setPromptCustomText(data.improvedPrompt);
        scrollToCustomPromptCategory();
      },
      onError: e => {
        const extensions = e.graphQLErrors[0]?.extensions;

        if (extensions?.code === 'TOO_MANY_REQUESTS' && extensions?.cooldown) {
          setImprovedPromptCooldown(extensions.cooldown as number);
        }
      },
    },
  );

  useEffect(() => {
    let interval: any;

    if (isImprovedPromptCooldownRunning) {
      interval = setInterval(() => {
        setImprovedPromptCooldown(prev => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isImprovedPromptCooldownRunning]);

  const {transcription, isTranscribing, startTranscription, stopTranscription} =
    useAwsTranscribe();
  const {_} = useLingui();
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();
  const navigate = useNavigate();
  const {
    runId,
    selectedImage,
    promptCategory,
    promptCustomText,
    promptText,
    uploadSourceLoading,
    runFlowLoading,
    run,
    errorStep,
    setCurrentStep,
    setSelectedImage,
    setPromptCategory,
    setPromptCustomText,
    setPromptText,
    runFlow,
  } = useFlowContext();
  const flowPrompsData = useQuery(GET_FLOW_PROMPTS);
  const [prompts, setPrompts] = useState<FlowPrompt[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const isCustomPrompt = promptCategory === 'custom';
  const prompt = isCustomPrompt ? promptCustomText : promptText;
  const onCompleted = useCallback((data: {href: string}) => {
    if (data.href) {
      window.location.href = data.href;
    }
  }, []);
  const {searchParams} = useSearchParamsContext();
  const {reportMetaAuthStart, reportIntegrationStart} = useAnalytics();
  const [metaLoginMutation, {loading: metaAuthLoading}] = useMutation(
    META_LOGIN,
    {
      onCompleted,
    },
  );
  const [connectMetaAds, {loading: metaConnectionLoading}] = useMutation(
    CONNECT_META_ADS,
    {
      onCompleted,
    },
  );
  const [clearFlowRun, {loading: clearFlowRunLoading}] = useMutation(
    CLEAR_FLOW_RUN,
    {
      update(cache, {data}) {
        if (!data) {
          return;
        }

        cache.writeQuery({
          query: GET_FLOW_RUN,
          variables: {id: data.clearFlowRun.id},
          data: {getFlowRun: data.clearFlowRun},
        });
      },
      onCompleted: data => {
        if (data.clearFlowRun) {
          setIsEditModalOpen(false);
          setCurrentStep('upload_photo');
        }
      },
    },
  );
  const {data: metaIntegrationsData, loading: metaIntegrationsLoading} =
    useQuery(GET_INTEGRATIONS, {
      variables: {
        filter: {
          userId: user?.id,
          type: IntegrationType.metaAds,
        },
        order: {type: 'ASC'},
        first: 1,
      },
      skip: !user?.id,
      fetchPolicy: 'cache-and-network',
    });
  const isMetaConnected = Boolean(
    metaIntegrationsData?.connection.edges?.length,
  );
  const [canSelectFiles, setCanSelectFiles] = useState(false);
  const [isComingSoonShown, setIsComingSoonShown] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const itemTitleStyle = 'text-sm font-medium';

  // Handler for file selection
  const handleFileSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];

      if (!file) {
        return;
      }

      // Create a URL for the selected image
      const imageURL = URL.createObjectURL(file);

      setSelectedImage({
        url: imageURL,
        file,
      });
    },
    [setSelectedImage],
  );

  // Functions to trigger the file input with appropriate attributes
  const handleSelectFromFiles = useCallback(() => {
    if (fileInputRef.current) {
      // Reset the input value to allow re-selecting the same file
      fileInputRef.current.value = '';
      fileInputRef.current.accept = 'image/*'; // Accept only images
      fileInputRef.current.removeAttribute('capture'); // Remove capture attribute
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const resetSelectedImage = useCallback(() => {
    setSelectedImage(undefined);
  }, [setSelectedImage]);

  const onTikTokPress = useCallback(() => {
    setIsComingSoonShown(true);

    setTimeout(() => {
      setIsComingSoonShown(false);
    }, 4000);
  }, []);

  const onClearRun = useCallback(async () => {
    if (!runId || clearFlowRunLoading) {
      return;
    }

    await clearFlowRun({
      variables: {
        id: runId,
      },
    });
  }, [runId, clearFlowRunLoading, clearFlowRun]);

  const onEdit = useCallback(() => {
    if (!run) {
      return;
    }

    if (run?.status === FlowRunStatus.Finished) {
      setIsEditModalOpen(true);
    } else {
      onClearRun();
    }
  }, [run, onClearRun]);

  const onEditCancel = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  useEffect(() => {
    const prompts = flowPrompsData.data?.getFlowPrompts ?? [];

    if (!prompts.length) {
      return;
    }

    setPrompts(prompts);
  }, [flowPrompsData.data]);

  useEffect(() => {
    if (!prompts.length || promptCategory) {
      return;
    }

    const defaultPrompt = prompts[0];

    setPromptCategory(defaultPrompt.category);

    if (defaultPrompt.category === 'custom') {
      setPromptCustomText(defaultPrompt.prompt);
    } else {
      setPromptText(defaultPrompt.prompt);
    }
  }, [prompts, promptCategory, promptText, promptCustomText]);

  useEffect(() => {
    const canSelectFiles = Boolean(
      window.File && window.FileReader && window.FileList && window.Blob,
    );

    setCanSelectFiles(canSelectFiles);
  }, []);

  useEffect(() => {
    if (
      selectedImage &&
      promptCategory &&
      prompt &&
      isAuthenticated &&
      isMetaConnected &&
      !(runFlowLoading || uploadSourceLoading) &&
      getPromoteOnMetaFilename() === selectedImage.file.name
    ) {
      removePromoteOnMetaFilename();
      runFlow();
    }
  }, [
    selectedImage,
    promptCategory,
    prompt,
    isAuthenticated,
    isMetaConnected,
    runFlowLoading,
    uploadSourceLoading,
  ]);

  const onCustomPromptChange = useCallback(
    (value: string) => {
      if (!isCustomPrompt) {
        setPromptCategory('custom');
      }

      setPromptCustomText(value);
    },
    [isCustomPrompt, setPromptCategory, setPromptCustomText],
  );

  useEffect(() => {
    if (transcription) {
      setPromptCustomText(transcription);
    }
  }, [transcription]);

  return (
    <>
      <Modal
        size="xs"
        radius="none"
        className="h-32 rounded-3xl bg-background/75 backdrop-blur-xl"
        placement="center"
        hideCloseButton
        isOpen={isComingSoonShown}
        onOpenChange={() => setIsComingSoonShown(false)}>
        <ModalContent className="min-w-64">
          <ModalBody className="flex-col items-center justify-center gap-0 p-0 text-default-500">
            <h3 className="text-xl font-semibold">Coming Soon!</h3>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isEditModalOpen}
        onOpenChange={onEditCancel}
        classNames={{
          header: 'p-4',
          body: 'p-4',
          closeButton: ' top-4 right-4',
        }}>
        <ModalContent>
          <ModalHeader>
            <div className="flex w-full text-base font-medium">
              <Trans>Edit Assets?</Trans>
            </div>
          </ModalHeader>
          <ModalBody className="flex-col items-center justify-center gap-8">
            <div className="text-sm text-foreground/60">
              <Trans>
                Editing the product image or video prompt will delete the
                generated videos. Are you sure you want to continue?
              </Trans>
            </div>
            <div className="flex w-full flex-col gap-3">
              <Button
                aria-label={_(msg`Edit assets`)}
                data-amp-track-label="Edit assets"
                className="w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
                onPress={onClearRun}>
                <Trans>Edit</Trans>
              </Button>
              <Button
                aria-label={_(msg`Cancel edit assets`)}
                data-amp-track-label="Cancel edit assets"
                className="w-full shrink-0 bg-foreground/10 px-4 py-2 text-sm font-medium text-foreground"
                onPress={onEditCancel}>
                <Trans>Cancel</Trans>
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <StepContainer
        step="upload_photo"
        isCompleted={Boolean(run && run.status !== FlowRunStatus.Initial)}
        isEditable={Boolean(
          run && run.status !== FlowRunStatus.GeneratingVideos,
        )}
        onEdit={onEdit}
        title={_(msg`Create Assets`)}
        titleCompleted={_(msg`Assets Uploaded`)}
        icon={
          <img className="h-7 w-7" src={CreateAssetsSVG} alt="Create Assets" />
        }
        iconCompleted={
          <img
            className="h-7 w-7"
            src={AssetsUploadedSVG}
            alt="Assets Uploaded"
          />
        }
        subtitle={_(msg`~1 minute`)}>
        <div className="flex w-full flex-col gap-8">
          {/* Hidden file input */}
          <input
            aria-label="Image input"
            data-amp-track-label="Image input"
            type="file"
            ref={fileInputRef}
            style={{display: 'none'}}
            onChange={handleFileSelection}
          />
          <div className="flex flex-col gap-3">
            <div className={itemTitleStyle}>
              <Trans>Product Image</Trans>
            </div>
            {selectedImage ? (
              <div className="relative flex w-full items-center gap-4">
                <img
                  src={selectedImage.url}
                  alt="Selected"
                  className="w-full rounded-lg object-contain"
                />
                <div className="absolute bottom-3 left-3 right-3 flex justify-center gap-1">
                  <Button
                    aria-label={_(msg`Delete`)}
                    data-amp-track-label="Delete"
                    disableAnimation
                    disableRipple
                    onClick={resetSelectedImage}
                    startContent={<Trash04SolidIcon className="h-4 w-4" />}
                    className="flex h-auto max-h-none w-auto gap-1 self-start rounded-full bg-[#262626] px-3 py-1 text-foreground bg-blend-difference">
                    <Trans>Delete</Trans>
                  </Button>
                  <Button
                    aria-label={_(msg`Replace Photo`)}
                    data-amp-track-label="Replace Photo"
                    disableAnimation
                    disableRipple
                    onClick={handleSelectFromFiles}
                    startContent={<Camera01SolidIcon className="h-4 w-4" />}
                    className="flex h-auto max-h-none w-auto gap-1 self-start rounded-full bg-[#262626] px-3 py-1 text-foreground bg-blend-difference">
                    <Trans>Replace Photo</Trans>
                  </Button>
                </div>
              </div>
            ) : canSelectFiles ? (
              <Button
                aria-label={_(msg`Upload Image`)}
                data-amp-track-label="Upload Image"
                color="primary"
                variant="solid"
                disableAnimation
                disableRipple
                onClick={handleSelectFromFiles}
                startContent={<Camera01SolidIcon className="h-4 w-4" />}
                className="h-16 w-full gap-2 border border-dashed border-primary/50 bg-primary/10 text-sm font-medium text-primary">
                <Trans>Add</Trans>
              </Button>
            ) : null}
          </div>
          <div className="flex flex-col gap-3">
            <div className={itemTitleStyle}>
              <Trans>Media Prompt</Trans>
            </div>
            <div className="-mx-4 -mb-2 flex gap-2 overflow-x-auto px-4 pb-2">
              {prompts.length ? (
                prompts.map(({icon, title, prompt, category}) => (
                  <Button
                    ref={category === 'custom' ? customPromptCategoryRef : null}
                    key={title}
                    aria-label={_(msg`Prompt Category`)}
                    data-amp-track-label="Prompt Category"
                    color="primary"
                    variant="solid"
                    disableAnimation
                    disableRipple
                    onClick={() => {
                      setPromptCategory(category);

                      if (isTranscribing) {
                        stopTranscription();
                      }

                      if (category === 'custom') {
                        setPromptText(promptCustomText);
                      } else {
                        setPromptText(prompt);
                      }
                    }}
                    className={twMerge(
                      'flex h-16 w-36 shrink-0 flex-col gap-0 border bg-primary/10 p-2 text-sm font-medium text-primary',
                      category === promptCategory
                        ? 'border-primary/50'
                        : 'border-transparent',
                    )}>
                    <div className="text-xl">{icon}</div>
                    <div
                      className={twJoin(
                        'w-full overflow-hidden overflow-ellipsis',
                        category === promptCategory
                          ? 'text-foreground'
                          : 'text-foreground/50',
                      )}>
                      {title}
                    </div>
                  </Button>
                ))
              ) : (
                <div className="flex h-16 w-full flex-col items-center justify-center">
                  <PortalSpinner />
                </div>
              )}
            </div>
            <Textarea
              aria-label={_(msg`Custom Prompt`)}
              data-amp-track-label="Custom Prompt"
              classNames={{
                base: `pt-5 relative z-10`,
                inputWrapper: twJoin(
                  `after:z-[-1] after:animate-animated-gradient after:bg-[linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)] after:transition-opacity after:duration-500 after:rounded-[0.625rem] animated-gradient-border bg-[#3b3b3b] relative w-full !shadow-none !opacity-100 p-3 rounded-lg data-[hover=true]:bg-[#3b3b3b] group-data-[focus=true]:bg-[#3b3b3b]`,
                  isTranscribing ? 'after:opacity-100' : 'after:opacity-0',
                ),
                input: '!text-foreground',
              }}
              placeholder={_(
                msg`e.g. In the background, a dilapidated, gothic-style haunted house with dimly lit windows`,
              )}
              value={isCustomPrompt ? promptCustomText : promptText}
              minRows={3}
              maxRows={6}
              onValueChange={onCustomPromptChange}
              endContent={
                isCustomPrompt ? (
                  isTranscribing ? (
                    <TrackButton
                      aria-label={_(msg`Stop Transcription`)}
                      data-amp-track-label="Stop Transcription"
                      disableAnimation
                      disableRipple
                      className="h-6 w-6 min-w-6 self-end bg-[#E34800] p-0"
                      radius="full"
                      isIconOnly
                      onClick={stopTranscription}>
                      <StopSolidIcon className="h-3 w-3 text-foreground" />
                    </TrackButton>
                  ) : (
                    <TrackButton
                      aria-label={_(msg`Start Transcription`)}
                      data-amp-track-label="Start Transcription"
                      disableAnimation
                      disableRipple
                      className="h-6 w-6 min-w-6 self-end p-0"
                      radius="full"
                      isIconOnly
                      onClick={() => {
                        setPromptCustomText('');
                        startTranscription();
                      }}>
                      <Microphone01SolidIcon className="h-5 w-5 text-foreground" />
                    </TrackButton>
                  )
                ) : null
              }
            />
            {selectedImage && (promptCustomText || promptText) ? (
              <>
                <TrackButton
                  aria-label={_(msg`Improve with AI`)}
                  data-amp-track-label="Improve with AI"
                  disableRipple
                  className="pointer-events-auto h-fit w-fit gap-1 bg-transparent p-0 text-foreground/50"
                  isDisabled={
                    isImprovedPromptCooldownRunning ||
                    improvePromptWithAiResult.loading
                  }
                  onPress={() => {
                    improvePromptWithAi({
                      variables: {
                        input: {
                          file: selectedImage.file,
                          prompt: promptCustomText || promptText || '',
                        },
                      },
                    });
                  }}
                  startContent={
                    <RefreshCcw04Icon
                      className={twJoin(
                        'h-[0.875rem] w-[0.875rem] shrink-0',
                        improvePromptWithAiResult.loading ? 'animate-spin' : '',
                      )}
                    />
                  }>
                  <Trans>Improve with AI</Trans>
                </TrackButton>
                <ErrorMessage
                  className="text-warning"
                  message={
                    isImprovedPromptCooldownRunning && (
                      <Trans>
                        Regeneration limit exceeded. Please try again in{' '}
                        {improvedPromptCooldown} seconds
                      </Trans>
                    )
                  }
                />
              </>
            ) : null}
          </div>
          <ErrorBanner
            isShown={errorStep === FlowRunErrorStep.InitializingGeneration}
            title={_(msg`Assets Generation Failed`)}
            message={_(
              msg`Unable to start asset generation. Please try again later.`,
            )}
          />
          <div className="flex flex-col gap-3">
            <div className={itemTitleStyle}>
              <Trans>Promote on</Trans>
            </div>
            <TrackButton
              aria-label={_(msg`Continue with Meta Ads`)}
              data-amp-track-label="Continue with Meta Ads"
              color="primary"
              variant="solid"
              className="text-sm font-medium text-foreground"
              isDisabled={!selectedImage || !promptCategory || !prompt}
              spinner={null}
              isLoading={
                runFlowLoading ||
                uploadSourceLoading ||
                metaAuthLoading ||
                metaConnectionLoading ||
                metaIntegrationsLoading
              }
              onPress={() => {
                if (!isAuthenticated) {
                  setPromoteOnMetaFilename(selectedImage);
                  setParamsAfterLogin(searchParams);
                  if (LEGACY_AUTH_ENABLED) {
                    navigate('/create/auth/login');
                  } else {
                    reportMetaAuthStart().finally(() => {
                      metaLoginMutation();
                    });
                  }
                  return;
                }

                if (!isMetaConnected) {
                  setPromoteOnMetaFilename(selectedImage);
                  reportIntegrationStart(IntegrationType.metaAds).finally(
                    () => {
                      connectMetaAds({
                        variables: {
                          input: {
                            callbackPath:
                              location.pathname.slice(1) + location.search,
                          },
                        },
                      });
                    },
                  );
                  return;
                }

                runFlow();
              }}>
              <img src={MetaAdsSVG} alt="Meta Ads" />
              <Trans>Meta Ads</Trans>
            </TrackButton>
            <Button
              aria-label={_(msg`Continue with TikTok Ads`)}
              data-amp-track-label="Continue with TikTok Ads"
              variant="solid"
              className="bg-foreground text-sm font-medium text-background"
              isDisabled={!selectedImage || !promptCategory || !prompt}
              onPress={onTikTokPress}>
              <img src={TikTokAdsSVG} alt="TikTok Ads" />
              <Trans>TikTok</Trans>
            </Button>
          </div>
          <div className={`text-center text-xs leading-4 text-default-400`}>
            <p>
              By Continuing with Meta or TikTok, you agree to the{' '}
              <Link
                aria-label={_(msg`Terms of Service`)}
                data-amp-track-label={`Terms of Service`}
                as={RouterLink}
                className="text-xs text-default-400 underline"
                to="/legal/terms">
                Terms of Service
              </Link>{' '}
              and confirm that any uploaded images are owned by you. Learn more
              about our data practices in our{' '}
              <Link
                aria-label={_(msg`Privacy Policy`)}
                data-amp-track-label={`Privacy Policy`}
                as={RouterLink}
                className="text-xs text-default-400 underline"
                to="/legal/privacy">
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </StepContainer>
    </>
  );
});

UploadPhoto.displayName = 'UploadPhoto';

export default UploadPhoto;
