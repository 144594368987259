'use client';

import {SVGProps} from 'react';

export default function ShoppingCart03SolidIcon(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.00001H3.06055L3.95644 17.3343C3.9634 17.4465 3.97181 17.5817 3.98881 17.7009C4.00866 17.84 4.04944 18.0358 4.16424 18.2387C4.31394 18.5033 4.5405 18.7161 4.81388 18.849C5.02353 18.951 5.22158 18.9794 5.36164 18.9906C5.4816 19.0001 5.61716 19.0001 5.72952 19L19 19C19.5523 19 20 18.5523 20 18C20 17.4477 19.5523 17 19 17H5.93945L5.81445 15H18.1718C18.6475 15 19.0605 15 19.4032 14.9734C19.7669 14.9451 20.128 14.8828 20.4824 14.7168C21.0151 14.4673 21.4627 14.0666 21.7695 13.5647C21.9736 13.2308 22.0755 12.8788 22.1438 12.5205C22.2081 12.1828 22.2537 11.7725 22.3062 11.2998L22.8979 5.97388C22.9126 5.84262 22.9297 5.68881 22.9332 5.55331C22.9372 5.39876 22.9285 5.16991 22.8265 4.92449C22.6963 4.61098 22.4635 4.35093 22.1663 4.18684C21.9337 4.0584 21.7072 4.02452 21.5531 4.0114C21.418 3.9999 21.2633 3.99996 21.1313 4.00001L5.12695 4.00001L5.04356 2.66572C5.0366 2.55358 5.02819 2.41828 5.01119 2.29916C4.99134 2.16006 4.95056 1.96417 4.83576 1.7613C4.68606 1.49673 4.4595 1.2839 4.18612 1.15099C3.97647 1.04907 3.77842 1.02059 3.63836 1.00945C3.51841 0.99991 3.38286 0.999962 3.27051 1.00001L2 1.00001C1.44772 1.00001 1 1.44773 1 2.00001C1 2.5523 1.44772 3.00001 2 3.00001Z"
        fill="currentColor"
      />
      <path
        d="M7.5 20C6.67157 20 6 20.6716 6 21.5C6 22.3284 6.67157 23 7.5 23C8.32843 23 9 22.3284 9 21.5C9 20.6716 8.32843 20 7.5 20Z"
        fill="currentColor"
      />
      <path
        d="M16.5 20C15.6716 20 15 20.6716 15 21.5C15 22.3284 15.6716 23 16.5 23C17.3284 23 18 22.3284 18 21.5C18 20.6716 17.3284 20 16.5 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
