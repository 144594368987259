import {msg, Trans} from '@lingui/macro';
import {memo} from 'react';
import {useLingui} from '@lingui/react';
import AnimatedBadge from './AnimatedBadge';

const SuccessMeasured = memo(() => {
  const {_} = useLingui();
  const delay = 0.9;

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="text-sm text-foreground/50">
        <Trans>Success Will Be Measured</Trans>
      </div>
      <div className="flex flex-wrap gap-2">
        <AnimatedBadge
          iconType="grossProfit"
          text={_(msg`Gross Profit`)}
          backgroundColor="#00660E"
          delay={delay}
        />
        <AnimatedBadge
          iconType="impressions"
          text={_(msg`Impressions`)}
          backgroundColor="#004766"
          delay={delay * 2}
        />
        <AnimatedBadge
          iconType="customerAcquisitionCost"
          text={_(msg`Customer Acquisition Cost`)}
          backgroundColor="#3F0066"
          delay={delay * 3}
        />
        <AnimatedBadge
          iconType="numberOfPurchases"
          text={_(msg`Number of Purchases`)}
          backgroundColor="#005F66"
          delay={delay * 4}
        />
        <AnimatedBadge
          iconType="totalSales"
          text={_(msg`Total Sales`)}
          backgroundColor="#664600"
          delay={delay * 5}
        />
      </div>
    </div>
  );
});

SuccessMeasured.displayName = 'SuccessMeasured';

export default SuccessMeasured;
