import {memo, useEffect, useMemo} from 'react';
import {Button} from '@nextui-org/react';
import {MetaPresetSetting as MetaPresetSettingType} from '@/common/types/flowSettings';
import FlowSettingLabel from './Label';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import {useQuery} from '@apollo/client';
import {
  COUNT_PLATFORM_USERS,
  GET_META_PRESET_CONNECTION,
  GET_META_PRESETS_DATA,
} from '@/common/queries';
import useMetaPresetFields from '@/hooks/useMetaPresetFields';
import MetaPresetItem from '@/components/MetaPresetItem';
import PortalSpinner from '@/components/PortalSpinner';
import {Link as RouterLink} from 'react-router-dom';
import {FlowPlatform} from '@/__generated__/graphql';
import {useAuthContext, useFlowContext} from '@/hooks';

type Props = {
  setting: MetaPresetSettingType;
  onChange: (id: string, value: string) => void;
};

const MetaPresetSetting = memo(({setting}: Props) => {
  const {_} = useLingui();
  const {user} = useAuthContext();
  const {setMetaPresetToCreate, updateSettingValue} = useFlowContext();
  const metaPresetDataQuery = useQuery(GET_META_PRESETS_DATA, {
    skip: Boolean(setting.value),
  });
  const getMetaPresetConnectionQuery = useQuery(GET_META_PRESET_CONNECTION, {
    variables: {
      filter: setting.value
        ? {
            id: setting.value,
          }
        : {
            isDefault: true,
          },
      first: 1,
    },
  });
  const platformUsersQuery = useQuery(COUNT_PLATFORM_USERS, {
    variables: {
      filter: {
        platform: FlowPlatform.Meta,
        userId: user?.id,
      },
    },
    skip: !user?.id,
  });
  const metaPreset = useMemo(
    () => getMetaPresetConnectionQuery.data?.connection?.edges[0]?.node || null,
    [getMetaPresetConnectionQuery.data],
  );
  const {state, fields, inputData} = useMetaPresetFields(
    metaPresetDataQuery.data?.getMetaPresetData ?? null,
    metaPreset,
  );
  const showUser = useMemo(
    () => (platformUsersQuery.data?.countPlatformUsers ?? 0) > 1,
    [platformUsersQuery.data],
  );

  useEffect(() => {
    if (setting.value) {
      return;
    }

    if (metaPreset) {
      // update setting value if metaPreset exists
      updateSettingValue(setting.id, metaPreset.id);
      setMetaPresetToCreate(undefined);
    } else if (inputData) {
      // set metaPreset to create
      setMetaPresetToCreate(inputData);
    }
  }, [
    setting,
    inputData,
    metaPreset,
    setMetaPresetToCreate,
    updateSettingValue,
  ]);

  if (metaPreset) {
    return (
      <div className="flex w-full flex-col gap-3">
        <div className="flex items-center justify-between gap-2">
          <FlowSettingLabel className="flex grow">
            {setting.label}
          </FlowSettingLabel>
          <Button
            aria-label={_(msg`Change preset`)}
            data-amp-track-label="Change preset"
            as={RouterLink}
            to={`meta-presets`}
            disableAnimation
            disableRipple
            className="min-h-auto h-auto w-auto min-w-0 shrink-0 bg-transparent p-0 text-sm text-foreground/50">
            <Trans>Change</Trans>
          </Button>
        </div>
        <div className="flex w-full flex-col gap-4">
          {metaPreset ? (
            <div
              key={metaPreset.id}
              className="flex w-full flex-col gap-4 rounded-2xl bg-foreground/[0.07] p-4">
              <div className="text-sm font-medium text-foreground">
                {metaPreset.name || metaPreset.pageName}
              </div>
              <MetaPresetItem item={metaPreset} showUser={showUser} />
            </div>
          ) : (
            <PortalSpinner />
          )}
        </div>
      </div>
    );
  }

  return state.data && !getMetaPresetConnectionQuery.loading ? (
    fields
  ) : (
    <PortalSpinner />
  );
});

MetaPresetSetting.displayName = 'MetaPresetSetting';

export default MetaPresetSetting;
