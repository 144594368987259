'use client';

import {I18nProvider} from '@lingui/react';
import {I18n} from '@lingui/core';
import {FC, PropsWithChildren, ReactNode} from 'react';

const DefaultI18n: FC<PropsWithChildren> = ({children}) => (
  <span>{children}</span>
);

export function LinguiProvider({
  children,
  i18n,
}: {
  children: ReactNode;
  i18n: I18n;
}) {
  return (
    <I18nProvider i18n={i18n} defaultComponent={DefaultI18n}>
      {children}
    </I18nProvider>
  );
}
