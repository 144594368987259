'use client';

import PortalLogo from '@/components/icons/PortalLogo';
import {useAppContext, useSearchParamsContext} from '@/hooks';
import {setParamsAfterLogin} from '@/utils';
import {useApolloClient, useMutation} from '@apollo/client';
import TrackButton from '@/components/buttons/TrackButton';
import {memo} from 'react';
import {useLingui} from '@lingui/react';
import {useAnalytics} from '@/common/analytics';
import {META_LOGIN} from '@/common/mutations';
import {msg, Trans} from '@lingui/macro';
import CloseButton from '@/components/Modal';
import {useNavigate} from 'react-router-dom';
import {LEGACY_AUTH_ENABLED} from '@/config';

const NewCampaignHeader = memo(({canClose}: {canClose?: boolean}) => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const {isAuthenticated, logout} = useAppContext();
  const {searchParams} = useSearchParamsContext();
  const {_} = useLingui();
  const {reportMetaAuthStart} = useAnalytics();
  const onLoginCompleted = (data: {href: string}) => {
    if (data.href) {
      window.location.href = data.href;
    }
  };
  const [metaLoginMutation, {loading: metaLoading}] = useMutation(META_LOGIN, {
    onCompleted: onLoginCompleted,
  });

  return (
    <div className="sticky flex w-full shrink-0 items-center justify-between gap-4">
      {isAuthenticated ? (
        <>
          <div className="flex w-full items-center text-base font-semibold">
            <Trans>New Campaign</Trans>
          </div>
          {canClose ? (
            <CloseButton
              className="h-6 w-6 shrink-0"
              onPress={() => {
                navigate('/live');
              }}
            />
          ) : (
            <TrackButton
              aria-label={_(msg`Log Out`)}
              data-amp-track-label="Log Out"
              disableAnimation
              disableRipple
              className="flex h-auto w-auto min-w-0 shrink-0 rounded-none bg-transparent p-0 text-foreground/50"
              onPress={() => {
                logout(apolloClient);
              }}>
              <Trans>Log Out</Trans>
            </TrackButton>
          )}
        </>
      ) : (
        <>
          <PortalLogo className="flex shrink-0 text-foreground" />
          <TrackButton
            aria-label={_(msg`Log In`)}
            data-amp-track-label="Log In"
            disableAnimation
            disableRipple
            isDisabled={metaLoading}
            onPress={() => {
              setParamsAfterLogin(searchParams);

              if (LEGACY_AUTH_ENABLED) {
                navigate('/create/auth/login');
              } else {
                reportMetaAuthStart().finally(() => {
                  metaLoginMutation();
                });
              }
            }}
            className="flex h-auto w-auto min-w-0 shrink-0 rounded-none bg-transparent p-0 text-foreground/50">
            <Trans>Log In</Trans>
          </TrackButton>
        </>
      )}
    </div>
  );
});

NewCampaignHeader.displayName = 'NewCampaignHeader';

export default NewCampaignHeader;
