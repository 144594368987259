'use client';

import {memo, useEffect, useState} from 'react';
import {useIsInAppBrowser, useToastQueue} from '@/hooks';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@/components/Modal';
import {msg, Trans} from '@lingui/macro';
import {Field, Form, Formik} from 'formik';
import {LegalFooter} from './components/LegalFooter';
import {object, string} from 'yup';
import {useLingui} from '@lingui/react';
import {ApolloError, useMutation} from '@apollo/client';
import {gql} from '@/__generated__';
import {formatApolloError} from './helpers';
import ErrorMessage from '@/components/ErrorMessage';
import {Button} from '@nextui-org/react';
import PortalSpinner from '@/components/PortalSpinner';
import NextUIInput from '@/components/inputs/NextUIInput';
import Mail02SolidIcon from '@/components/icons/Mail02SolidIcon';

export const SEND_MAGIC_LINK = gql(`
  mutation SendMagicLink($email: String!) {
    sendMagicLink(email: $email)
  }
`);

const RESEND_DELAY = 59;

const InAppDialog = memo(() => {
  const {addErrorToast} = useToastQueue();
  const {_} = useLingui();
  const {isInApp} = useIsInAppBrowser();
  const [error, setError] = useState<string | undefined>();
  const [email, setEmail] = useState('');
  const [sendMagicLink, sendMagicLinkResult] = useMutation(SEND_MAGIC_LINK);
  const [seconds, setSeconds] = useState(RESEND_DELAY);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0 && email) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [email, seconds]);

  return (
    <Modal isOpen={isInApp} hideCloseButton isDismissable={false}>
      <ModalContent>
        <ModalHeader className="p-4 text-base font-medium">
          <Trans>Continue with Email</Trans>
        </ModalHeader>
        {email ? (
          <>
            <ModalBody className="items-center gap-4 p-4 text-center text-sm font-normal text-foreground/60">
              <Mail02SolidIcon className="h-11 w-11" />
              <div>
                <Trans>
                  We just sent you an email to{' '}
                  <span className="font-medium text-foreground">{email}</span>.
                  It has a magic link to sign you up.
                </Trans>
              </div>
              <div>
                <span className="mr-1">
                  <Trans>Didn’t get the email?</Trans>
                </span>
                {seconds > 0 ? (
                  <Trans>
                    Resend in 0:
                    <span className="tabular-nums">
                      {seconds.toString().padStart(2, '0')}
                    </span>
                  </Trans>
                ) : (
                  <Button
                    aria-label={_(msg`Resend email`)}
                    data-amp-track-label={`Resend email`}
                    variant="light"
                    disableRipple
                    className="h-fit min-w-0 rounded-sm p-0 text-sm underline underline-offset-4 data-[hover=true]:bg-transparent"
                    isDisabled={sendMagicLinkResult.loading}
                    onPress={() => sendMagicLink({variables: {email}})}>
                    <Trans>Resend</Trans>
                  </Button>
                )}
              </div>
            </ModalBody>
            <ModalFooter className="justify-center p-4">
              <Button
                aria-label={_(msg`Back`)}
                data-amp-track-label={`Back to email`}
                fullWidth
                onPress={() => setEmail('')}
                variant="solid"
                color="default"
                disableRipple
                className="bg-foreground/10 font-medium text-foreground">
                <Trans>Back</Trans>
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody className="p-4">
              <Formik
                initialValues={{email: ''}}
                validationSchema={object({
                  email: string()
                    .email(_(msg`Invalid email address`))
                    .required(_(msg`Required`)),
                })}
                onSubmit={async (values, {setValues}) => {
                  setError(undefined);

                  try {
                    await sendMagicLink({
                      variables: {email: values.email},
                      onCompleted: () => {
                        setEmail(values.email);
                        setValues({email: ''});
                      },
                    });
                  } catch (e: any) {
                    if (e instanceof ApolloError) {
                      setError(formatApolloError(e) || undefined);
                    } else {
                      const {data} = e.response || {};
                      const message = data.detail || e.message;

                      setError(message);
                      addErrorToast({message});
                    }
                  }
                }}>
                {({isSubmitting, touched, errors}) => {
                  const isEmailInvalid = Boolean(touched.email && errors.email);

                  return (
                    <Form className="flex min-h-[5.25rem] w-full flex-col gap-4">
                      <Field
                        classNames={{label: '!text-foreground/50 font-normal'}}
                        as={NextUIInput}
                        name="email"
                        type="email"
                        label={_(msg`Email`)}
                        placeholder={_(msg`Enter your email`)}
                        isInvalid={isEmailInvalid}
                        errorMessage={isEmailInvalid ? errors.email : undefined}
                        isDisabled={isSubmitting}
                      />
                      <ErrorMessage message={error} />
                      <Button
                        aria-label={_(msg`Continue`)}
                        data-amp-track-label={`Send magic link`}
                        fullWidth
                        type="submit"
                        variant="solid"
                        color="primary"
                        className="font-medium text-white"
                        spinner={<PortalSpinner size="sm" />}
                        isLoading={isSubmitting}
                        disableRipple>
                        <Trans>Continue</Trans>
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </ModalBody>
            <ModalFooter className="justify-center p-4">
              <LegalFooter />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
});

InAppDialog.displayName = 'InAppDialog';

export default InAppDialog;
