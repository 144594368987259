import {memo} from 'react';
import {Input} from '@nextui-org/react';
import {URLSetting as URLSettingType} from '@/common/types/flowSettings';
import FlowSettingLabel from './Label';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

type Props = {
  setting: URLSettingType;
  onChange: (id: string, value: URLSettingType['value']) => void;
};

const URLSetting = memo(({setting, onChange}: Props) => {
  const {_} = useLingui();

  return (
    <div className="flex w-full flex-col gap-3">
      <FlowSettingLabel>{setting.label}</FlowSettingLabel>
      <Input
        aria-label={_(msg`URL setting ${setting.label}`)}
        data-amp-track-label={`URL setting ${setting.label}`}
        fullWidth
        required={setting.required}
        type="url"
        value={setting.value ?? ''}
        placeholder={setting.data?.placeholder ?? ''}
        classNames={{
          inputWrapper:
            'bg-foreground/10 group-data-[focus=true]:bg-foreground/10 group-data-[hover=true]:bg-foreground/10',
        }}
        onChange={e => onChange(setting.id, e.target.value)}
      />
    </div>
  );
});

URLSetting.displayName = 'URLSetting';

export default URLSetting;
