'use client';

import axios from 'axios';
import {getCountryData, TCountryCode} from 'countries-list';
import {InstrumentEventName} from '@/common/instrumentTracker';
import {IntegrationType} from './types';
import {useCallback} from 'react';
import {useAnalyticsContext} from '@/hooks';

if (typeof window !== 'undefined') {
  window._hsq = window._hsq || [];
}

interface UserInfo {
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
}

interface IPInfoResponse {
  country: string;
  region: string;
  city: string;
  timezone: string;
}

interface GeoInfo {
  countryCode?: string;
  country?: string;
  region?: string;
  city?: string;
  timezone: string;
}

export const useAnalytics = () => {
  const instrumentTracker = useAnalyticsContext();

  const flushQueue = useCallback(async () => {
    await instrumentTracker?.flushQueue();
  }, [instrumentTracker]);

  const getIPGeoInfo = useCallback(async (): Promise<GeoInfo | undefined> => {
    try {
      const {data} = await axios.get<IPInfoResponse>('https://ipinfo.io/json/');
      const countryData = data.country
        ? getCountryData(data.country.toUpperCase() as TCountryCode)
        : undefined;

      return {
        countryCode: data.country,
        country: countryData?.name,
        region: data.region,
        city: data.city,
        timezone: data.timezone,
      };
    } catch (e) {
      console.warn('Failed to get geo info:', e);

      return;
    }
  }, []);

  const identifyHSUser = useCallback(
    ({id, email, firstName, lastName}: UserInfo) => {
      window._hsq.push([
        'identify',
        {
          email,
          firstname: firstName ?? undefined,
          lastname: lastName ?? undefined,
          ...(id ? {id} : {}),
        },
      ]);

      window._hsq.push(['trackPageView']);
    },
    [],
  );

  const reportEmailAuthStart = useCallback(() => {
    window.dataLayer.push({
      event: 'auth-started-email',
    });
    instrumentTracker?.track(InstrumentEventName.AUTH_STARTED_EMAIL);
  }, [instrumentTracker]);

  const reportEmailSignUp = useCallback(() => {
    window.dataLayer.push({
      event: 'sign_up',
    });
    instrumentTracker?.track(InstrumentEventName.SIGNUP_SUCCESS, {
      method: 'email',
    });
  }, [instrumentTracker]);

  const reportEmailSignIn = useCallback(() => {
    window.dataLayer.push({
      event: 'successful-sign-in-email',
    });
    instrumentTracker?.track(InstrumentEventName.SIGNIN_SUCCESS, {
      method: 'email',
    });
  }, [instrumentTracker]);

  const reportGoogleSignUp = useCallback(() => {
    window.dataLayer.push({
      event: 'successful-authorization-google',
    });
    instrumentTracker?.track(InstrumentEventName.SIGNUP_SUCCESS, {
      method: 'google',
    });
  }, [instrumentTracker]);

  const reportGoogleSignIn = useCallback(() => {
    window.dataLayer.push({
      event: 'successful-sign-in-google',
    });
    instrumentTracker?.track(InstrumentEventName.SIGNIN_SUCCESS, {
      method: 'google',
    });
  }, [instrumentTracker]);

  const reportGoogleAuthStart = useCallback(async () => {
    window.dataLayer.push({
      event: 'auth-started-google',
    });

    await instrumentTracker?.track(InstrumentEventName.AUTH_STARTED_GOOGLE);

    await instrumentTracker?.flushQueue();
  }, [instrumentTracker]);

  const reportMetaSignUp = useCallback(() => {
    instrumentTracker?.track(InstrumentEventName.SIGNUP_SUCCESS, {
      method: 'meta',
    });
  }, [instrumentTracker]);

  const reportMetaSignIn = useCallback(() => {
    instrumentTracker?.track(InstrumentEventName.SIGNIN_SUCCESS, {
      method: 'meta',
    });
  }, [instrumentTracker]);

  const reportMetaAuthStart = useCallback(async () => {
    await instrumentTracker?.track(InstrumentEventName.AUTH_STARTED_META);
    await instrumentTracker?.flushQueue();
  }, [instrumentTracker]);

  const reportAppleSignUp = useCallback(() => {
    window.dataLayer.push({
      event: 'successful-authorization-appleid',
    });
    instrumentTracker?.track(InstrumentEventName.SIGNUP_SUCCESS, {
      method: 'apple',
    });
  }, [instrumentTracker]);

  const reportAppleSignIn = useCallback(() => {
    window.dataLayer.push({
      event: 'successful-sign-in-appleid',
    });
    instrumentTracker?.track(InstrumentEventName.SIGNIN_SUCCESS, {
      method: 'apple',
    });
  }, [instrumentTracker]);

  const reportAppleAuthStart = useCallback(async () => {
    window.dataLayer.push({
      event: 'auth-started-apple',
    });

    await instrumentTracker?.track(InstrumentEventName.AUTH_STARTED_APPLE);

    await instrumentTracker?.flushQueue();
  }, [instrumentTracker]);

  const reportSubscribe = useCallback(
    (subscriptionId: string) => {
      window.dataLayer.push({
        event: 'free-trial-started',
        transaction_id: subscriptionId,
      });
      instrumentTracker?.track(InstrumentEventName.SUBSCRIBE_STARTED, {
        transaction_id: subscriptionId,
      });
    },
    [instrumentTracker],
  );

  const reportIntegrationStart = useCallback(
    async (integration_type: IntegrationType) => {
      window.dataLayer.push({
        event: 'grant-access-started',
        integration_type,
      });

      await instrumentTracker?.track(InstrumentEventName.INTEGRATION_START, {
        '3rd_party_service': integration_type,
      });

      await instrumentTracker?.flushQueue();
    },
    [instrumentTracker],
  );

  const reportIntegrationConnect = useCallback(
    (integration_type: string) => {
      window.dataLayer.push({
        event: 'granted-access-provided',
        integration_type,
      });
      instrumentTracker?.track(InstrumentEventName.INTEGRATION_PROVIDED, {
        '3rd_party_service': integration_type,
      });
    },
    [instrumentTracker],
  );

  const reportLogout = useCallback(() => {
    window.dataLayer.push({
      event: 'logout',
    });

    return instrumentTracker?.track(InstrumentEventName.LOGOUT);
  }, [instrumentTracker]);

  const reportIntegration3rdPartyServiceRedirect = useCallback(
    async (integration_type: IntegrationType, href: string) => {
      await instrumentTracker?.track(
        InstrumentEventName.FE_INTEGRATION_REDIRECT,
        {
          '3rd_party_service': integration_type,
          href,
        },
      );
      await instrumentTracker?.flushQueue();
    },
    [instrumentTracker],
  );

  const reportIntegration3rdPartyServiceCallback = useCallback(
    async (integration_type: IntegrationType) => {
      await instrumentTracker?.track(
        InstrumentEventName.FE_INTEGRATION_CALLBACK,
        {
          '3rd_party_service': integration_type,
        },
      );
    },
    [instrumentTracker],
  );

  const reportLaunchAdCampaignStart = useCallback(
    async (eventProperties?: {
      '3rd_party_service': IntegrationType;
      flow_run_id: string;
      number_of_assets: number;
      [key: string]: string | number;
    }) => {
      await instrumentTracker?.track(
        InstrumentEventName.FE_LAUNCH_AD_CAMPAIGN_START,
        eventProperties,
      );
    },
    [instrumentTracker],
  );

  return {
    getIPGeoInfo,
    identifyHSUser,
    reportEmailAuthStart,
    reportEmailSignUp,
    reportEmailSignIn,
    reportGoogleSignUp,
    reportGoogleSignIn,
    reportGoogleAuthStart,
    reportMetaSignUp,
    reportMetaSignIn,
    reportMetaAuthStart,
    reportAppleSignUp,
    reportAppleSignIn,
    reportAppleAuthStart,
    reportSubscribe,
    reportIntegrationStart,
    reportIntegrationConnect,
    reportLogout,
    reportIntegration3rdPartyServiceRedirect,
    reportIntegration3rdPartyServiceCallback,
    reportLaunchAdCampaignStart,
    flushQueue,
  };
};
