import {useCallback} from 'react';

export type IconType =
  | 'grossProfit'
  | 'impressions'
  | 'customerAcquisitionCost'
  | 'numberOfPurchases'
  | 'totalSales';

const useRenderAnimatedBadgeIcon = (icon: IconType) => {
  const renderAnimatedBadgeIcon = useCallback(
    (gradintId: string) => {
      switch (icon) {
        case 'grossProfit':
          return (
            <>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00008 1.83203C3.93073 1.83203 2.93342 1.98855 2.17953 2.26269C2.15614 2.2712 2.13285 2.27987 2.10967 2.2887C1.76193 2.4212 1.4388 2.59099 1.18694 2.80751C0.920462 3.03662 0.666748 3.38053 0.666748 3.83203V4.83203C0.666748 5.28353 0.920462 5.62744 1.18694 5.85655C1.45559 6.08751 1.80533 6.2653 2.17953 6.40137C2.93342 6.67551 3.93073 6.83203 5.00008 6.83203C5.15482 6.83203 5.30806 6.82875 5.45926 6.82232C5.55255 6.81835 5.59919 6.81636 5.641 6.80324C5.681 6.7907 5.71034 6.77549 5.74364 6.75004C5.77846 6.72343 5.81398 6.67736 5.88502 6.58522C6.02717 6.40084 6.17912 6.24895 6.31771 6.1298C6.75256 5.75595 7.2648 5.5099 7.72387 5.34297C8.07885 5.21389 8.46936 5.10915 8.87975 5.02889C9.04834 4.99591 9.13264 4.97943 9.19006 4.9396C9.24242 4.90328 9.27976 4.85794 9.30535 4.79958C9.33342 4.73559 9.33342 4.65405 9.33342 4.49097C9.33342 4.26585 9.33342 4.0065 9.33342 3.83203C9.33342 3.38053 9.0797 3.03662 8.81322 2.80751C8.54457 2.57655 8.19484 2.39877 7.82063 2.26269C7.06675 1.98855 6.06944 1.83203 5.00008 1.83203Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                d="M5.33299 8.49365C5.33313 8.31131 5.18242 8.16536 5.00008 8.16536C3.93073 8.16536 2.93342 8.00884 2.17953 7.7347C1.80533 7.59863 1.45559 7.42085 1.18694 7.18988C1.08902 7.10569 0.99282 7.006 0.9103 6.89116C0.884251 6.85491 0.842988 6.83203 0.798347 6.83203C0.725667 6.83203 0.666748 6.89095 0.666748 6.96363V8.16536C0.666748 8.61686 0.920462 8.96078 1.18694 9.18988C1.45559 9.42085 1.80533 9.59863 2.17953 9.7347C2.93342 10.0088 3.93073 10.1654 5.00008 10.1654C5.00276 10.1654 5.00411 10.1654 5.00821 10.1653C5.18205 10.161 5.32903 10.0139 5.33323 9.84009C5.33333 9.83598 5.33333 9.83295 5.33333 9.82688C5.33312 9.38247 5.33264 8.93806 5.33299 8.49365Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                d="M5.33342 11.827C5.33342 11.6445 5.18252 11.4987 5.00008 11.4987C3.93073 11.4987 2.93342 11.3422 2.17953 11.068C1.80533 10.932 1.45559 10.7542 1.18694 10.5232C1.08902 10.439 0.99282 10.3393 0.9103 10.2245C0.88425 10.1882 0.842988 10.1654 0.798347 10.1654C0.725667 10.1654 0.666748 10.2243 0.666748 10.297V11.832C0.666748 12.2835 0.920462 12.6274 1.18694 12.8565C1.45559 13.0875 1.80533 13.2653 2.17953 13.4014C2.93342 13.6755 3.93073 13.832 5.00008 13.832C5.01283 13.832 5.01973 13.832 5.01973 13.832C5.23309 13.8314 5.37032 13.6803 5.35058 13.4679C5.35058 13.4679 5.34872 13.4485 5.3451 13.4108C5.33745 13.3312 5.33342 13.2494 5.33342 13.1654V11.827Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.4715 5.94456C9.94874 5.87116 10.4654 5.83203 11.0001 5.83203C12.0694 5.83203 13.0667 5.98855 13.8206 6.26269C13.844 6.2712 13.8673 6.27987 13.8905 6.2887C14.2382 6.4212 14.5614 6.59099 14.8132 6.80751C15.0797 7.03662 15.3334 7.38053 15.3334 7.83203V9.16536C15.3334 9.61686 15.0797 9.96078 14.8132 10.1899C14.5446 10.4208 14.1948 10.5986 13.8206 10.7347C13.0667 11.0088 12.0694 11.1654 11.0001 11.1654C9.93073 11.1654 8.93342 11.0088 8.17953 10.7347C7.80533 10.5986 7.45559 10.4208 7.18694 10.1899C6.92046 9.96078 6.66675 9.61686 6.66675 9.16536C6.66675 8.71897 6.66675 8.27148 6.66675 7.83203C6.66675 7.38053 6.92046 7.03662 7.18694 6.80751C7.45559 6.57655 7.80533 6.39877 8.17953 6.26269C8.22665 6.24556 8.27471 6.22889 8.32369 6.21268C8.62843 6.11185 8.96837 6.02917 9.33342 5.96694C9.37906 5.95916 9.42509 5.9517 9.4715 5.94456Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                d="M6.79835 11.1654C6.72567 11.1654 6.66675 11.2243 6.66675 11.297V12.832C6.66675 13.2835 6.92046 13.6274 7.18694 13.8565C7.45559 14.0875 7.80533 14.2653 8.17953 14.4014C8.93342 14.6755 9.93073 14.832 11.0001 14.832C12.0694 14.832 13.0667 14.6755 13.8206 14.4014C14.1948 14.2653 14.5446 14.0875 14.8132 13.8565C15.0797 13.6274 15.3334 13.2835 15.3334 12.832V11.297C15.3334 11.2243 15.2745 11.1654 15.2018 11.1654C15.1572 11.1654 15.1159 11.1882 15.0899 11.2245C15.0073 11.3393 14.9111 11.439 14.8132 11.5232C14.5446 11.7542 14.1948 11.932 13.8206 12.068C13.0667 12.3422 12.0694 12.4987 11.0001 12.4987C9.93073 12.4987 8.93342 12.3422 8.17953 12.068C7.80533 11.932 7.45559 11.7542 7.18694 11.5232C7.08902 11.439 6.99282 11.3393 6.9103 11.2245C6.88425 11.1882 6.84299 11.1654 6.79835 11.1654Z"
                // fill="#CBCBCB"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
            </>
          );
        case 'customerAcquisitionCost':
          return (
            <>
              <path
                d="M13.3334 3.16667C13.3334 2.79848 13.0349 2.5 12.6667 2.5C12.2986 2.5 12.0001 2.79848 12.0001 3.16667V4.5H10.6667C10.2986 4.5 10.0001 4.79848 10.0001 5.16667C10.0001 5.53486 10.2986 5.83333 10.6667 5.83333H12.0001V7.16667C12.0001 7.53486 12.2986 7.83333 12.6667 7.83333C13.0349 7.83333 13.3334 7.53486 13.3334 7.16667V5.83333H14.6667C15.0349 5.83333 15.3334 5.53486 15.3334 5.16667C15.3334 4.79848 15.0349 4.5 14.6667 4.5H13.3334V3.16667Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.50584 9.83333H7.49424C8.03089 9.83332 8.4738 9.83332 8.83459 9.8628C9.20932 9.89341 9.55379 9.95912 9.87735 10.124C10.3791 10.3796 10.7871 10.7876 11.0427 11.2894C11.2076 11.6129 11.2733 11.9574 11.3039 12.3321C11.3334 12.6929 11.3334 13.1358 11.3334 13.6725C11.3334 13.8609 11.342 14.0519 11.3266 14.2399C11.3187 14.3367 11.2984 14.4754 11.2244 14.6207C11.1285 14.8088 10.9755 14.9618 10.7874 15.0577C10.6421 15.1317 10.5034 15.152 10.4066 15.1599C10.3231 15.1667 10.2282 15.1667 10.1488 15.1667C7.38296 15.1657 4.61712 15.1657 1.85128 15.1667C1.77187 15.1667 1.67703 15.1667 1.59349 15.1599C1.49668 15.152 1.35802 15.1317 1.21272 15.0577C1.02456 14.9618 0.871576 14.8088 0.775702 14.6207C0.70167 14.4754 0.681374 14.3367 0.673464 14.2399C0.658105 14.0519 0.666709 13.8609 0.666709 13.6725C0.6667 13.1358 0.666693 12.6929 0.696171 12.3321C0.726788 11.9574 0.792498 11.6129 0.957358 11.2894C1.21302 10.7876 1.62097 10.3796 2.12273 10.124C2.44629 9.95912 2.79076 9.89341 3.16549 9.8628C3.52629 9.83332 3.9692 9.83332 4.50584 9.83333Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.00004 5.5C3.00004 3.84315 4.34319 2.5 6.00004 2.5C7.6569 2.5 9.00004 3.84315 9.00004 5.5C9.00004 7.15685 7.6569 8.5 6.00004 8.5C4.34319 8.5 3.00004 7.15685 3.00004 5.5Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
            </>
          );
        case 'impressions':
          return (
            <>
              <path
                d="M6.66661 8.5013C6.66661 7.76492 7.26356 7.16797 7.99994 7.16797C8.73632 7.16797 9.33327 7.76492 9.33327 8.5013C9.33327 9.23768 8.73632 9.83464 7.99994 9.83464C7.26356 9.83464 6.66661 9.23768 6.66661 8.5013Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99994 3.16797C6.09216 3.16797 4.53684 4.01112 3.38444 4.97292C2.23361 5.93342 1.44459 7.04416 1.04942 7.66987L1.03337 7.69519C0.954952 7.81862 0.853142 7.97887 0.801675 8.20341C0.760119 8.38472 0.760119 8.61789 0.801675 8.79919C0.853142 9.02373 0.954951 9.18398 1.03336 9.30741L1.04942 9.33274C1.44459 9.95844 2.23361 11.0692 3.38444 12.0297C4.53684 12.9915 6.09216 13.8346 7.99994 13.8346C9.90773 13.8346 11.463 12.9915 12.6154 12.0297C13.7663 11.0692 14.5553 9.95844 14.9505 9.33274L14.9665 9.30742C15.0449 9.18399 15.1467 9.02374 15.1982 8.79919C15.2398 8.61789 15.2398 8.38472 15.1982 8.20341C15.1467 7.97887 15.0449 7.81861 14.9665 7.69518L14.9505 7.66987C14.5553 7.04416 13.7663 5.93342 12.6154 4.97292C11.463 4.01112 9.90772 3.16797 7.99994 3.16797ZM7.99994 5.83464C6.52718 5.83464 5.33327 7.02854 5.33327 8.5013C5.33327 9.97406 6.52718 11.168 7.99994 11.168C9.4727 11.168 10.6666 9.97406 10.6666 8.5013C10.6666 7.02854 9.4727 5.83464 7.99994 5.83464Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
            </>
          );
        case 'numberOfPurchases':
          return (
            <>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33341 2.50131H2.04045L2.63771 12.0575C2.64235 12.1323 2.64795 12.2225 2.65929 12.3019C2.67252 12.3946 2.69971 12.5252 2.77624 12.6605C2.87604 12.8368 3.02708 12.9787 3.20934 13.0673C3.3491 13.1353 3.48113 13.1543 3.57451 13.1617C3.65448 13.168 3.74485 13.168 3.81976 13.168L12.6667 13.168C13.0349 13.168 13.3334 12.8695 13.3334 12.5013C13.3334 12.1331 13.0349 11.8346 12.6667 11.8346H3.95972L3.87638 10.5013H12.1146C12.4317 10.5013 12.7071 10.5013 12.9355 10.4836C13.178 10.4647 13.4187 10.4231 13.655 10.3125C14.0101 10.1461 14.3085 9.87906 14.5131 9.54447C14.6492 9.32187 14.7171 9.08718 14.7626 8.84828C14.8055 8.6232 14.8359 8.34963 14.8709 8.03449L15.2654 4.48389C15.2751 4.39639 15.2866 4.29384 15.2889 4.20351C15.2915 4.10048 15.2858 3.94791 15.2178 3.7843C15.1309 3.57529 14.9758 3.40192 14.7776 3.29253C14.6225 3.2069 14.4715 3.18431 14.3688 3.17557C14.2788 3.1679 14.1756 3.16794 14.0876 3.16797L3.41805 3.16798L3.36246 2.27845C3.35781 2.20369 3.35221 2.11349 3.34088 2.03407C3.32764 1.94134 3.30046 1.81075 3.22392 1.6755C3.12412 1.49912 2.97308 1.35723 2.79082 1.26863C2.65106 1.20068 2.51903 1.18169 2.42566 1.17427C2.34569 1.16791 2.25532 1.16794 2.18042 1.16797L1.33341 1.16798C0.965225 1.16798 0.666748 1.46645 0.666748 1.83464C0.666748 2.20283 0.965225 2.50131 1.33341 2.50131Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                d="M5.00008 13.8346C4.4478 13.8346 4.00008 14.2824 4.00008 14.8346C4.00008 15.3869 4.4478 15.8346 5.00008 15.8346C5.55237 15.8346 6.00008 15.3869 6.00008 14.8346C6.00008 14.2824 5.55237 13.8346 5.00008 13.8346Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
              <path
                d="M11.0001 13.8346C10.4478 13.8346 10.0001 14.2824 10.0001 14.8346C10.0001 15.3869 10.4478 15.8346 11.0001 15.8346C11.5524 15.8346 12.0001 15.3869 12.0001 14.8346C12.0001 14.2824 11.5524 13.8346 11.0001 13.8346Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
            </>
          );
        case 'totalSales':
          return (
            <>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.66659 1.83464C8.66659 1.46645 8.36811 1.16797 7.99992 1.16797C7.63173 1.16797 7.33325 1.46645 7.33325 1.83464V2.5013H6.66659C4.82564 2.5013 3.33325 3.99369 3.33325 5.83464C3.33325 7.67558 4.82564 9.16797 6.66659 9.16797H7.33325V13.168H6.66659C5.56202 13.168 4.66659 12.2725 4.66659 11.168C4.66659 10.7998 4.36811 10.5013 3.99992 10.5013C3.63173 10.5013 3.33325 10.7998 3.33325 11.168C3.33325 13.0089 4.82564 14.5013 6.66659 14.5013H7.33325V15.168C7.33325 15.5362 7.63173 15.8346 7.99992 15.8346C8.36811 15.8346 8.66659 15.5362 8.66659 15.168V14.5013H9.33325C11.1742 14.5013 12.6666 13.0089 12.6666 11.168C12.6666 9.32702 11.1742 7.83464 9.33325 7.83464H8.66659V3.83464H9.33325C10.4378 3.83464 11.3333 4.73007 11.3333 5.83464C11.3333 6.20283 11.6317 6.5013 11.9999 6.5013C12.3681 6.5013 12.6666 6.20283 12.6666 5.83464C12.6666 3.99369 11.1742 2.5013 9.33325 2.5013H8.66659V1.83464ZM7.33325 3.83464H6.66659C5.56202 3.83464 4.66659 4.73007 4.66659 5.83464C4.66659 6.93921 5.56202 7.83464 6.66659 7.83464H7.33325V3.83464ZM8.66659 9.16797V13.168H9.33325C10.4378 13.168 11.3333 12.2725 11.3333 11.168C11.3333 10.0634 10.4378 9.16797 9.33325 9.16797H8.66659Z"
                fill={`url(#${gradintId})`}
                style={{mixBlendMode: 'color-dodge'}}
              />
            </>
          );
      }
    },
    [icon],
  );

  return renderAnimatedBadgeIcon;
};

export default useRenderAnimatedBadgeIcon;
