import {makeVar, useReactiveVar} from '@apollo/client';
import {useCallback} from 'react';

export const modalRegistry = makeVar<Set<string>>(new Set());

const generateId = () => `${Math.random().toString(36).slice(2)}${Date.now()}`;

export const useModalRegistry = () => {
  const registry = useReactiveVar(modalRegistry);

  const size = registry.size;

  const add = useCallback(() => {
    const id = generateId();

    modalRegistry(new Set([...modalRegistry(), id]));

    return () => {
      modalRegistry(new Set([...modalRegistry()].filter(x => x !== id)));
    };
  }, []);

  return {
    size,
    add,
  };
};
