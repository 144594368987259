'use client';

import * as amplitude from '@amplitude/analytics-browser';
import {
  InstrumentTransport,
  InstrumentEventMetadata,
  InstrumentEventName,
} from '../InstrumentTracker';
import {
  EnrichmentPlugin,
  Event,
  SpecialEventType,
} from '@amplitude/analytics-types';
import {UTM_PROPERTY_NAMES, utmPropertiesVar} from '@/utmProperties';
import {AMPLITUDE_API_KEY, AMPLITUDE_OPT_OUT} from '@/config';
import {getWindow} from '@/utils';

export class EventUtmEnrichmentPlugin implements EnrichmentPlugin {
  name = 'event-utm-enrichment-plugin';
  type = 'enrichment' as any;

  async setup(): Promise<void> {
    this.setUtmProperties(new URLSearchParams(getWindow()?.location.search));

    return undefined;
  }

  async execute(event: Event): Promise<Event> {
    if (event.event_type === SpecialEventType.IDENTIFY) {
      this.setUtmProperties(Object.entries(event.user_properties ?? {}));
    } else {
      event.event_properties = {
        ...utmPropertiesVar(),
        ...(event.event_properties ?? {}),
      };
    }

    return event;
  }

  private setUtmProperties(entries: Iterable<string[]>) {
    const utmEntries = [...entries].filter(([key]) =>
      UTM_PROPERTY_NAMES.has(key),
    );

    utmPropertiesVar(Object.fromEntries(utmEntries));
  }
}

export class AmplitudeTransport extends InstrumentTransport {
  async init(): Promise<void> {
    amplitude.add(new EventUtmEnrichmentPlugin());
    amplitude.init(AMPLITUDE_API_KEY, {
      autocapture: {
        elementInteractions: true,
        attribution: {excludeReferrers: []},
      },
      optOut: AMPLITUDE_OPT_OUT,
    });
  }

  async track(
    eventName: string,
    eventProperties?: Record<string, any>,
    eventMetadata: InstrumentEventMetadata = {},
  ): Promise<void> {
    const metadata = {...eventMetadata};

    // Add user_id to metadata for logout events
    // bacause user_id gets removed before the event is sent
    // even after awaiting (probably due to batching)
    if (eventName === InstrumentEventName.LOGOUT) {
      const user_id = amplitude.getUserId();

      if (user_id) {
        metadata.user_id = user_id;
      }
    }

    amplitude.track(eventName, eventProperties, metadata);
  }

  async getMetadata(): Promise<InstrumentEventMetadata> {
    return {
      user_id: amplitude.getUserId(),
      session_id: amplitude.getSessionId(),
      device_id: amplitude.getDeviceId(),
    };
  }

  async flushQueue(): Promise<void> {
    amplitude.flush();
  }

  async setUserId(userId?: string): Promise<void> {
    amplitude.setUserId(userId);
  }
}
