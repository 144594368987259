'use client';

import {useState, useEffect} from 'react';
import {useMutation} from '@apollo/client';
import {LRUCache} from 'lru-cache';
import {GENERATE_DOWNLOAD_LINK} from '@/common/mutations';

const CACHE_KEY = 'presignedUrlCache';

const cache = new LRUCache<string, string>({
  max: 500,
  // We won't set a global TTL because each URL may have a different expiry
  // We'll set the TTL per entry when we add items to the cache
});

// Load the cache from localStorage
const loadCache = () => {
  const serializedCache = localStorage.getItem(CACHE_KEY);

  if (serializedCache) {
    try {
      const dumpedCache = JSON.parse(serializedCache);
      cache.load(dumpedCache);
    } catch (error) {
      console.error('Failed to load cache from localStorage:', error);
    }
  }
};

// Save the cache to localStorage
const saveCache = () => {
  try {
    const dumpedCache = cache.dump();
    localStorage.setItem(CACHE_KEY, JSON.stringify(dumpedCache));
  } catch (error) {
    console.error('Failed to save cache to localStorage:', error);
  }
};

loadCache(); // Load the cache when the module is first imported

export const usePresignedLink = (
  name: string | undefined | null,
): string | null => {
  const [url, setUrl] = useState<string | null>(null);
  const [downloadByName] = useMutation(GENERATE_DOWNLOAD_LINK, {
    ignoreResults: true,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchPresignedUrl = async () => {
      if (!name) {
        return;
      }

      // Check if the URL is in the cache
      if (cache.has(name)) {
        const cachedUrl = cache.get(name);
        if (cachedUrl && isMounted) {
          setUrl(cachedUrl);
          return;
        }
      }

      // If not in cache or expired, fetch a new URL
      try {
        const result = await downloadByName({
          variables: {name},
        });

        const newUrl = result.data?.generateFileLink;

        if (newUrl) {
          // Extract the expiry time from the URL
          const expiryTime = extractExpiryFromUrl(newUrl);
          if (expiryTime) {
            const ttl = expiryTime - Date.now();
            if (ttl > 0) {
              // Store in cache with TTL
              cache.set(name, newUrl, {ttl});
              saveCache(); // Save the updated cache to localStorage
            }
          }

          if (isMounted) {
            setUrl(newUrl);
          }
        }
      } catch (error) {
        console.error('Error fetching presigned URL:', error);
      }
    };

    fetchPresignedUrl();

    return () => {
      isMounted = false;
    };
  }, [name]);

  return url;
};

// Function to extract the 'Expires' parameter from the URL
function extractExpiryFromUrl(url: string): number | null {
  try {
    const urlObj = new URL(url);
    const expiresParam = urlObj.searchParams.get('Expires');
    if (expiresParam) {
      const expiryTimeInSeconds = parseInt(expiresParam, 10);
      return expiryTimeInSeconds * 1000; // Convert to milliseconds
    }
  } catch (error) {
    console.error('Invalid URL:', error);
  }
  return null;
}

export default usePresignedLink;
