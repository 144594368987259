import {memo} from 'react';
import Skeleton from '@/components/Skeleton';
import {twMerge} from 'tailwind-merge';

const VideoSelectorItemSkeleton = memo(({className}: {className?: string}) => {
  return (
    <div
      className={twMerge('h-full w-full', className)}
      style={{
        maskImage: 'linear-gradient(to bottom, black 0%, rgba(0,0,0,0.1) 100%)',
        WebkitMaskImage:
          'linear-gradient(to bottom, black 0%, rgba(0,0,0,0.1) 100%)',
      }}>
      <Skeleton direction="vertical" className="rounded-xl" />
    </div>
  );
});

VideoSelectorItemSkeleton.displayName = 'VideoSelectorItemSkeleton';

export default VideoSelectorItemSkeleton;
