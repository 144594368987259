'use client';

import ModalOutlet from '@/components/ModalOutlet';
import {memo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import GenerateCampaign from './GenerateCampaign';
import NewCampaignHeader from './components/NewCampaignHeader';
import {useQuery} from '@apollo/client';
import {GET_FLOW_RUN_CONNECTION} from '@/common/queries';
import {useAppContext, useAuthContext} from '@/hooks';
import InAppDialog from './InAppDialog';

const subPath = ['auth', 'asset', 'fine-tune', 'meta-presets'];

const NewCampaign = memo(() => {
  const {isAuthenticated} = useAppContext();
  const {user} = useAuthContext();
  const {pathname} = useLocation();
  const {runId} = useParams();
  const basePath = runId ? `/create/${runId}` : 'create';
  const flowRuns = useQuery(GET_FLOW_RUN_CONNECTION, {
    variables: {
      filter: {
        userId: user?.id,
        campaignId: {$ne: null},
        errorMessage: {$eq: null},
      },
      first: 1,
      order: {createdAt: 'asc'},
    },
    skip: !user,
    fetchPolicy: 'cache-and-network',
  });
  const hasSuccessfulFlowRun = Boolean(
    flowRuns.data?.getFlowRunConnection?.edges.length,
  );

  return (
    <>
      <InAppDialog />
      <ModalOutlet
        isDismissable={pathname.includes('/asset')}
        basePath={basePath}
        subPath={subPath}
      />
      <div className="flex w-full max-w-[min(100dvw,42rem)] grow flex-col gap-5 p-4">
        <NewCampaignHeader canClose={hasSuccessfulFlowRun} />
        <GenerateCampaign
          showBanner={
            !hasSuccessfulFlowRun &&
            Boolean(flowRuns.data?.getFlowRunConnection || !isAuthenticated)
          }
        />
      </div>
    </>
  );
});

NewCampaign.displayName = 'NewCampaign';

export default NewCampaign;
