import {gql} from '@/__generated__';

export const GET_USER_STATUS = gql(`
  query GetUserStatus($email: String!) {
    getUserStatus(email: $email)
  }
`);

export const GET_INTEGRATIONS = gql(`
  query GetIntegrationConnection(
    $filter: JSON
    $order: JSON
    $first: Int
    $after: String
  ) {
    connection: getIntegrationConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          type
          displayName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_FLOW_RUN = gql(`
  query GetFlowRun($id: ID!) {
    getFlowRun(id: $id) {
      id
      userId
      sourceFileId
      status
      errorMessage
      errorStep
      promptCategory
      promptText
      createdAt
      updatedAt
      deletedAt
      campaignId
      campaignCreatedAt
      videoGenerationStartedAt
      sourceFile {
        id
        displayName
        name
        thumbnailName
      }
      assets(first: 20) {
        edges {
          node {
            ...AssetFragment
            videoFile {
              ...FileFragment
            }
          }
        }
      }
      settings(first: 100) {
        edges {
          node {
            id
            flowRunId
            platform
            key
            value
            required
            featured
            data
            label
            type
            sortValue
          }
        }
      }
    }
  }
`);

export const GET_FLOW_RUN_CONNECTION = gql(`
  query GetFlowRunConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    getFlowRunConnection(filter: $filter, order: $order, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          userId
          sourceFileId
          status
          errorMessage
          errorStep
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`);

export const GET_ASSET = gql(`
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      ...AssetFragment
      videoFile {
        ...FileFragment
      }
    }
  }
`);

export const CONNECT_SHOPIFY_INTEGRATION = gql(`
  mutation ConnectShopifyIntegration($input: ShopifyIntegrationConnectInput!) {
    href: connectShopifyIntegration(input: $input)
  }
`);

export const GET_META_PRESET = gql(`
  query GetMetaPreset($id: ID!) {
    getMetaPreset(id: $id) {
      ...MetaPresetFragment
    }
  }
`);

export const GET_META_PRESET_CONNECTION = gql(`
  query GetMetaPresetConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    connection: getMetaPresetConnection(filter: $filter, order: $order, first: $first, after: $after) {
      edges {
        node {
          ...MetaPresetFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export const GET_META_PRESETS_DATA = gql(`
  query GetMetaPresetData {
    getMetaPresetData {
      users {
        id
        metaId
        firstName
        lastName
        email
        avatar
        pages {
          id
          name
          avatar
          instagramAccount {
            actorId
            username
            avatar
          }
        }
        adAccounts {
          id
          name
          status
          currency
          timezone
          pixels {
            id
            name
          }
        }
      }
    }
  }
`);

export const COUNT_PLATFORM_USERS = gql(`
  query CountPlatformUsers($filter: JSON) {
    countPlatformUsers(filter: $filter)
  }
`);

export const GET_PLATFORM_USER_CONNECTION = gql(`
  query GetPlatformUserConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    connection: getPlatformUserConnection(filter: $filter, order: $order, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          platformId
          platform
          platformData
          accessToken
          tokenType
          avatar
          firstName
          lastName
          email
          userId
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`);

export const GET_PHONE_NUMBER_CONNECTION = gql(`
  query GetPhoneNumberConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    connection: getPhoneNumberConnection(
      filter: $filter
      order: $order
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          phoneNumber
          whatsappOptedIn
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const GET_FLOW_RUN_ASSET_AD_INFO = gql(`
  query GetFlowRunAssetAdInfo($id: ID!) {
    getFlowRunAssetAdInfo(id: $id) {
      status
      statusCategory
      isLearning
      isPaused
      canChangePauseState
      startTime
      endTime
    }
  }
`);
