import React, {forwardRef, useEffect, useState} from 'react';
import campaignCreatedImg from '@/assets/campaign-created-bg.png';

interface Props {
  firstName?: string;
  isRecord: boolean;
  showDate: boolean;
  minutes?: string;
  seconds?: string;
  milliseconds?: string;
}

const CampaignSVG = forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const {firstName, isRecord, showDate, minutes, seconds, milliseconds} =
      props;

    const [backgroundImageDataUrl, setBackgroundImageDataUrl] =
      useState<any>(null);

    useEffect(() => {
      // Load the image and convert it to a Data URL
      async function loadImage() {
        try {
          const response = await fetch(campaignCreatedImg);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            setBackgroundImageDataUrl(reader.result); // This is the Data URL
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error('Failed to load image', error);
        }
      }
      loadImage();
    }, []);

    // Wait until the background image is loaded
    if (!backgroundImageDataUrl) {
      return null; // Or display a loading indicator if desired
    }

    // SVG dimensions and calculations
    const SVG_WIDTH = 1080;
    const SVG_HEIGHT = 1080;
    const CENTER_X = SVG_WIDTH / 2;
    const CENTER_Y = SVG_HEIGHT / 2;

    return (
      <svg
        ref={ref}
        aria-hidden="true"
        width={SVG_WIDTH}
        height={SVG_HEIGHT}
        className="pointer-events-none invisible absolute"
        xmlns="http://www.w3.org/2000/svg">
        {/* Background Image */}
        <image
          href={backgroundImageDataUrl}
          x="0"
          y="0"
          width={SVG_WIDTH}
          height={SVG_HEIGHT}
        />

        {/* Emoji */}
        <text
          x={CENTER_X}
          y={CENTER_Y - 250}
          textAnchor="middle"
          fontSize="144">
          {isRecord ? '🦄' : '🚀'}
        </text>

        {/* Check Circle Icon */}
        <g transform={`translate(${CENTER_X + 45}, ${CENTER_Y - 265})`}>
          {/* Replace with actual path data */}
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10
               10-4.48 10-10S17.52 2 12 2zm-1 15l-5-5
               1.41-1.41L11 14.17l7.59-7.59L20 8l-9 9z"
            fill="#00ff00"
          />
        </g>

        {/* Congrats Text */}
        <text
          x={CENTER_X}
          y={CENTER_Y - 120}
          textAnchor="middle"
          fill="#ffffff"
          fontSize="48"
          fontFamily="Arial, sans-serif">
          {isRecord
            ? 'New Personal Best!'
            : firstName
              ? `Congrats, ${firstName}!`
              : 'Congrats!'}
        </text>

        {/* Subtext Line 1 */}
        <text
          x={CENTER_X}
          y={CENTER_Y - 70}
          textAnchor="middle"
          fill="rgba(255, 255, 255, 0.5)"
          fontSize="32"
          fontFamily="Arial, sans-serif">
          You’ve Successfully
        </text>

        {/* Subtext Line 2 */}
        <text
          x={CENTER_X}
          y={CENTER_Y - 30}
          textAnchor="middle"
          fill="rgba(255, 255, 255, 0.5)"
          fontSize="32"
          fontFamily="Arial, sans-serif">
          {showDate
            ? 'Launched the Campaign in just:'
            : 'Launched the Campaign'}
        </text>

        {/* Time Parts */}
        {showDate && (
          <g>
            {(() => {
              const timePartWidth = 80;
              const timePartHeight = 80;
              const gap = 20;
              const timeParts = [];
              let index = 0;
              let numberOfTimeParts = 0;

              if (minutes) numberOfTimeParts += 1;
              if (seconds) numberOfTimeParts += 1;
              if (milliseconds) numberOfTimeParts += 1;

              const totalWidth =
                timePartWidth * numberOfTimeParts +
                gap * (numberOfTimeParts - 1);
              const startX = CENTER_X - totalWidth / 2;
              const timePartsY = CENTER_Y + 20; // Adjusted Y position

              if (minutes) {
                timeParts.push(
                  <g key="minutes">
                    <rect
                      x={startX + index * (timePartWidth + gap)}
                      y={timePartsY}
                      width={timePartWidth}
                      height={timePartHeight}
                      fill="#262626"
                      rx="10"
                    />
                    <text
                      x={
                        startX +
                        index * (timePartWidth + gap) +
                        timePartWidth / 2
                      }
                      y={timePartsY + 40}
                      textAnchor="middle"
                      fill="#ffffff"
                      fontSize="40"
                      fontFamily="Arial, sans-serif">
                      {minutes}
                    </text>
                    <text
                      x={
                        startX +
                        index * (timePartWidth + gap) +
                        timePartWidth / 2
                      }
                      y={timePartsY + 65}
                      textAnchor="middle"
                      fill="rgba(255, 255, 255, 0.5)"
                      fontSize="20"
                      fontFamily="Arial, sans-serif">
                      min
                    </text>
                  </g>,
                );
                index += 1;
              }

              if (seconds) {
                timeParts.push(
                  <g key="seconds">
                    <rect
                      x={startX + index * (timePartWidth + gap)}
                      y={timePartsY}
                      width={timePartWidth}
                      height={timePartHeight}
                      fill="#262626"
                      rx="10"
                    />
                    <text
                      x={
                        startX +
                        index * (timePartWidth + gap) +
                        timePartWidth / 2
                      }
                      y={timePartsY + 40}
                      textAnchor="middle"
                      fill="#ffffff"
                      fontSize="40"
                      fontFamily="Arial, sans-serif">
                      {seconds}
                    </text>
                    <text
                      x={
                        startX +
                        index * (timePartWidth + gap) +
                        timePartWidth / 2
                      }
                      y={timePartsY + 65}
                      textAnchor="middle"
                      fill="rgba(255, 255, 255, 0.5)"
                      fontSize="20"
                      fontFamily="Arial, sans-serif">
                      s
                    </text>
                  </g>,
                );
                index += 1;
              }

              if (milliseconds) {
                timeParts.push(
                  <g key="milliseconds">
                    <rect
                      x={startX + index * (timePartWidth + gap)}
                      y={timePartsY}
                      width={timePartWidth}
                      height={timePartHeight}
                      fill="#262626"
                      rx="10"
                    />
                    <text
                      x={
                        startX +
                        index * (timePartWidth + gap) +
                        timePartWidth / 2
                      }
                      y={timePartsY + 40}
                      textAnchor="middle"
                      fill="#ffffff"
                      fontSize="40"
                      fontFamily="Arial, sans-serif">
                      {milliseconds}
                    </text>
                    <text
                      x={
                        startX +
                        index * (timePartWidth + gap) +
                        timePartWidth / 2
                      }
                      y={timePartsY + 65}
                      textAnchor="middle"
                      fill="rgba(255, 255, 255, 0.5)"
                      fontSize="20"
                      fontFamily="Arial, sans-serif">
                      ms
                    </text>
                  </g>,
                );
              }

              return timeParts;
            })()}
          </g>
        )}
      </svg>
    );
  },
);

CampaignSVG.displayName = 'CampaignSVG';

export default CampaignSVG;
