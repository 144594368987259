'use client';

import {Trans} from '@lingui/macro';
import {Link} from '@nextui-org/link';
import {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';

export const LegalFooter: FC = () => {
  return (
    <div className={`text-center text-xs leading-4 text-default-400`}>
      <Trans>
        <p>
          By clicking Continue, you agree to the{' '}
          <Link
            aria-label={`Terms of Service`}
            data-amp-track-label={`Terms of Service`}
            as={RouterLink}
            className="text-xs text-default-400 underline"
            to="/legal/terms">
            Terms of Service
          </Link>
          .<br />
          Learn more about our data practices in our{' '}
          <Link
            aria-label={`Privacy Policy`}
            data-amp-track-label={`Privacy Policy`}
            as={RouterLink}
            className="text-xs text-default-400 underline"
            to="/legal/privacy">
            Privacy Policy
          </Link>
          .
        </p>
      </Trans>
    </div>
  );
};
