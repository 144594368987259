'use client';

import {memo, useEffect, useState} from 'react';
import Markdown from 'react-markdown';

const LegalDocument = memo(({file}: {file: Promise<{default: string}>}) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    file.then(i => setContent(i.default));
  }, [file]);

  return (
    <div className="flex min-h-[100dvh] flex-col px-4 py-8">
      <Markdown
        className="prose prose-sm prose-invert mx-auto w-full pb-[calc(var(--bottom-nav-height)+4rem)] prose-pre:bg-transparent md:pb-[4rem]"
        components={{
          pre: ({node, ...props}) => (
            <pre
              {...props}
              className="not-prose color-inherit whitespace-pre-wrap break-words pl-4 font-sans"
            />
          ),
          code: ({node, ...props}) => <span {...props} />,
        }}>
        {content}
      </Markdown>
    </div>
  );
});

LegalDocument.displayName = 'LegalDocument';

export default LegalDocument;
