'use client';

import ModalOutlet from '@/components/ModalOutlet';
import React, {memo, useCallback, useMemo} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {ProfileHeader} from './components/ProfileHeader';
import {msg, Trans} from '@lingui/macro';
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import {useAppContext, useAuthContext} from '@/hooks';
import {getUserName} from '@/utils';
import {twMerge} from 'tailwind-merge';
import {getIntegrationIcon} from '@/common/integrations';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon';
import DotsVertical from '@/components/icons/DotsVertical';
import UsersIcon from '@/components/icons/UsersIcon';
import LinkBrokenIcon from '@/components/icons/LinkBrokenIcon';
import WhatsAppLogo from '@/assets/whatsapp-logo-small.png';
import {useLingui} from '@lingui/react';
import {useApolloClient, useQuery} from '@apollo/client';
import {
  COUNT_PLATFORM_USERS,
  GET_META_PRESET_CONNECTION,
} from '@/common/queries';
import PortalSpinner from '@/components/PortalSpinner';
import {FlowPlatform} from '@/__generated__/graphql';
import FacebookLogo from '@/assets/facebook-logo.svg';
import TrackButton from '@/components/buttons/TrackButton';
import usePhoneNumberFormatter from '@/hooks/usePhoneNumberFormatter';
import {useWhatsappNotifications} from '@/hooks/useWhatsappNotifications';

const subpath = [
  'meta-presets',
  'delete-account',
  'meta-users',
  'enable-whatsapp-notifications',
  'disable-whatsapp-notifications',
];

const ProfileSection = memo(
  ({
    title,
    className,
    children,
  }: {
    title?: string | React.ReactNode;
    className?: string;
    children: React.ReactNode;
  }) => {
    return (
      <div
        className={twMerge(
          'flex w-full flex-col gap-1 overflow-hidden rounded-xl bg-foreground/10 p-4',
          className,
        )}>
        {title ? (
          <div className="text-base font-semibold text-foreground">{title}</div>
        ) : null}
        <div className={'flex w-full flex-col gap-4'}>{children}</div>
      </div>
    );
  },
);

ProfileSection.displayName = 'ProfileSection';

const Profile = memo(() => {
  const {_} = useLingui();
  const {user} = useAuthContext();
  const {logout} = useAppContext();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const {
    isLoading: phoneNumbersLoading,
    optedInPhoneNumbers,
    optedOutPhoneNumbers,
    setOptInStatus,
  } = useWhatsappNotifications();
  const {formatPhoneNumber} = usePhoneNumberFormatter();
  const whatsappPhoneNumbers = useMemo(
    () =>
      optedInPhoneNumbers
        .map(p => formatPhoneNumber(`+${p.phoneNumber}`))
        ?.join(', '),
    [optedInPhoneNumbers, formatPhoneNumber],
  );
  const descriptionClass = 'text-sm text-foreground/50';
  const getMetaPresetConnectionQuery = useQuery(GET_META_PRESET_CONNECTION, {
    variables: {
      filter: {
        isDefault: true,
      },
      first: 1,
    },
  });
  const countPlatformUsersQuery = useQuery(COUNT_PLATFORM_USERS, {
    variables: {
      filter: {
        platform: FlowPlatform.Meta,
        userId: user?.id,
      },
    },
    skip: !user?.id,
  });
  const defaultMetaPreset = useMemo(
    () => getMetaPresetConnectionQuery.data?.connection?.edges[0]?.node,
    [getMetaPresetConnectionQuery.data],
  );
  const metaUsersCount = countPlatformUsersQuery.data?.countPlatformUsers;

  const onWhatsAppEnable = useCallback(() => {
    if (!optedInPhoneNumbers.length && optedOutPhoneNumbers.length) {
      setOptInStatus(true);
    } else {
      navigate('enable-whatsapp-notifications');
    }
  }, [optedInPhoneNumbers, optedOutPhoneNumbers, setOptInStatus, navigate]);

  return (
    <>
      <ModalOutlet basePath="/profile" subPath={subpath} />
      <div className="mx-auto flex w-full max-w-[42rem] flex-col px-5">
        <ProfileHeader />
        <div className="flex flex-col gap-4 pt-5">
          <ProfileSection title={<Trans>Profile</Trans>} className="gap-6 pb-2">
            <div className="flex w-full flex-col gap-6">
              {user ? (
                <div className="flex w-full items-center gap-[0.625rem]">
                  <div className="relative flex shrink-0">
                    <Avatar
                      src={user.avatar ?? undefined}
                      className="h-7 w-7 shrink-0"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-full border-foreground/15 [border-width:1.5px]" />
                  </div>
                  <div className="text-base">{getUserName(user)}</div>
                </div>
              ) : null}
              <div className="flex w-full flex-col gap-1">
                <div className="text-base font-medium text-foreground">
                  <Trans>Connected Accounts</Trans>
                </div>
                <div className={descriptionClass}>
                  <Trans>
                    Additional accounts allows you using more combinations in Ad
                    Presets
                  </Trans>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col">
              <Button
                aria-label={_(msg`Meta Users`)}
                data-amp-track-label="Meta Users"
                as={RouterLink}
                to={'meta-users'}
                disableAnimation
                disableRipple
                className="flex h-11 w-auto min-w-0 max-w-full flex-row justify-start gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50"
                onPress={() => {}}>
                <div className="flex h-7 w-7 shrink-0 items-center justify-center rounded-[7px] bg-[#002747]">
                  <img
                    src={FacebookLogo}
                    className="max-h-[1.125rem] max-w-[1.125rem]"
                  />
                </div>
                <div className="relative flex h-full max-w-[calc(100%-2.5rem)] grow items-center justify-between gap-2">
                  <div className="text-foreground">
                    <Trans>Facebook</Trans>
                  </div>
                  <div className="flex shrink items-center truncate">
                    {Number.isFinite(metaUsersCount) ? (
                      <div className="flex shrink truncate text-foreground/50">
                        {metaUsersCount}
                      </div>
                    ) : countPlatformUsersQuery.loading ? (
                      <PortalSpinner size="sm" />
                    ) : null}
                    <ChevronLeftIcon className="relative -right-2 h-6 w-6 shrink-0 rotate-180 text-foreground/25" />
                  </div>
                </div>
              </Button>
            </div>
          </ProfileSection>
          <ProfileSection
            title={<Trans>Default Ad Presets</Trans>}
            className="pb-2">
            <div className="flex w-full flex-col gap-4">
              <div className={descriptionClass}>
                <Trans>
                  We collect all settings from your active Meta campaigns to
                  optimize their performance.
                </Trans>
              </div>
              <div className="flex w-full flex-col">
                <Button
                  aria-label={_(msg`Meta Ads Presets`)}
                  data-amp-track-label="Meta Ads Presets"
                  as={RouterLink}
                  to={'meta-presets'}
                  disableAnimation
                  disableRipple
                  className="flex h-11 w-auto min-w-0 max-w-full flex-row justify-start gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50"
                  onPress={() => {}}>
                  <div className="flex h-7 w-7 shrink-0 items-center justify-center rounded-[7px] bg-[#002747]">
                    <img
                      src={getIntegrationIcon('meta_ads', 'small')}
                      className="max-h-[1.125rem] max-w-[1.125rem]"
                    />
                  </div>
                  <div className="relative flex h-full max-w-[calc(100%-2.5rem)] grow items-center justify-between gap-2">
                    <div className="text-foreground">
                      <Trans>Meta Ads</Trans>
                    </div>
                    <div className="flex shrink items-center truncate">
                      {defaultMetaPreset ? (
                        <div className="flex shrink truncate text-foreground/50">
                          {defaultMetaPreset.name || defaultMetaPreset.pageName}
                        </div>
                      ) : getMetaPresetConnectionQuery.loading ? (
                        <PortalSpinner size="sm" />
                      ) : null}
                      <ChevronLeftIcon className="relative -right-2 h-6 w-6 shrink-0 rotate-180 text-foreground/25" />
                    </div>
                    <div className="absolute bottom-0 left-0 h-[1px] w-[100dvw] bg-foreground/10" />
                  </div>
                </Button>
                <Button
                  aria-label={_(msg`TikTok Presets`)}
                  data-amp-track-label="TikTok Presets"
                  disableAnimation
                  disableRipple
                  className="flex h-11 w-auto min-w-0 max-w-full flex-row justify-start gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50">
                  <div
                    style={{
                      background:
                        'linear-gradient(129deg, #004543 9.63%, #4B010F 91.78%)',
                    }}
                    className="flex h-7 w-7 shrink-0 items-center justify-center rounded-[7px]">
                    <img
                      src={getIntegrationIcon('tiktok', 'small')}
                      className="max-h-[1.125rem] max-w-[1.125rem]"
                    />
                  </div>
                  <div className="relative flex h-full grow items-center justify-between gap-2">
                    <div className="text-foreground">
                      <Trans>TikTok</Trans>
                    </div>
                    <div className="flex items-center">
                      <div>
                        <Trans>Coming soon</Trans>
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </ProfileSection>
          <ProfileSection title={<Trans>Notifications</Trans>} className="pb-2">
            <div className="flex w-full flex-col gap-4">
              <div className={descriptionClass}>
                <Trans>
                  Get notified when your Ads exit the learning phase and are
                  ready to display performance metrics.
                </Trans>
              </div>
              <div className="flex w-full flex-col">
                <div className="flex h-11 w-auto min-w-0 flex-row items-center gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50">
                  <div className="flex h-7 w-7 shrink-0 items-center justify-center rounded-[7px] bg-[#003715]">
                    <img
                      src={WhatsAppLogo}
                      className="max-h-[1.125rem] max-w-[1.125rem]"
                    />
                  </div>
                  <div className="relative flex h-full grow items-center justify-between gap-2">
                    <div className="text-foreground">
                      <Trans>WhatsApp</Trans>
                    </div>
                    {whatsappPhoneNumbers ? (
                      <div className="flex items-center">
                        <div
                          className={twMerge(descriptionClass, 'text-right')}>
                          {whatsappPhoneNumbers}
                        </div>
                        <Dropdown
                          classNames={{
                            content: 'border-transparent rounded-2xl',
                          }}>
                          <DropdownTrigger>
                            <Button
                              aria-label={_(msg`WhatsApp Settings`)}
                              data-amp-track-label="WhatsApp Settings"
                              disableRipple
                              disableAnimation
                              isIconOnly
                              className="relative -right-2 h-auto min-h-0 w-auto min-w-0 bg-transparent">
                              <DotsVertical className="h-6 w-6 text-foreground/25" />
                            </Button>
                          </DropdownTrigger>

                          <DropdownMenu aria-label="Static Actions">
                            <DropdownItem
                              aria-label={_(msg`Change WhatsApp Number`)}
                              data-amp-track-label="Change WhatsApp Number"
                              key="change_number"
                              as={RouterLink}
                              // @ts-expect-error typing issue
                              to={'enable-whatsapp-notifications'}
                              className="flex flex-row gap-3 p-2"
                              startContent={
                                <UsersIcon className="h-5 w-5 shrink-0" />
                              }>
                              <Trans>Change Number</Trans>
                            </DropdownItem>
                            <DropdownItem
                              aria-label={_(msg`Disconnect WhatsApp`)}
                              data-amp-track-label="Disconnect WhatsApp"
                              key="disconnect"
                              as={RouterLink}
                              // @ts-expect-error typing issue
                              to={'disable-whatsapp-notifications'}
                              className="flex flex-row gap-3 p-2 text-[#e34800]"
                              startContent={
                                <LinkBrokenIcon className="h-5 w-5 shrink-0" />
                              }>
                              <Trans>Disconnect</Trans>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    ) : phoneNumbersLoading ? (
                      <PortalSpinner size="sm" />
                    ) : (
                      <TrackButton
                        aria-label={_(msg`Enable WhatsApp notifications`)}
                        data-amp-track-label="Enable WhatsApp notifications"
                        onPress={onWhatsAppEnable}
                        className="h-auto w-auto min-w-0 rounded-full bg-foreground/15 px-4 py-2 font-medium text-foreground">
                        <Trans>Enable</Trans>
                      </TrackButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ProfileSection>
          <ProfileSection className="py-0">
            <div className="flex w-full flex-col">
              <Button
                aria-label={_(msg`Terms of Service`)}
                data-amp-track-label="Terms of Service"
                as={RouterLink}
                to={'/legal/terms'}
                disableAnimation
                disableRipple
                className="flex h-11 w-auto min-w-0 max-w-full flex-row justify-start gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50">
                <div className="relative flex h-full grow items-center justify-between gap-2">
                  <div className="text-foreground">
                    <Trans>Terms of Service</Trans>
                  </div>
                  <div className="flex items-center">
                    <ChevronLeftIcon className="relative -right-2 h-6 w-6 rotate-180 text-foreground/25" />
                  </div>
                  <div className="absolute bottom-0 left-0 h-[1px] w-[100dvw] bg-foreground/10" />
                </div>
              </Button>
              <Button
                aria-label={_(msg`Privacy Policy`)}
                data-amp-track-label="Privacy Policy"
                as={RouterLink}
                to={'/legal/privacy'}
                disableAnimation
                disableRipple
                className="flex h-11 w-auto min-w-0 max-w-full flex-row justify-start gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50">
                <div className="relative flex h-full grow items-center justify-between gap-2">
                  <div className="text-foreground">
                    <Trans>Privacy Policy</Trans>
                  </div>
                  <div className="flex items-center">
                    <ChevronLeftIcon className="relative -right-2 h-6 w-6 rotate-180 text-foreground/25" />
                  </div>
                </div>
              </Button>
            </div>
          </ProfileSection>
          <ProfileSection className="py-0">
            <div className="flex w-full flex-col">
              <Button
                aria-label={_(msg`Log Out`)}
                data-amp-track-label="Log Out"
                disableAnimation
                disableRipple
                className="flex h-11 w-auto min-w-0 max-w-full flex-row justify-start gap-3 overflow-visible bg-transparent p-0 text-base text-foreground/50"
                onPress={() => {
                  logout(apolloClient);
                }}>
                <div className="relative flex h-full grow items-center justify-between gap-2">
                  <div className="text-foreground">
                    <Trans>Log Out</Trans>
                  </div>
                </div>
              </Button>
            </div>
          </ProfileSection>
          <Button
            aria-label={_(msg`Delete Account`)}
            data-amp-track-label="Delete Account"
            as={RouterLink}
            to={'delete-account'}
            disableRipple
            disableAnimation
            className="mb-10 mt-2 flex h-auto min-h-0 w-full min-w-0 bg-transparent p-0 text-xs text-[#e34800]">
            <Trans>Delete Account</Trans>
          </Button>
        </div>
      </div>
    </>
  );
});

Profile.displayName = 'Profile';

export default Profile;
