import {SET_DEFAULT_META_PRESET} from '@/common/mutations';
import MetaPresets from '@/routes/components/MetaPresets';
import {useMutation} from '@apollo/client';
import {Trans} from '@lingui/macro';
import {memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

const SelectDefaultMetaPreset = memo(() => {
  const navigate = useNavigate();
  const [setDefaultMetaPreset, {loading}] = useMutation(
    SET_DEFAULT_META_PRESET,
    {
      refetchQueries: ['GetMetaPresetConnection'],
      onCompleted: () => {
        navigate('..');
      },
    },
  );
  const onSave = useCallback(
    (presetId: string | null) => {
      if (!presetId) {
        return;
      }

      setDefaultMetaPreset({
        variables: {
          id: presetId,
        },
      });
    },
    [setDefaultMetaPreset],
  );

  return (
    <MetaPresets
      title={<Trans>Select Default Preset</Trans>}
      basePath="/profile/meta-presets"
      isSaving={loading}
      onSave={onSave}
    />
  );
});

SelectDefaultMetaPreset.displayName = 'SelectDefaultMetaPreset';

export default SelectDefaultMetaPreset;
