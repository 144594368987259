'use client';

import {Trans} from '@lingui/macro';
import {Card, CardBody, CardFooter, CardHeader} from '@nextui-org/card';
import {Snippet} from '@nextui-org/snippet';
import {FC, useEffect, useMemo} from 'react';
import {isRouteErrorResponse, useRouteError} from 'react-router-dom';
import {captureException} from '@sentry/react';

const ErrorPage: FC = () => {
  const error = useRouteError();
  const message = useMemo(() => {
    if (isRouteErrorResponse(error)) {
      return `${error.status} ${error.statusText}`;
    } else if (error instanceof Error) {
      return error.message;
    } else if (typeof error === 'string') {
      return error;
    } else {
      return <Trans>Unknown error</Trans>;
    }
  }, [error]);

  useEffect(() => {
    console.error(error);
    captureException(error, {level: 'error'});
  }, [error]);

  return (
    <Card className="mx-auto mt-20 max-w-xl">
      <CardHeader className="flex justify-center text-large font-bold">
        <Trans>Oops!</Trans>
      </CardHeader>
      <CardBody>
        <p>
          <Trans>Sorry, an error has occurred.</Trans>
        </p>
      </CardBody>
      <CardFooter>
        <Snippet
          className="w-full"
          classNames={{pre: 'whitespace-break-spaces'}}
          hideSymbol>
          {message}
        </Snippet>
      </CardFooter>
    </Card>
  );
};

export default ErrorPage;
