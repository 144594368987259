'use client';

import './sentry';
import {memo, useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {i18n} from '@lingui/core';
import router from '@/router';
import {Providers} from '@/providers';
import {dynamicActivate} from './i18n';
import {LinguiProvider} from './components/providers';

const lang = 'en';

const App = memo(() => {
  useEffect(() => {
    dynamicActivate(lang);
  }, []);

  return (
    <LinguiProvider i18n={i18n}>
      <Providers>
        <RouterProvider router={router} />
      </Providers>
    </LinguiProvider>
  );
});

App.displayName = 'App';

export default App;
