import {memo, useState} from 'react';
import flowBanner from '@/assets/flow-banner.png';
import {Trans} from '@lingui/macro';
import CloseButton from '@/components/Modal';
import {AnimatePresence, motion} from 'framer-motion';
import {useAppContext} from '@/hooks';

const LS_KEY = 'flow-banner-closed';

const storeClosed = () => {
  localStorage.setItem(LS_KEY, 'true');
};

const isClosed = () => {
  return localStorage.getItem(LS_KEY) === 'true';
};

interface Props {
  isShown?: boolean;
}

const Banner = memo(({isShown = true}: Props) => {
  const {isAuthenticated} = useAppContext();
  const [closed, setClosed] = useState(isAuthenticated ? isClosed : false);
  const show = isShown && !closed;

  const onPress = () => {
    storeClosed();
    setClosed(true);
  };

  return (
    <AnimatePresence mode="wait">
      {show ? (
        <motion.div
          initial={{opacity: 0, scale: 0.9}}
          animate={{opacity: 1, scale: 1}}
          exit={{opacity: 0, scale: 0.9}}
          className="relative flex w-full flex-col overflow-hidden rounded-2xl bg-[#281247]">
          <CloseButton
            className="absolute right-4 top-4 z-10 h-6 w-6 bg-transparent data-[hover=true]:bg-transparent"
            onPress={onPress}
          />
          <div className="absolute bottom-[12rem] left-[0] z-[1] h-[20rem] w-full bg-[#F92578] blur-[5rem]" />
          <div className="z-[2] flex w-full flex-col items-center gap-1">
            <img
              src={flowBanner}
              alt="Get Sales Now"
              className="h-[6.5625rem] w-[8.5rem]"
            />
            <div className="flex w-full flex-col gap-2 p-4">
              <div className="text-2xl font-semibold">
                <Trans>Get Sales Now</Trans>
              </div>
              <div className="text-sm text-foreground/50">
                <Trans>
                  Effortlessly craft a fruitful ad campaign with AI. Get started
                  below ↘︎
                </Trans>
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
});

Banner.displayName = 'Banner';

export default Banner;
