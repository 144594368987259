'use client';

import {SVGProps} from 'react';

export default function EyeSolidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.13833 4 6.80535 5.26472 5.07675 6.70743C3.3505 8.14818 2.16697 9.81429 1.57422 10.7528L1.55014 10.7908C1.43252 10.976 1.27981 11.2164 1.2026 11.5532C1.14027 11.8251 1.14027 12.1749 1.2026 12.4468C1.2798 12.7836 1.43252 13.024 1.55014 13.2092L1.57423 13.2472C2.16697 14.1857 3.3505 15.8518 5.07675 17.2926C6.80535 18.7353 9.13833 20 12 20C14.8617 20 17.1947 18.7353 18.9233 17.2926C20.6495 15.8518 21.833 14.1857 22.4258 13.2472L22.4499 13.2092C22.5675 13.024 22.7202 12.7837 22.7974 12.4468C22.8597 12.1749 22.8597 11.8251 22.7974 11.5532C22.7202 11.2163 22.5675 10.976 22.4499 10.7908L22.4258 10.7528C21.833 9.81429 20.6495 8.14818 18.9233 6.70743C17.1947 5.26472 14.8617 4 12 4ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
