import {FlowRunErrorStep} from '@/__generated__/graphql';
import {CLEAR_FLOW_RUN} from '@/common/mutations';
import {GET_FLOW_RUN} from '@/common/queries';
import VideoRecorderOffIcon from '@/components/icons/VideoRecorderOffIcon';
import {Modal, ModalBody, ModalContent, ModalHeader} from '@/components/Modal';
import {useFlowContext} from '@/hooks';
import {useMutation} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {memo, useCallback} from 'react';

const VideogenError = memo(() => {
  const {_} = useLingui();
  const {runId, errorStep, setCurrentStep} = useFlowContext();
  const [clearFlowRun, {loading: clearFlowRunLoading}] = useMutation(
    CLEAR_FLOW_RUN,
    {
      update(cache, {data}) {
        if (!data) {
          return;
        }

        cache.writeQuery({
          query: GET_FLOW_RUN,
          variables: {id: data.clearFlowRun.id},
          data: {getFlowRun: data.clearFlowRun},
        });
      },
      onCompleted: data => {
        if (data.clearFlowRun) {
          setCurrentStep('upload_photo');
        }
      },
    },
  );

  const onClearRun = useCallback(async () => {
    if (!runId || clearFlowRunLoading) {
      return;
    }

    await clearFlowRun({
      variables: {
        id: runId,
      },
    });
  }, [runId, clearFlowRunLoading, clearFlowRun]);

  return (
    <Modal
      isOpen={errorStep === FlowRunErrorStep.GeneratingVideos}
      hideCloseButton
      onOpenChange={() => null}>
      <ModalContent>
        <ModalHeader className="p-4">
          <div className="flex w-full text-base font-medium">
            <Trans>Assets Generation Failed</Trans>
          </div>
        </ModalHeader>
        <ModalBody className="flex-col items-center justify-center gap-8 p-4">
          <div className="flex w-full flex-col items-center gap-4">
            <VideoRecorderOffIcon className="flex h-11 w-11 shrink-0 text-foreground/60" />
            <div className="text-center text-sm text-foreground/60">
              <Trans>
                Something went wrong. Please return to asset creation and try
                again.
              </Trans>
            </div>
          </div>
          <div className="flex w-full flex-col gap-3">
            <Button
              aria-label={_(msg`Edit assets`)}
              data-amp-track-label="Edit assets"
              className="w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
              onPress={onClearRun}>
              <Trans>Back to Asset Creation</Trans>
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

VideogenError.displayName = 'VideogenError';

export default VideogenError;
