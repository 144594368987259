import {memo, useMemo} from 'react';
import BackButton from '@/components/buttons/BackButton';
import {Navigate, useParams} from 'react-router-dom';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import usePresignedLink from '@/hooks/usePresignedLink';
import {useLingui} from '@lingui/react';
import {msg, Trans} from '@lingui/macro';
import {AdStatusCategory, FlowPlatform} from '@/__generated__/graphql';
import {getIntegrationIcon} from '@/common/integrations';
import {IntegrationType} from '@/common/types';
import {gql} from '@/__generated__';
import {twMerge} from 'tailwind-merge';
import {Switch, Textarea} from '@nextui-org/react';
import NextUIInput from '@/components/inputs/NextUIInput';
import {Metric} from '@/components/LiveMetric/types';
import LiveMetric from '@/components/LiveMetric';
import {isSafari} from '@/utils';
import MusicIcon from '@/components/icons/MusicIcon';
import PortalSpinner from '@/components/PortalSpinner';
import dayjs from 'dayjs';
import {GET_FLOW_RUN_ASSET_AD_INFO} from '@/common/queries';

const GET_ASSET_DETAILS = gql(`
  query GetAssetDetails($id: ID!) {
    getAsset(id: $id) {
      ...AssetFragment
      videoFile {
        ...FileFragment
      }
      grossProfitMetric {
        ...SingleNumberMetricFragment
      }
      totalSalesMetric {
        ...SingleNumberMetricFragment
      }
      totalPurchasesMetric {
        ...SingleNumberMetricFragment
      }
      impressionsMetric {
        ...SingleNumberMetricFragment
      }
      cacMetric {
        ...SingleNumberMetricFragment
      }
    }
  }
`);

const GET_SETTINGS_CONNECTION = gql(`
  query GetSettingConnection($filter: JSON, $order: JSON, $first: Int, $after: String) {
    getSettingConnection(filter: $filter, order: $order, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          key
          value
        }
      }
    }
  }
`);

const SET_AD_PAUSE_STATE = gql(`
  mutation SetAdPauseState($id: ID!, $isPaused: Boolean!) {
    setAdPauseState(id: $id, isPaused: $isPaused) {
      id
    }
  }
`);

const AdDetails = memo(() => {
  const {_} = useLingui();
  const {id} = useParams<{id: string}>();
  const apolloClient = useApolloClient();
  const assetData = useQuery(GET_ASSET_DETAILS, {
    variables: {
      id: id!,
    },
    skip: !id,
  });
  const adInfoQuery = useQuery(GET_FLOW_RUN_ASSET_AD_INFO, {
    variables: {
      id: id!,
    },
    skip: !id,
    fetchPolicy: 'cache-first',
  });
  const adInfo = adInfoQuery.data?.getFlowRunAssetAdInfo;
  const asset = assetData?.data?.getAsset;
  const settingsConnetion = useQuery(GET_SETTINGS_CONNECTION, {
    variables: {
      filter: {
        flowRunId: asset?.flowRunId,
        key: {
          $in: ['product_link'],
        },
      },
      first: 1,
    },
    skip: !id || !asset?.flowRunId,
  });
  const productLink =
    settingsConnetion?.data?.getSettingConnection?.edges?.find(
      ({node}) => node.key === 'product_link',
    )?.node.value;
  const [setAdPauseState, {loading: setAdPauseStateLoading}] = useMutation(
    SET_AD_PAUSE_STATE,
    {
      refetchQueries: [
        {
          query: GET_FLOW_RUN_ASSET_AD_INFO,
          variables: {
            id: id!,
          },
        },
      ],
    },
  );
  const thumbnailURL = usePresignedLink(asset?.videoFile.thumbnailName);
  const videoURL = usePresignedLink(asset?.videoFile.name);
  const platformLabel = useMemo(() => {
    switch (asset?.platform) {
      case FlowPlatform.Meta:
        return _(msg`Meta Ads`);
      case FlowPlatform.Tiktok:
        return _(msg`TikTok`);
      default:
        return;
    }
  }, [asset?.platform, _]);
  const platformImgSrc = useMemo(() => {
    switch (asset?.platform) {
      case FlowPlatform.Meta:
        return getIntegrationIcon(IntegrationType.metaAds, 'small');
      default:
        return;
    }
  }, [asset?.platform]);
  const subtitle = useMemo(() => {
    switch (asset?.platform) {
      case FlowPlatform.Meta:
        return _(msg`Post in Instagram and Facebook`);
      default:
        return;
    }
  }, [asset?.platform, _]);

  const [metrics, dateRange] = useMemo(() => {
    if (!asset) {
      return [[], undefined];
    }

    const metrics = [
      {
        type: 'single_number',
        title: _(msg`Total Purchases`),
        value: asset.totalPurchasesMetric.value,
        valueUnit: 'pcs',
        trend: asset.totalPurchasesMetric.trend,
        priority: 3,
      },
      {
        type: 'single_number',
        title: _(msg`Impressions`),
        value: asset.impressionsMetric.value,
        trend: asset.impressionsMetric.trend,
        priority: 4,
      },
      asset.grossProfitMetric
        ? {
            type: 'single_number',
            title: _(msg`Gross Profit`),
            value: asset.grossProfitMetric.value,
            valueFormat: {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits:
                asset.grossProfitMetric.value < 100 ? 2 : 0,
            },
            trend: asset.grossProfitMetric.trend,
            priority: 1,
          }
        : {
            type: 'connect',
            title: _(msg`Gross Profit`),
            value: 'shopify',
            priority: 5,
          },
      asset.totalSalesMetric
        ? {
            type: 'single_number',
            title: _(msg`Total Sales`),
            value: asset.totalSalesMetric.value,
            valueFormat: {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: asset.totalSalesMetric.value < 100 ? 2 : 0,
            },
            trend: asset.totalSalesMetric.trend,
            priority: 2,
          }
        : {
            type: 'connect',
            title: _(msg`Total Sales`),
            value: 'shopify',
            priority: 6,
          },
      {
        type: 'single_number',
        title: _(msg`Customer Acq. Cost`),
        value: asset.cacMetric.value,
        valueFormat: {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: asset.cacMetric.value < 100 ? 2 : 0,
        },
        trend: asset.cacMetric.trend,
        priority: 7,
        isInvertedTrend: true,
      },
      {
        type: 'coming_soon',
        value: 'tiktok',
        priority: 8,
      },
    ].sort((a, b) => a.priority - b.priority) as Metric[];
    let startDate = undefined;
    let endDate = undefined;
    let dateRange = undefined;

    for (const metric of [
      asset.cacMetric,
      asset.totalSalesMetric,
      asset.grossProfitMetric,
      asset.impressionsMetric,
      asset.totalPurchasesMetric,
    ]) {
      if (typeof metric === 'string') {
        continue;
      }

      if (metric?.startDate) {
        if (!startDate || dayjs(metric.startDate).isBefore(startDate)) {
          startDate = dayjs(metric.startDate);
        }
      }
      if (metric?.endDate) {
        if (!endDate || dayjs(metric.endDate).isAfter(endDate)) {
          endDate = dayjs(metric.endDate);
        }
      }
    }

    if (startDate && endDate) {
      const dateDiffDays = endDate.diff(startDate, 'days');

      if (dateDiffDays > 1) {
        dateRange = _(msg`Last ${dateDiffDays} days`);
      } else {
        dateRange = _(msg`Last ${endDate.diff(startDate, 'hours')} hours`);
      }
    }

    return [metrics, dateRange];
  }, [asset, _]);

  const adDate = useMemo(() => {
    if (!adInfo?.startTime || !adInfo?.endTime) {
      return;
    }

    const now = dayjs();
    const startDate = dayjs(adInfo.startTime);
    const endDate = dayjs(adInfo.endTime);

    const currentYear = now.year() === startDate.year();
    const sameYear = startDate.year() === endDate.year();
    const sameMonth = startDate.month() === endDate.month();

    return (
      startDate
        .format(`D ${sameMonth ? '' : 'MMM'}${sameYear ? '' : " 'YY"}`)
        .trim() +
      ' - ' +
      endDate.format(`D MMM${currentYear ? '' : " 'YY"}`).trim()
    );
  }, [adInfo]);

  if (!id) {
    return <Navigate to="/" />;
  }

  let musicParts = asset
    ? [asset.musicTitle, asset.musicAuthor].filter(Boolean)
    : [];

  if (musicParts.length === 2) {
    musicParts = [musicParts[0], 'by', musicParts[1]];
  }

  const statusBgColor = (() => {
    switch (adInfo?.statusCategory) {
      case AdStatusCategory.Positive:
        return 'bg-success';
      case AdStatusCategory.Neutral:
        return 'bg-default-500';
      case AdStatusCategory.Warning:
        return 'bg-[#FF8000]';
      case AdStatusCategory.Negative:
        return 'bg-[#F00]';
    }
  })();

  return (
    <div className="flex w-full max-w-2xl grow flex-col">
      <div className="flex w-full gap-2 truncate px-4 py-3">
        <BackButton isIconOnly className="shrink-0" />
        <h1 className="flex grow justify-center truncate text-center text-base font-semibold text-foreground">
          {asset?.headline ?? ''}
        </h1>
        <div className="w-6" />
      </div>
      {asset ? (
        <div className="flex w-full flex-col gap-10 p-5">
          <div className="flex w-full flex-col gap-5">
            <div className="flex w-full items-center gap-4">
              {thumbnailURL ? (
                <img
                  src={thumbnailURL}
                  alt="thumbnail"
                  className="h-11 w-11 shrink-0 rounded-lg object-cover"
                />
              ) : (
                <div className="h-11 w-11 rounded-lg" />
              )}
              <div className="flex grow flex-col gap-1">
                <div className="flex grow gap-4">
                  <div className="flex items-center gap-1">
                    <img
                      src={platformImgSrc}
                      alt={platformLabel ?? 'platform icon'}
                      className="h-4 w-4 shrink-0 object-contain object-center"
                    />
                    <div>{platformLabel}</div>
                  </div>
                  {adDate ? <div>{adDate}</div> : null}
                </div>
                <div className="flex grow flex-col gap-4 text-sm text-foreground/50">
                  {subtitle}
                </div>
              </div>
            </div>
            {adInfo ? (
              <div className="flex w-full justify-between gap-2">
                <div className="flex items-center gap-2">
                  <span
                    className={twMerge(
                      'h-[0.375rem] w-[0.375rem] shrink-0 rounded-full',
                      statusBgColor,
                    )}
                  />
                  {adInfo?.status}
                </div>
                <Switch
                  aria-label={_(msg`Toggle state`)}
                  data-amp-track-label="Toggle state"
                  classNames={{
                    wrapper:
                      'p-[0.125rem] h-8 w-[3.25rem] mr-0 group-data-[selected=true]:bg-[#34C759]',
                    thumb: 'min-h-7 min-w-7',
                  }}
                  isDisabled={
                    !adInfo?.canChangePauseState || setAdPauseStateLoading
                  }
                  isSelected={!adInfo?.isPaused}
                  onChange={e => {
                    if (!adInfo) {
                      return;
                    }

                    const prevIsPaused = adInfo.isPaused;
                    const isPaused = !e.target.checked;

                    apolloClient.writeQuery({
                      query: GET_FLOW_RUN_ASSET_AD_INFO,
                      variables: {
                        id: id!,
                      },
                      data: {
                        getFlowRunAssetAdInfo: {
                          ...adInfo,
                          isPaused,
                        },
                      },
                    });

                    setAdPauseState({
                      variables: {
                        id: id!,
                        isPaused,
                      },
                      onError: () => {
                        apolloClient.writeQuery({
                          query: GET_FLOW_RUN_ASSET_AD_INFO,
                          variables: {
                            id: id!,
                          },
                          data: {
                            getFlowRunAssetAdInfo: {
                              ...adInfo,
                              isPaused: prevIsPaused,
                            },
                          },
                        });
                      },
                    });
                  }}
                />
              </div>
            ) : null}
            <NextUIInput
              label={_(msg`Product Link`)}
              placeholder={''}
              classNames={{
                label: '!text-foreground/50 font-normal',
                input: 'placeholder:!text-foreground/40',
              }}
              isReadOnly={true}
              value={productLink ?? ''}
            />
          </div>
          <div className="flex w-full flex-col gap-6">
            <div className="flex flex-col gap-1">
              <h2 className="text-xl font-semibold text-foreground">
                <Trans>Ad Performance</Trans>
              </h2>
              {dateRange && (
                <h5 className="text-sm text-foreground/50">{dateRange}</h5>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2">
              {metrics.map((metric, index) => {
                let isCompact = true;

                switch (metric.type) {
                  case 'connect_whatsapp':
                    isCompact = !metrics.some(m => m.type === 'area_chart');
                    break;
                }
                return (
                  <LiveMetric
                    key={index}
                    className="min-h-[7.25rem] rounded-2xl bg-foreground/15 p-4"
                    isCompact={isCompact}
                    metric={metric}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex w-full flex-col gap-6">
            <h2 className="text-xl font-semibold text-foreground">
              <Trans>Asset</Trans>
            </h2>
            <div className="relative flex w-full justify-center">
              <video
                className="mx-auto h-auto max-h-[min(24rem,50dvh)] rounded-lg"
                preload="metadata"
                controls
                loop
                playsInline
                src={
                  videoURL
                    ? isSafari
                      ? `${videoURL}#t=0.01`
                      : videoURL
                    : undefined
                }
              />
            </div>
            <div className="flex w-full flex-col gap-4">
              <div className="flex justify-center gap-2 truncate text-sm">
                <MusicIcon className="h-4 w-4 text-foreground/50" />
                {musicParts.map((part, index) => (
                  <span
                    key={index}
                    className={
                      index === 1 ? 'text-foreground/50' : 'text-foreground'
                    }>
                    {part + ' '}
                  </span>
                ))}
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm text-foreground/50">
                  <Trans>Headline</Trans>
                </div>
                <Textarea
                  classNames={{
                    inputWrapper: `relative w-full !shadow-none !opacity-100 px-3 py-2 rounded-lg bg-foreground/10 data-[hover=true]:bg-foreground/10 group-data-[focus=true]:bg-foreground/10`,
                    input: '!text-foreground',
                  }}
                  placeholder={_(msg`Video headline...`)}
                  value={asset.headline ?? ''}
                  minRows={1}
                  maxRows={5}
                  isReadOnly={true}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm text-foreground/50">
                  <Trans>Caption</Trans>
                </div>
                <Textarea
                  classNames={{
                    inputWrapper: `relative w-full !shadow-none !opacity-100 px-3 py-2 rounded-lg bg-foreground/10 data-[hover=true]:bg-foreground/10 group-data-[focus=true]:bg-foreground/10`,
                    input: '!text-foreground',
                  }}
                  placeholder={_(msg`Video caption...`)}
                  value={asset.caption ?? ''}
                  minRows={1}
                  maxRows={5}
                  isReadOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full grow items-center justify-center">
          <PortalSpinner />
        </div>
      )}
    </div>
  );
});

AdDetails.displayName = 'AdDetails';

export default AdDetails;
