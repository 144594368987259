'use client';

import {memo, useState, PropsWithChildren, useMemo, useCallback} from 'react';
import {
  API_URL,
  LS_AUTH_TOKEN_KEY,
  LS_PHOTO_UPLOAD_PREFIX,
  LS_REFRESH_TOKEN_KEY,
  LS_USER_COUPON_TYPE,
} from '@/config';
import {AppProviderContext} from '@/contexts';
import {Toast, TokenResponse} from '@/common/types';
import {ApolloClient} from '@apollo/client';
import axios from 'axios';
import {useAnalyticsContext} from '@/hooks';
import {useAnalytics} from '@/common/analytics';

let initialToken: string | undefined;

if (typeof localStorage !== 'undefined') {
  initialToken = localStorage.getItem(LS_AUTH_TOKEN_KEY) ?? undefined;
}

export const AppProvider = memo(({children, ...props}: PropsWithChildren) => {
  const instrumentTracker = useAnalyticsContext();
  const {reportLogout} = useAnalytics();

  useMemo(() => {
    axios.defaults.baseURL = API_URL;
    axios.interceptors.request.use(async config => {
      const trackerHeaders = (await instrumentTracker?.buildHeaders()) ?? {};

      for (const [key, value] of Object.entries(trackerHeaders)) {
        config.headers[key] = value;
      }

      return config;
    });
  }, [instrumentTracker]);

  const [toasts, setToasts] = useState<Toast[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(initialToken));

  const login = useCallback(
    ({
      access_token,
      refresh_token,
    }: Pick<TokenResponse, 'access_token' | 'refresh_token'>) => {
      localStorage.removeItem(LS_USER_COUPON_TYPE);
      localStorage.setItem(LS_AUTH_TOKEN_KEY, access_token);

      if (refresh_token) {
        localStorage.setItem(LS_REFRESH_TOKEN_KEY, refresh_token);
      }

      setIsAuthenticated(true);
    },
    [],
  );

  const logout = useCallback((apolloClient: ApolloClient<any> | undefined) => {
    localStorage.removeItem(LS_AUTH_TOKEN_KEY);
    localStorage.removeItem(LS_REFRESH_TOKEN_KEY);
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(LS_PHOTO_UPLOAD_PREFIX)) {
        localStorage.removeItem(key);
      }
    });
    setIsAuthenticated(false);
    reportLogout();
    instrumentTracker.setUserId().then(() => {
      // stop to avoid "Store reset while query was in flight"
      apolloClient?.stop();
      // resetStore instead of clearStore to refetch active queries
      apolloClient?.resetStore();
    });
  }, []);

  const appState = useMemo(
    () => ({
      toasts,
      setToasts,
      login,
      logout,
      isAuthenticated,
    }),
    [toasts, login, logout, isAuthenticated],
  );

  return (
    <AppProviderContext.Provider {...props} value={appState}>
      {children}
    </AppProviderContext.Provider>
  );
});

AppProvider.displayName = 'AppProvider';
