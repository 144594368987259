import {msg, Trans} from '@lingui/macro';
import {memo, useMemo} from 'react';
import {Metric} from '@/components/LiveMetric/types';
import LiveMetric from '@/components/LiveMetric';
import {useQuery} from '@apollo/client';
import {gql} from '@/__generated__';
import dayjs from 'dayjs';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/react';
import {Link as RouterLink} from 'react-router-dom';
import Announcement01SolidIcon from '@/components/icons/Announcement01SolidIcon';
import {CountdownWidget} from './CountdownWidget';
import {useCountdown} from '@/hooks/useCountdown';

const GET_METRICS = gql(`
  query GetMetrics {
    grossProfitMetric: getGrossProfitMetric {
      value
      trend
      startDate
      endDate
      chartData
    }
    totalSalesMetric: getTotalSalesMetric {
      ...SingleNumberMetricFragment
    }
    totalPurchasesMetric: getTotalPurchasesMetric {
      ...SingleNumberMetricFragment
    }
    impressionsMetric: getImpressionsMetric {
      ...SingleNumberMetricFragment
    }
    cacMetric: getCacMetric {
      ...SingleNumberMetricFragment
    }
  }
`);

const PERFORMANCE_DELAY_MINUTES = 10;

export const PerformanceSection = memo(({startTime}: {startTime: any}) => {
  const {_} = useLingui();
  const endTime = useMemo(
    () => dayjs.utc(startTime).add(PERFORMANCE_DELAY_MINUTES, 'minutes'),
    [startTime],
  );
  const countdown = useCountdown(endTime, 51);
  const {data} = useQuery(GET_METRICS, {
    skip: countdown.asMilliseconds() > 0,
  });
  const [metrics, dateRange] = useMemo(() => {
    if (!data) {
      return [[], undefined];
    }

    const metrics = [
      {
        type: 'single_number',
        title: _(msg`Total Purchases`),
        value: data.totalPurchasesMetric.value,
        valueUnit: 'pcs',
        trend: data.totalPurchasesMetric.trend,
        priority: 3,
      },
      {
        type: 'single_number',
        title: _(msg`Impressions`),
        value: data.impressionsMetric.value,
        trend: data.impressionsMetric.trend,
        priority: 4,
      },
      data.grossProfitMetric
        ? {
            type: 'area_chart',
            title: _(msg`Gross Profit`),
            value: data.grossProfitMetric.value,
            valueFormat: {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: data.grossProfitMetric.value < 100 ? 2 : 0,
            },
            trend: data.grossProfitMetric.trend,
            chartData: data.grossProfitMetric.chartData,
            chartXDataKeys: ['date'],
            chartYDataKeys: ['value'],
            priority: 1,
          }
        : {
            type: 'connect',
            title: _(msg`Gross Profit`),
            value: 'shopify',
            priority: 5,
          },
      data.totalSalesMetric
        ? {
            type: 'single_number',
            title: _(msg`Total Sales`),
            value: data.totalSalesMetric.value,
            valueFormat: {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: data.totalSalesMetric.value < 100 ? 2 : 0,
            },
            trend: data.totalSalesMetric.trend,
            priority: 2,
          }
        : {
            type: 'connect',
            title: _(msg`Total Sales`),
            value: 'shopify',
            priority: 6,
          },
      {
        type: 'single_number',
        title: _(msg`Customer Acq. Cost`),
        value: data.cacMetric.value,
        valueFormat: {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: data.cacMetric.value < 100 ? 2 : 0,
        },
        trend: data.cacMetric.trend,
        priority: 7,
        isInvertedTrend: true,
      },
      {
        type: 'connect_whatsapp',
        priority: 8,
      },
    ].sort((a, b) => a.priority - b.priority) as Metric[];
    let startDate = undefined;
    let endDate = undefined;
    let dateRange = undefined;

    for (const metric of Object.values(data)) {
      if (typeof metric === 'string') {
        continue;
      }

      if (metric?.startDate) {
        if (!startDate || dayjs(metric.startDate).isBefore(startDate)) {
          startDate = dayjs(metric.startDate);
        }
      }
      if (metric?.endDate) {
        if (!endDate || dayjs(metric.endDate).isAfter(endDate)) {
          endDate = dayjs(metric.endDate);
        }
      }
    }

    if (startDate && endDate) {
      const dateDiffDays = endDate.diff(startDate, 'days');

      if (dateDiffDays > 1) {
        dateRange = _(msg`Last ${dateDiffDays} days`);
      } else {
        dateRange = _(msg`Last ${endDate.diff(startDate, 'hours')} hours`);
      }
    }

    return [metrics, dateRange];
  }, [data, _]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-1">
        <h1 className="text-xl font-semibold">
          <Trans>Performance</Trans>
        </h1>
        {dateRange && (
          <h5 className="text-sm text-foreground/50">{dateRange}</h5>
        )}
      </div>
      {countdown.asMilliseconds() > 0 ? (
        <CountdownWidget
          min={countdown.minutes()}
          s={countdown.seconds()}
          ms={countdown.milliseconds()}
        />
      ) : (
        <div className="grid grid-cols-2 gap-2">
          {metrics.map((metric, index) => {
            let isCompact = true;

            switch (metric.type) {
              case 'connect_whatsapp':
                isCompact = !metrics.some(m => m.type === 'area_chart');
                break;
            }

            return (
              <LiveMetric
                key={index}
                className="min-h-[7.25rem] rounded-2xl bg-foreground/15 p-4"
                isCompact={isCompact}
                metric={metric}
              />
            );
          })}
        </div>
      )}
      <Button
        disableRipple
        className="h-[2.75rem] bg-[linear-gradient(90deg,#474EFF,#943FFF)]"
        aria-label={_(msg`Get More Sales`)}
        data-amp-track-label="Get More Sales"
        as={RouterLink}
        to="/create"
        startContent={<Announcement01SolidIcon className="h-4 w-4 shrink-0" />}>
        <Trans>Get More Sales</Trans>
      </Button>
    </div>
  );
});

PerformanceSection.displayName = 'PerformanceSection';
