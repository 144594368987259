'use client';

import {InputProps} from '@nextui-org/input';

export const URL_INPUT_PROPS: InputProps = {
  inputMode: 'url',
  autoCapitalize: 'none',
  autoComplete: 'url',
  autoCorrect: 'off',
  spellCheck: false,
};
