import {memo, useCallback} from 'react';
import {ConnectWhatsapp as ConnectWhatsappMetricType} from '../types';
import {msg, Trans} from '@lingui/macro';
import {twMerge} from 'tailwind-merge';
import WhatsappIcon from '@/components/icons/WhatsapIcon';
import {Button} from '@nextui-org/react';
import {useLingui} from '@lingui/react';
import {useWhatsappNotifications} from '@/hooks/useWhatsappNotifications';
import {useNavigate} from 'react-router-dom';

export const ConnectWhatsappMetric = memo(
  ({
    className,
    isCompact = true,
  }: {
    metric: ConnectWhatsappMetricType;
    className?: string;
    isCompact?: boolean;
  }) => {
    const {_} = useLingui();
    const navigate = useNavigate();
    const {
      isInitialLoading,
      isLoading: phoneNumbersLoading,
      optedInPhoneNumbers,
      optedOutPhoneNumbers,
      setOptInStatus,
    } = useWhatsappNotifications();
    const onWhatsAppEnable = useCallback(() => {
      if (optedInPhoneNumbers.length) {
        return;
      }

      if (!optedInPhoneNumbers.length && optedOutPhoneNumbers.length) {
        setOptInStatus(true);
      } else {
        navigate('enable-whatsapp-notifications');
      }
    }, [optedInPhoneNumbers, optedOutPhoneNumbers, setOptInStatus, navigate]);

    if (optedInPhoneNumbers.length || isInitialLoading) {
      return null;
    }

    return (
      <div
        className={twMerge(
          'flex flex-col items-start justify-center gap-3 text-sm',
          className,
          isCompact ? 'col-span-1' : 'col-span-2',
          'border-[length:thin] border-[rgba(36,211,102,0.24)] bg-[rgba(36,211,102,0.14)]',
        )}>
        {isCompact ? (
          <Button
            aria-label={_(msg`Connect Whatsapp`)}
            data-amp-track-label={`Connect Whatsapp`}
            disableRipple
            radius="sm"
            variant="light"
            className="h-auto flex-col items-start justify-start gap-3 px-0 data-[hover=true]:bg-transparent"
            isDisabled={phoneNumbersLoading}
            onPress={onWhatsAppEnable}>
            <h1 className="text-sm font-medium">
              <Trans>Stay Updated!</Trans>
            </h1>
            <div className="flex gap-3">
              <WhatsappIcon className="h-6 w-6 shrink-0" />
              <span className="whitespace-break-spaces text-start text-xs text-foreground/60">
                <Trans>Tap to enable WhatsApp notifications</Trans> {'\u2192'}
              </span>
            </div>
          </Button>
        ) : (
          <Button
            aria-label={_(msg`Connect Whatsapp`)}
            data-amp-track-label={`Connect Whatsapp`}
            disableRipple
            radius="sm"
            variant="light"
            className="h-auto flex-col items-start justify-start gap-3 px-0 data-[hover=true]:bg-transparent"
            isDisabled={phoneNumbersLoading}
            onPress={onWhatsAppEnable}>
            <h1 className="text-base font-medium">
              <Trans>Stay Updated!</Trans>
            </h1>
            <div className="flex items-center gap-3">
              <WhatsappIcon className="h-7 w-7 shrink-0" />
              <span className="whitespace-break-spaces text-sm text-foreground/60">
                <Trans>Tap to enable WhatsApp notifications</Trans> {'\u2192'}
              </span>
            </div>
          </Button>
        )}
      </div>
    );
  },
);

ConnectWhatsappMetric.displayName = 'ConnectWhatsappMetric';
