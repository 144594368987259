'use client';

import {SVGProps} from 'react';

export default function LinkBrokenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}>
      <g clipPath="url(#clip0_5760_9566)">
        <path
          d="M7.50002 3.33073V1.66406M12.5 16.6641V18.3307M3.33335 7.4974H1.66669M16.6667 12.4974H18.3334M4.0952 4.09257L2.91669 2.91406M15.9048 15.9022L17.0834 17.0807M10 14.7114L8.23225 16.4792C6.93051 17.781 4.81996 17.781 3.51821 16.4792C2.21646 15.1775 2.21646 13.0669 3.51821 11.7652L5.28598 9.9974M14.7141 9.9974L16.4818 8.22963C17.7836 6.92788 17.7836 4.81733 16.4818 3.51558C15.1801 2.21384 13.0695 2.21384 11.7678 3.51558L10 5.28335"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5760_9566">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
