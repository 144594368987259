'use client';

import {SVGProps} from 'react';

export default function Stars02Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}>
      <path
        d="M10.6222 1.59401C10.5232 1.33655 10.2758 1.16666 9.99996 1.16666C9.72411 1.16666 9.47675 1.33655 9.37773 1.59401L8.59198 3.63696C8.39171 4.15765 8.32878 4.3077 8.2427 4.42876C8.15632 4.55023 8.05019 4.65636 7.92872 4.74274C7.80766 4.82882 7.65761 4.89175 7.13692 5.09202L5.09397 5.87777C4.83651 5.97679 4.66663 6.22415 4.66663 6.5C4.66663 6.77585 4.83651 7.0232 5.09397 7.12223L7.13692 7.90798C7.65761 8.10824 7.80766 8.17118 7.92872 8.25726C8.0502 8.34363 8.15632 8.44976 8.2427 8.57123C8.32878 8.69229 8.39171 8.84234 8.59198 9.36304L9.37773 11.406C9.47675 11.6634 9.72411 11.8333 9.99996 11.8333C10.2758 11.8333 10.5232 11.6634 10.6222 11.406L11.4079 9.36304C11.6082 8.84234 11.6711 8.69229 11.7572 8.57123C11.8436 8.44976 11.9497 8.34363 12.0712 8.25726C12.1923 8.17118 12.3423 8.10824 12.863 7.90798L14.9059 7.12223C15.1634 7.0232 15.3333 6.77584 15.3333 6.5C15.3333 6.22415 15.1634 5.97679 14.9059 5.87777L12.863 5.09202C12.3423 4.89175 12.1923 4.82882 12.0712 4.74274C11.9497 4.65636 11.8436 4.55023 11.7572 4.42876C11.6711 4.3077 11.6082 4.15765 11.4079 3.63696L10.6222 1.59401Z"
        fill="#007AFF"
      />
      <path
        d="M10.6222 1.59401C10.5232 1.33655 10.2758 1.16666 9.99996 1.16666C9.72411 1.16666 9.47675 1.33655 9.37773 1.59401L8.59198 3.63696C8.39171 4.15765 8.32878 4.3077 8.2427 4.42876C8.15632 4.55023 8.05019 4.65636 7.92872 4.74274C7.80766 4.82882 7.65761 4.89175 7.13692 5.09202L5.09397 5.87777C4.83651 5.97679 4.66663 6.22415 4.66663 6.5C4.66663 6.77585 4.83651 7.0232 5.09397 7.12223L7.13692 7.90798C7.65761 8.10824 7.80766 8.17118 7.92872 8.25726C8.0502 8.34363 8.15632 8.44976 8.2427 8.57123C8.32878 8.69229 8.39171 8.84234 8.59198 9.36304L9.37773 11.406C9.47675 11.6634 9.72411 11.8333 9.99996 11.8333C10.2758 11.8333 10.5232 11.6634 10.6222 11.406L11.4079 9.36304C11.6082 8.84234 11.6711 8.69229 11.7572 8.57123C11.8436 8.44976 11.9497 8.34363 12.0712 8.25726C12.1923 8.17118 12.3423 8.10824 12.863 7.90798L14.9059 7.12223C15.1634 7.0232 15.3333 6.77584 15.3333 6.5C15.3333 6.22415 15.1634 5.97679 14.9059 5.87777L12.863 5.09202C12.3423 4.89175 12.1923 4.82882 12.0712 4.74274C11.9497 4.65636 11.8436 4.55023 11.7572 4.42876C11.6711 4.3077 11.6082 4.15765 11.4079 3.63696L10.6222 1.59401Z"
        fill="url(#paint0_linear_4850_16531)"
      />
      <path
        d="M10.6222 1.59401C10.5232 1.33655 10.2758 1.16666 9.99996 1.16666C9.72411 1.16666 9.47675 1.33655 9.37773 1.59401L8.59198 3.63696C8.39171 4.15765 8.32878 4.3077 8.2427 4.42876C8.15632 4.55023 8.05019 4.65636 7.92872 4.74274C7.80766 4.82882 7.65761 4.89175 7.13692 5.09202L5.09397 5.87777C4.83651 5.97679 4.66663 6.22415 4.66663 6.5C4.66663 6.77585 4.83651 7.0232 5.09397 7.12223L7.13692 7.90798C7.65761 8.10824 7.80766 8.17118 7.92872 8.25726C8.0502 8.34363 8.15632 8.44976 8.2427 8.57123C8.32878 8.69229 8.39171 8.84234 8.59198 9.36304L9.37773 11.406C9.47675 11.6634 9.72411 11.8333 9.99996 11.8333C10.2758 11.8333 10.5232 11.6634 10.6222 11.406L11.4079 9.36304C11.6082 8.84234 11.6711 8.69229 11.7572 8.57123C11.8436 8.44976 11.9497 8.34363 12.0712 8.25726C12.1923 8.17118 12.3423 8.10824 12.863 7.90798L14.9059 7.12223C15.1634 7.0232 15.3333 6.77584 15.3333 6.5C15.3333 6.22415 15.1634 5.97679 14.9059 5.87777L12.863 5.09202C12.3423 4.89175 12.1923 4.82882 12.0712 4.74274C11.9497 4.65636 11.8436 4.55023 11.7572 4.42876C11.6711 4.3077 11.6082 4.15765 11.4079 3.63696L10.6222 1.59401Z"
        fill="url(#paint1_linear_4850_16531)"
      />
      <path
        d="M4.92958 8.86852C4.81665 8.64266 4.58581 8.5 4.33329 8.5C4.08078 8.5 3.84994 8.64266 3.73701 8.86852L3.21404 9.91446C3.02571 10.2911 2.96833 10.3995 2.8986 10.49C2.82866 10.5807 2.74732 10.662 2.65659 10.732C2.56613 10.8017 2.45774 10.8591 2.08109 11.0474L1.03515 11.5704C0.809294 11.6833 0.666626 11.9141 0.666626 12.1667C0.666626 12.4192 0.809294 12.65 1.03515 12.7629L2.08109 13.2859C2.45774 13.4742 2.56613 13.5316 2.65659 13.6014C2.74732 13.6713 2.82866 13.7526 2.8986 13.8434C2.96833 13.9338 3.02571 14.0422 3.21404 14.4189L3.73701 15.4648C3.84994 15.6907 4.08078 15.8333 4.33329 15.8333C4.58581 15.8333 4.81665 15.6907 4.92958 15.4648L5.45255 14.4189C5.64087 14.0422 5.69826 13.9338 5.76799 13.8434C5.83793 13.7526 5.91927 13.6713 6.01 13.6014C6.10046 13.5316 6.20884 13.4742 6.58549 13.2859L7.63143 12.7629C7.85729 12.65 7.99996 12.4192 7.99996 12.1667C7.99996 11.9141 7.85729 11.6833 7.63143 11.5704L6.58549 11.0474C6.20884 10.8591 6.10046 10.8017 6.01 10.732C5.91927 10.662 5.83793 10.5807 5.76799 10.49C5.69826 10.3995 5.64087 10.2911 5.45255 9.91447L4.92958 8.86852Z"
        fill="#007AFF"
      />
      <path
        d="M4.92958 8.86852C4.81665 8.64266 4.58581 8.5 4.33329 8.5C4.08078 8.5 3.84994 8.64266 3.73701 8.86852L3.21404 9.91446C3.02571 10.2911 2.96833 10.3995 2.8986 10.49C2.82866 10.5807 2.74732 10.662 2.65659 10.732C2.56613 10.8017 2.45774 10.8591 2.08109 11.0474L1.03515 11.5704C0.809294 11.6833 0.666626 11.9141 0.666626 12.1667C0.666626 12.4192 0.809294 12.65 1.03515 12.7629L2.08109 13.2859C2.45774 13.4742 2.56613 13.5316 2.65659 13.6014C2.74732 13.6713 2.82866 13.7526 2.8986 13.8434C2.96833 13.9338 3.02571 14.0422 3.21404 14.4189L3.73701 15.4648C3.84994 15.6907 4.08078 15.8333 4.33329 15.8333C4.58581 15.8333 4.81665 15.6907 4.92958 15.4648L5.45255 14.4189C5.64087 14.0422 5.69826 13.9338 5.76799 13.8434C5.83793 13.7526 5.91927 13.6713 6.01 13.6014C6.10046 13.5316 6.20884 13.4742 6.58549 13.2859L7.63143 12.7629C7.85729 12.65 7.99996 12.4192 7.99996 12.1667C7.99996 11.9141 7.85729 11.6833 7.63143 11.5704L6.58549 11.0474C6.20884 10.8591 6.10046 10.8017 6.01 10.732C5.91927 10.662 5.83793 10.5807 5.76799 10.49C5.69826 10.3995 5.64087 10.2911 5.45255 9.91447L4.92958 8.86852Z"
        fill="url(#paint2_linear_4850_16531)"
      />
      <path
        d="M4.92958 8.86852C4.81665 8.64266 4.58581 8.5 4.33329 8.5C4.08078 8.5 3.84994 8.64266 3.73701 8.86852L3.21404 9.91446C3.02571 10.2911 2.96833 10.3995 2.8986 10.49C2.82866 10.5807 2.74732 10.662 2.65659 10.732C2.56613 10.8017 2.45774 10.8591 2.08109 11.0474L1.03515 11.5704C0.809294 11.6833 0.666626 11.9141 0.666626 12.1667C0.666626 12.4192 0.809294 12.65 1.03515 12.7629L2.08109 13.2859C2.45774 13.4742 2.56613 13.5316 2.65659 13.6014C2.74732 13.6713 2.82866 13.7526 2.8986 13.8434C2.96833 13.9338 3.02571 14.0422 3.21404 14.4189L3.73701 15.4648C3.84994 15.6907 4.08078 15.8333 4.33329 15.8333C4.58581 15.8333 4.81665 15.6907 4.92958 15.4648L5.45255 14.4189C5.64087 14.0422 5.69826 13.9338 5.76799 13.8434C5.83793 13.7526 5.91927 13.6713 6.01 13.6014C6.10046 13.5316 6.20884 13.4742 6.58549 13.2859L7.63143 12.7629C7.85729 12.65 7.99996 12.4192 7.99996 12.1667C7.99996 11.9141 7.85729 11.6833 7.63143 11.5704L6.58549 11.0474C6.20884 10.8591 6.10046 10.8017 6.01 10.732C5.91927 10.662 5.83793 10.5807 5.76799 10.49C5.69826 10.3995 5.64087 10.2911 5.45255 9.91447L4.92958 8.86852Z"
        fill="url(#paint3_linear_4850_16531)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4850_16531"
          x1="0.666626"
          y1="8.5"
          x2="15.3333"
          y2="8.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#474EFF" />
          <stop offset="1" stopColor="#943FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4850_16531"
          x1="0.666626"
          y1="8.5"
          x2="15.3333"
          y2="8.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#7378FF" />
          <stop offset="1" stopColor="#A966FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4850_16531"
          x1="0.666626"
          y1="8.5"
          x2="15.3333"
          y2="8.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#474EFF" />
          <stop offset="1" stopColor="#943FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4850_16531"
          x1="0.666626"
          y1="8.5"
          x2="15.3333"
          y2="8.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#7378FF" />
          <stop offset="1" stopColor="#A966FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
