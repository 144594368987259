import {memo, useMemo, useRef, useEffect} from 'react';
import {Slider, SliderRenderThumbProps} from '@nextui-org/react';
import {SliderSetting as SliderSettingType} from '@/common/types/flowSettings';
import FlowSettingLabel from './Label';
import RecommendedBadge from './RecommendedBadge';
import {motion, useMotionValue, animate} from 'framer-motion';
import {twMerge} from 'tailwind-merge';
import {useLingui} from '@lingui/react';
import {msg} from '@lingui/macro';

type Props = {
  setting: SliderSettingType;
  onChange: (id: string, value: SliderSettingType['value']) => void;
};

const getEvenlySpacedValues = (min: number, max: number, count: number) => {
  const step = (max - min) / (count - 1);
  return Array.from({length: count}, (_, i) => Math.round(min + step * i));
};

const SliderSetting = memo(({setting, onChange}: Props) => {
  const {i18n, _} = useLingui();
  const thumbValueRef = useRef<HTMLDivElement>(null);
  const badgeRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const badgeOffset = useMotionValue(0);
  const recomenedPercent = setting.data.recommended
    ? ((setting.data.recommended - setting.data.min) /
        (setting.data.max - setting.data.min)) *
      100
    : 0;

  const handleChange = (rawValue: number | [number, number]) => {
    onChange(setting.id, rawValue);
  };

  const annotationValues = useMemo(() => {
    const min = setting.data.min;
    const max = setting.data.max;
    return getEvenlySpacedValues(min, max, 4);
  }, [setting.data.min, setting.data.max]);

  useEffect(() => {
    const checkOverlap = () => {
      if (
        !setting.data.recommended ||
        !thumbValueRef.current ||
        !containerRef.current ||
        !badgeRef.current
      ) {
        return;
      }

      const middle = (setting.data.min + setting.data.max) / 2;
      const isOnLeft = ((setting.value as number) ?? 0) > middle;
      const padding = 12;

      const thumbRect = thumbValueRef.current.getBoundingClientRect();
      const badgeRect = badgeRef.current.getBoundingClientRect();

      const offset = isOnLeft
        ? -badgeRect.width - padding
        : thumbRect.width + padding;
      const diff = Math.abs(badgeOffset.get() - offset);

      if (diff < 5 || badgeOffset.isAnimating()) {
        animate(badgeOffset, offset, {
          type: 'tween',
          duration: 0.2,
        });
      } else {
        badgeOffset.set(offset);
      }
    };

    checkOverlap();

    window.addEventListener('resize', checkOverlap);

    return () => {
      window.removeEventListener('resize', checkOverlap);
    };
  }, [setting, badgeOffset]);

  const formatValue = (value: number | [number, number] | undefined) => {
    const values = Array.isArray(value) ? value : [value];
    const func = (v: number | undefined) => {
      switch (setting.data.format) {
        case 'currency':
          return i18n.number(v ?? 0, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          });
        case 'age':
        case 'number':
          return i18n.number(v ?? 0, {
            maximumFractionDigits: 0,
          });
        default:
          return value;
      }
    };

    return values.map(func).join(' - ');
  };

  return (
    <div className="flex w-full flex-col gap-3">
      <FlowSettingLabel>{setting.label}</FlowSettingLabel>
      <div ref={containerRef} className="relative flex w-full flex-col gap-1">
        <Slider
          input
          minValue={setting.data.min}
          maxValue={setting.data.max}
          step={setting.data.step ?? 1}
          classNames={{
            track: 'h-2 bg-foreground/10 mb-3 mt-12',
            thumb: 'w-7 h-7 border-none bg-foreground',
          }}
          renderThumb={({
            children = null,
            ...props
          }: SliderRenderThumbProps) => {
            const values = setting.value ?? setting.data.recommended;
            const value = Array.isArray(values)
              ? values[props.index ?? 0]
              : values;

            return (
              <>
                {recomenedPercent ? (
                  <div
                    style={{
                      background:
                        'linear-gradient(90deg, #7378FF 0%, #A966FF 100%)',
                      left: `calc(${recomenedPercent}% - 0.375rem)`,
                    }}
                    className="absolute -top-0.5 left-0 h-3 w-3 rounded-full"
                  />
                ) : null}
                <div
                  {...props}
                  className="group top-1/2 h-7 w-7 cursor-grab rounded-full bg-foreground shadow-medium data-[dragging=true]:cursor-grabbing"
                  aria-label={_(msg`Slider ${setting.label} toggle`)}
                  data-amp-track-label={`Slider ${setting.label} toggle`}
                  ref={e => {
                    const track = e?.parentElement;

                    if (track?.getAttribute('aria-label')) {
                      return;
                    }

                    track?.setAttribute(
                      'aria-label',
                      _(msg`Slider ${setting.label}`),
                    );
                    track?.setAttribute(
                      'data-amp-track-label',
                      `Slider ${setting.label}`,
                    );
                  }}>
                  <div
                    ref={thumbValueRef}
                    className={
                      'pointer-events-none absolute bottom-10 left-1/2 -translate-x-1/2 transform text-foreground transition-opacity'
                    }>
                    {setting.data.recommended ? (
                      <motion.div
                        ref={badgeRef}
                        style={{x: badgeOffset}}
                        className={twMerge('absolute bottom-0 left-0')}>
                        <RecommendedBadge
                          value={
                            setting.data.recommended === setting.value
                              ? undefined
                              : formatValue(setting.data.recommended)
                          }
                        />
                      </motion.div>
                    ) : null}
                    <span
                      className={twMerge(
                        'transition-all',
                        setting.data.recommended === setting.value
                          ? 'text-base font-medium'
                          : 'text-sm font-[300]',
                      )}>
                      {formatValue(value)}
                    </span>
                  </div>
                  {children}
                </div>
              </>
            );
          }}
          value={setting.value ?? setting.data.recommended}
          // @ts-expect-error wrong type
          onChange={handleChange}
        />
        <div className="flex w-full justify-between">
          {annotationValues.map((value, index) => (
            <div key={index} className="text-xs text-foreground/50">
              {formatValue(value)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

SliderSetting.displayName = 'SliderSetting';

export default SliderSetting;
