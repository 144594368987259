import {UPDATE_WHATSAPP_OPTED_IN_PHONE_NUMBER} from '@/common/mutations';
import {GET_PHONE_NUMBER_CONNECTION} from '@/common/queries';
import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {PhoneNumberPurpose} from '@/__generated__/graphql';

export const useWhatsappNotifications = () => {
  const intervalRef = useRef<any>();
  const [optedInPhoneNumbersState, setOptedInPhoneNumbersState] = useState<{
    phoneNumbers:
      | {
          phoneNumber: string;
          updatedAt: string;
        }[]
      | undefined;
    hasChanged: boolean;
  }>({
    phoneNumbers: undefined,
    hasChanged: false,
  });
  const [updateWhatsappOptedInPhoneNumber, {loading: optInLoading}] =
    useMutation(UPDATE_WHATSAPP_OPTED_IN_PHONE_NUMBER, {
      refetchQueries: ['GetPhoneNumberConnection'],
    });
  const phoneNumbersData = useQuery(GET_PHONE_NUMBER_CONNECTION, {
    variables: {
      filter: {
        purpose: PhoneNumberPurpose.Whatsapp,
      },
      order: {
        phoneNumber: 'asc',
      },
    },
  });
  const pageInfo = phoneNumbersData?.data?.connection.pageInfo;
  const isLoading = phoneNumbersData.loading || optInLoading;
  const isInitialLoading = !phoneNumbersData.data && phoneNumbersData.loading;
  const phoneNumbers = useMemo(() => {
    return phoneNumbersData?.data?.connection.edges.map(e => e.node) ?? [];
  }, [phoneNumbersData?.data?.connection.edges]);
  const optedInPhoneNumbers = useMemo(() => {
    return phoneNumbers.filter(e => e.whatsappOptedIn);
  }, [phoneNumbers]);
  const optedOutPhoneNumbers = useMemo(() => {
    return phoneNumbers.filter(e => !e.whatsappOptedIn);
  }, [phoneNumbers]);

  const startPhoneNumberPolling = useCallback(() => {
    intervalRef.current = setInterval(() => {
      phoneNumbersData.refetch();
    }, 3000);
  }, []);

  const stopPhoneNumberPolling = useCallback(() => {
    clearInterval(intervalRef.current);
  }, []);

  const setOptInStatus = useCallback(
    (enable: boolean, cb?: (success: boolean) => void) => {
      // return if loading or no opted out phone numbers when enabling
      if (optInLoading || (enable && !optedOutPhoneNumbers.length)) {
        return cb?.(false);
      }

      const phoneNumber = enable
        ? optedOutPhoneNumbers[0].phoneNumber
        : undefined;

      updateWhatsappOptedInPhoneNumber({
        variables: {
          phoneNumber,
        },
      })
        .then(() => {
          cb?.(true);
        })
        .catch(() => {
          cb?.(false);
        });
    },
    [optInLoading, optedOutPhoneNumbers, updateWhatsappOptedInPhoneNumber],
  );

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      phoneNumbersData.fetchMore({variables: {after: pageInfo.endCursor}});
    }
  }, [pageInfo?.hasNextPage, pageInfo?.endCursor, phoneNumbersData.fetchMore]);

  useEffect(() => {
    if (isInitialLoading) {
      return;
    }

    const getOptedInPhoneNumbersKey = (
      phones: {
        phoneNumber: string;
        updatedAt: string;
      }[],
    ) => phones.map(p => `${p.phoneNumber}|${p.updatedAt}`).join(',');

    setOptedInPhoneNumbersState(prev => {
      const hasChanged = prev.phoneNumbers
        ? getOptedInPhoneNumbersKey(optedInPhoneNumbers) !==
          getOptedInPhoneNumbersKey(prev.phoneNumbers)
        : false;

      return {
        phoneNumbers: optedInPhoneNumbers,
        hasChanged,
      };
    });
  }, [optedInPhoneNumbers]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return useMemo(
    () => ({
      isLoading,
      isInitialLoading,
      isEnabled: optedInPhoneNumbers.length > 0,
      optedInPhoneNumbersChanged: optedInPhoneNumbersState.hasChanged,
      optedInPhoneNumbers,
      optedOutPhoneNumbers,
      startPhoneNumberPolling,
      stopPhoneNumberPolling,
      setOptInStatus,
    }),
    [
      isInitialLoading,
      isLoading,
      optedInPhoneNumbers,
      optedOutPhoneNumbers,
      optedInPhoneNumbersState.hasChanged,
      startPhoneNumberPolling,
      stopPhoneNumberPolling,
      setOptInStatus,
    ],
  );
};
