'use client';

import {FC, memo} from 'react';
import {Outlet} from 'react-router-dom';
import ToastQueue from '@/components/ToastQueue';
// import InstagramBusinessAccountSelector from '@/components/InstagramBusinessAccountSelector';

const Root: FC = memo(() => {
  return (
    <>
      {/* <InstagramBusinessAccountSelector /> */}
      <div className="flex h-full min-h-[100dvh] w-[100dvw] min-w-[100dvw] max-w-[100dvw] flex-col bg-background text-foreground">
        <div className="flex grow flex-col items-center">
          <Outlet />
        </div>
        <ToastQueue />
      </div>
    </>
  );
});

Root.displayName = 'Root';

export default Root;
