'use client';

import {SVGProps} from 'react';

export default function AlertTriangle2Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6772 1.89377C10.2461 1.70208 9.75393 1.70208 9.32283 1.89377C8.98989 2.04181 8.77361 2.30258 8.62311 2.51505C8.47514 2.72396 8.31672 2.99765 8.14249 3.29864L1.2532 15.1983C1.07827 15.5004 0.919309 15.7749 0.811525 16.0079C0.701984 16.2447 0.583147 16.563 0.621021 16.9263C0.67003 17.3964 0.916314 17.8235 1.29858 18.1015C1.59399 18.3162 1.92899 18.3729 2.18879 18.3967C2.44441 18.4202 2.76162 18.4201 3.1107 18.4201H16.8893C17.2383 18.4201 17.5556 18.4202 17.8112 18.3967C18.071 18.3729 18.406 18.3162 18.7014 18.1015C19.0837 17.8235 19.33 17.3964 19.379 16.9263C19.4168 16.563 19.298 16.2447 19.1885 16.0079C19.0807 15.775 18.9217 15.5005 18.7468 15.1984L11.8575 3.29861C11.6833 2.99764 11.5248 2.72395 11.3769 2.51505C11.2264 2.30257 11.0101 2.04181 10.6772 1.89377ZM10.8334 8.00346C10.8334 7.54322 10.4603 7.17013 10 7.17013C9.53978 7.17013 9.16669 7.54322 9.16669 8.00346V11.3368C9.16669 11.797 9.53978 12.1701 10 12.1701C10.4603 12.1701 10.8334 11.797 10.8334 11.3368V8.00346ZM10 13.8368C9.53978 13.8368 9.16669 14.2099 9.16669 14.6701C9.16669 15.1304 9.53978 15.5035 10 15.5035H10.0084C10.4686 15.5035 10.8417 15.1304 10.8417 14.6701C10.8417 14.2099 10.4686 13.8368 10.0084 13.8368H10Z"
        fill="currentColor"
      />
    </svg>
  );
}
