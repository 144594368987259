'use client';

import {InputProps} from '@nextui-org/input';
import {FC} from 'react';
import NextUIInput from './NextUIInput';

const NextUIUrlInput: FC<InputProps> = props => (
  <NextUIInput
    startContent={
      <div className="flex min-h-full items-center pr-3 text-sm text-foreground/75">
        https://
      </div>
    }
    {...props}
  />
);

export default NextUIUrlInput;
