import {VIDEOGEN_TIME, VIDEOGEN_VIDEO_COUNT} from '@/config';
import shuffle from 'lodash/shuffle';
import {useFlowContext} from '@/hooks';
import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {animate, AnimatePresence, motion, useMotionValue} from 'framer-motion';
import {memo, useEffect, useMemo, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import SegmentedProgressBar from './SegmentedProgressBar';
import ThumbnailImage from './ThumbnailImage';
import {Button} from '@nextui-org/react';

interface Props {
  isVisible: boolean;
  className?: string;
  scrollToVideos: () => void;
}

const IMAGE_OFFSET = 12;
const HEIGHT = 112;

const ProgressOverlay = memo(
  ({isVisible, className, scrollToVideos}: Props) => {
    const {_} = useLingui();
    const {
      isGeneratingVideos,
      currentStep,
      generatedVideosState,
      loaderProgress,
    } = useFlowContext();
    const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState(0);
    const videogenProgressValue = useMotionValue(loaderProgress ?? 0);
    const videosCountProgressValue = useMotionValue(
      100 * (generatedVideosState.length / VIDEOGEN_VIDEO_COUNT),
    );
    const isShown = isVisible && currentStep === 'configure_campaign';
    const flowState =
      generatedVideosState.length === 0
        ? 'initial'
        : generatedVideosState.length === VIDEOGEN_VIDEO_COUNT
          ? 'success'
          : 'progress';
    const title = (() => {
      switch (flowState) {
        case 'initial':
          return [
            _(msg`~${VIDEOGEN_TIME / 60 / 1000} min to Generate Assets`),
            loaderProgress === 100
              ? ''
              : _(msg`${Math.round(loaderProgress ?? 0)}% Done…`),
          ]
            .filter(Boolean)
            .join(', ');
        case 'progress':
          return _(
            msg`${generatedVideosState.length} out of ${VIDEOGEN_VIDEO_COUNT} Assets Generated!`,
          );
        case 'success':
          return _(msg`All Assets are Ready!`);
      }
    })();
    const subtitleOptions = useMemo(
      () =>
        shuffle([
          ['🚀', _(msg`As fast as your favorite TikTok loop`)],
          ['😚', _(msg`As fast as snapping the perfect selfie`)],
          ['☕', _(msg`As fast as brewing your morning coffee`)],
          ['🍿', _(msg`As fast as microwaving a bag of popcorn`)],
          ['🧘', _(msg`As fast as peaceful savasana`)],
          ['🍩', _(msg`As fast as grabbing a donut`)],
          ['🎮', _(msg`As fast as beating the boss in your game`)],
          ['🚿', _(msg`As fast as refreshing shower`)],
          ['🌞', _(msg`As fast as morning yoga stretch`)],
          ['🎬', _(msg`As fast as posting a reel`)],
          ['🏄', _(msg`As fast as waxing your board`)],
        ]),
      [_],
    );
    const finishingLoadingSubtitle = useMemo(
      () => ['🏁', _(msg`Finishing Generation…`)],
      [_],
    );
    const currentSubtitle =
      loaderProgress === 100
        ? finishingLoadingSubtitle
        : subtitleOptions[currentSubtitleIndex];

    useEffect(() => {
      if (flowState !== 'initial') {
        return;
      }

      const interval = setInterval(() => {
        setCurrentSubtitleIndex(prev => (prev + 1) % subtitleOptions.length);
      }, 1000 * 5);

      return () => clearInterval(interval);
    }, [flowState]);

    useEffect(() => {
      const value = loaderProgress ?? 0;

      animate(videogenProgressValue, value, {
        duration: 0.3,
        ease: 'linear',
      });
    }, [loaderProgress]);

    useEffect(() => {
      const value = 100 * (generatedVideosState.length / VIDEOGEN_VIDEO_COUNT);

      if (isGeneratingVideos) {
        animate(videosCountProgressValue, value, {
          duration: 0.3,
          ease: 'easeInOut',
        });
      } else {
        videosCountProgressValue.set(value);
      }
    }, [generatedVideosState, isGeneratingVideos]);

    return (
      <AnimatePresence>
        {isShown ? (
          <motion.div
            initial={{y: -HEIGHT}}
            animate={{y: 0}}
            exit={{y: -HEIGHT}}
            transition={{
              type: 'tween',
              duration: 0.2,
            }}
            style={{
              height: HEIGHT,
              background:
                'linear-gradient(180deg, #000 0%, transparent 100%) no-repeat',
            }}
            className={twMerge(
              'pointer-events-none fixed left-0 right-0 top-0 z-[100] mx-auto flex max-w-[42rem] flex-col gap-3 p-4',
              className,
            )}>
            {flowState === 'initial' ? (
              <SegmentedProgressBar
                progress={videogenProgressValue}
                segments={1}
              />
            ) : (
              <SegmentedProgressBar
                progress={videosCountProgressValue}
                segments={VIDEOGEN_VIDEO_COUNT}
              />
            )}
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{
                  duration: 0.6,
                  ease: 'easeInOut',
                }}
                key={flowState}
                className="text-center text-base font-medium text-foreground">
                {title}
              </motion.div>
            </AnimatePresence>
            <AnimatePresence mode="wait">
              {flowState === 'initial' ? (
                <motion.div
                  initial={{opacity: 0, y: -16}}
                  animate={{opacity: 1, y: -8}}
                  exit={{opacity: 0, y: 8}}
                  transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                  }}
                  key={currentSubtitle[1]}
                  className="text-center text-sm">
                  {currentSubtitle[0]}{' '}
                  <span className="text-foreground/50">
                    {currentSubtitle[1]}
                  </span>
                </motion.div>
              ) : (
                <Button
                  aria-label={_(msg`Scroll to videos`)}
                  data-amp-track-label="Scroll to videos"
                  disableAnimation
                  disableRipple
                  style={{
                    transform: `translateX(${((generatedVideosState.length - 1) * IMAGE_OFFSET) / 2}px)`,
                  }}
                  className="pointer-events-auto flex h-auto min-h-0 min-w-0 items-center justify-center self-center overflow-visible bg-transparent p-0 transition-all duration-300"
                  onPress={() => {
                    scrollToVideos();
                  }}>
                  {generatedVideosState
                    .slice()
                    .reverse()
                    .map((video, index) => (
                      <ThumbnailImage key={video.id} {...video} index={index} />
                    ))}
                </Button>
              )}
            </AnimatePresence>
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  },
);

ProgressOverlay.displayName = 'ProgressOverlay';

export default ProgressOverlay;
