'use client';

import {SVGProps} from 'react';

export default function PlayIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}>
      <g filter="url(#filter0_b_583_10660)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.13842 2.75817C7.14848 2.76488 7.15858 2.77161 7.16871 2.77836L16.0495 8.6989C16.3065 8.87017 16.5447 9.02898 16.7277 9.17655C16.9187 9.33056 17.1438 9.54413 17.2734 9.85655C17.4446 10.2695 17.4446 10.7336 17.2734 11.1466C17.1438 11.459 16.9187 11.6725 16.7277 11.8266C16.5447 11.9741 16.3065 12.1329 16.0496 12.3042L7.13845 18.2449C6.82435 18.4543 6.54192 18.6427 6.30226 18.7725C6.06244 18.9025 5.73325 19.051 5.34904 19.0281C4.85759 18.9987 4.4036 18.7558 4.10657 18.3631C3.87436 18.0562 3.81534 17.6999 3.79045 17.4283C3.76558 17.1568 3.7656 16.8174 3.76563 16.4399L3.76563 4.59964C3.76563 4.58746 3.76563 4.57533 3.76563 4.56323C3.7656 4.18573 3.76558 3.84629 3.79045 3.57485C3.81534 3.30321 3.87436 2.94693 4.10657 2.63997C4.4036 2.24733 4.85759 2.00436 5.34904 1.97502C5.73325 1.95208 6.06244 2.1006 6.30226 2.23056C6.54191 2.36044 6.82433 2.54875 7.13842 2.75817Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_583_10660"
          x="-0.180703"
          y="-1.97367"
          width="21.5294"
          height="24.9513"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.97316" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_583_10660"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_583_10660"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
