'use client';

import {useAppContext, useAuthContext} from '@/hooks';
import PortalSpinner from '@/components/PortalSpinner';
import {memo} from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';

const AppInvite = memo(() => {
  const {user} = useAuthContext();
  const {isAuthenticated} = useAppContext();
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');

  if (isAuthenticated && !user) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-40',
        }}
        size="lg"
      />
    );
  }

  if ((isAuthenticated && user?.verified) || !state) {
    return <Navigate to="../.." replace />;
  }

  return <Navigate to="../login/profile" state={state} replace />;
});

AppInvite.displayName = 'AppInvite';

export default AppInvite;
