'use client';

import {memo, SVGProps, useEffect, useRef} from 'react';
import {
  motion,
  animate,
  MotionValue,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import {twJoin} from 'tailwind-merge';

const progressToDashArray = (progress: number, radius: number) => {
  const circumference = 2 * Math.PI * radius;
  const filledLength = (circumference * progress) / 100;
  const gapLength = circumference - filledLength;

  return `${filledLength} ${gapLength}`;
};

type Props = {
  size: number;
  strokeWidth: number;
  spin?: boolean;
  backgroundColor?: string;
  progress: number; // Progress value between 0 and 100
} & SVGProps<SVGSVGElement>;

const FlowSpinner = memo(
  ({
    className,
    size,
    strokeWidth,
    backgroundColor = 'rgba(255, 255, 255, 0.08)',
    spin = true,
    center,
    radius,
    progressValue,
    ...props
  }: Omit<Props, 'progress' | 'ref' | 'radius'> & {
    progressValue: MotionValue<number>;
    radius: number;
    center: number;
  }) => {
    const dasharrayValue = useTransform(progressValue, value =>
      progressToDashArray(value, radius),
    );

    return (
      <svg
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}>
        <defs>
          <radialGradient
            id="toolLoaderGradient"
            cx={size}
            cy={0}
            r={size * 1.2}
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#474EFF" offset={0} />
            <stop stopColor="#CC3FFF" offset={100} />
          </radialGradient>
        </defs>
        <circle
          cx={center}
          cy={center}
          fill="none"
          r={radius}
          strokeWidth={strokeWidth}
          stroke={backgroundColor}
        />
        <motion.circle
          style={{
            strokeDasharray: dasharrayValue,
          }}
          className={twJoin(
            'origin-center',
            spin ? 'animate-[spin_1.2s_linear_infinite]' : '-rotate-90',
          )}
          cx={center}
          cy={center}
          fill="none"
          r={radius}
          strokeWidth={strokeWidth}
          stroke="url(#toolLoaderGradient)"
          strokeLinecap="round"
        />
      </svg>
    );
  },
);

FlowSpinner.displayName = 'FlowSpinner';

const WrappedFlowSpinner = memo(({progress, ...props}: Props) => {
  const center = props.size / 2;
  const radius = center - 2 - props.strokeWidth / 2;
  const progressValue = useMotionValue(progress);
  const prevProgressRef = useRef(progress);

  useEffect(() => {
    const diff = Math.abs(progress - prevProgressRef.current);

    if (diff > 1 && !progressValue.isAnimating()) {
      progressValue.set(progress);
    } else {
      animate(progressValue, progress, {
        duration: 0.3,
        ease: 'easeInOut',
      });
    }
  }, [progress, progressValue]);

  return <FlowSpinner {...props} {...{center, radius, progressValue}} />;
});

WrappedFlowSpinner.displayName = 'WrappedFlowSpinner';

export default WrappedFlowSpinner;
