'use client';

import {SVGProps} from 'react';

export default function CheckRectIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8385 2.67188H6.16146C5.63433 2.67186 5.17954 2.67184 4.80497 2.70245C4.40963 2.73475 4.01641 2.80606 3.63803 2.99886C3.07354 3.28648 2.6146 3.74542 2.32698 4.30991C2.13419 4.68829 2.06287 5.0815 2.03057 5.47685C1.99997 5.85141 1.99998 6.30616 2 6.83329V18.5104C1.99998 19.0376 1.99997 19.4923 2.03057 19.8669C2.06287 20.2623 2.13419 20.6555 2.32698 21.0338C2.6146 21.5983 3.07354 22.0573 3.63803 22.3449C4.01641 22.5377 4.40963 22.609 4.80497 22.6413C5.17954 22.6719 5.6343 22.6719 6.16144 22.6719H17.8386C18.3657 22.6719 18.8205 22.6719 19.195 22.6413C19.5904 22.609 19.9836 22.5377 20.362 22.3449C20.9265 22.0573 21.3854 21.5983 21.673 21.0338C21.8658 20.6555 21.9371 20.2623 21.9694 19.8669C22 19.4923 22 19.0376 22 18.5104V6.83331C22 6.30617 22 5.85142 21.9694 5.47685C21.9371 5.0815 21.8658 4.68829 21.673 4.30991C21.3854 3.74542 20.9265 3.28648 20.362 2.99886C19.9836 2.80606 19.5904 2.73475 19.195 2.70245C18.8205 2.67184 18.3657 2.67186 17.8385 2.67188ZM17.2071 10.379C17.5976 9.98846 17.5976 9.35529 17.2071 8.96477C16.8166 8.57424 16.1834 8.57424 15.7929 8.96477L10.5 14.2577L8.20711 11.9648C7.81658 11.5742 7.18342 11.5742 6.79289 11.9648C6.40237 12.3553 6.40237 12.9885 6.79289 13.379L9.79289 16.379C10.1834 16.7695 10.8166 16.7695 11.2071 16.379L17.2071 10.379Z"
        fill="currentColor"
      />
    </svg>
  );
}
