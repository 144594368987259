import Stars02Icon from '@/components/icons/Stars02Icon';
import {Trans} from '@lingui/macro';
import {forwardRef} from 'react';

interface Props {
  value?: string | number;
}

const RecommendedBadge = forwardRef(
  ({value}: Props, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        style={{
          background:
            'linear-gradient(90deg, rgba(71, 78, 255, 0.25) 0%, rgba(148, 63, 255, 0.25) 100%)',
        }}
        className="flex items-center gap-1 rounded-full px-2 py-1 text-xs text-foreground/50">
        <Stars02Icon className="h-4 w-4" />
        {value ? <Trans>Recommended:</Trans> : <Trans>Recommended</Trans>}
        {value ? <div className="text-foreground"> {value}</div> : null}
      </div>
    );
  },
);

RecommendedBadge.displayName = 'RecommendedBadge';

export default RecommendedBadge;
