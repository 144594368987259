'use client';

import {SVGProps} from 'react';

export default function MusicIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.648 3.11587C11.5674 3.12223 11.4563 3.14022 11.2591 3.17308L6.12582 4.02864C5.97863 4.05317 5.90013 4.06664 5.84423 4.08036L5.83855 4.08178L5.83808 4.08762C5.83374 4.14501 5.83336 4.22466 5.83336 4.37388V11.1666L5.83335 11.1687C5.83221 12.4564 4.78798 13.4999 3.50002 13.4999C2.21136 13.4999 1.16669 12.4552 1.16669 11.1665C1.16669 9.87788 2.21136 8.83321 3.50002 8.83321C3.92502 8.83321 4.32349 8.94684 4.66669 9.14537L4.66669 4.35729C4.66667 4.23123 4.66666 4.10649 4.67474 3.99964C4.68373 3.88079 4.70484 3.74263 4.76889 3.59986C4.85899 3.39903 5.00405 3.22779 5.18733 3.10591C5.31764 3.01925 5.45044 2.97571 5.5662 2.94731C5.67027 2.92177 5.79331 2.90128 5.91766 2.88057L11.0877 2.0189C11.257 1.99065 11.4189 1.96365 11.5562 1.95282C11.7044 1.94112 11.8852 1.94179 12.0729 2.0115C12.3286 2.10647 12.543 2.28805 12.6787 2.52467C12.7783 2.69833 12.8087 2.8766 12.8215 3.0247C12.8334 3.16186 12.8334 3.32597 12.8334 3.49763L12.8334 9.99989C12.8334 10.0323 12.8307 10.0642 12.8256 10.0952C12.7756 11.3397 11.7509 12.3332 10.4942 12.3332C9.20552 12.3332 8.16085 11.2885 8.16085 9.99988C8.16085 8.71121 9.20552 7.66654 10.4942 7.66654C10.9216 7.66654 11.3222 7.78146 11.6667 7.98209V3.51832C11.6667 3.31841 11.6662 3.20593 11.6592 3.12536L11.6582 3.11512L11.648 3.11587Z"
        fill="currentColor"
      />
    </svg>
  );
}
