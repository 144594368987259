import MusicIcon from '@/components/icons/MusicIcon';
import {ModalBody, ModalHeader} from '@/components/Modal';
import PortalSpinner from '@/components/PortalSpinner';
import {useFlowContext} from '@/hooks';
import usePresignedLink from '@/hooks/usePresignedLink';
import {isSafari} from '@/utils';
import {Trans} from '@lingui/macro';
import {memo, useRef} from 'react';
import {Navigate, useParams} from 'react-router-dom';

const PreviewAsset = memo(() => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const {id} = useParams();
  const {generatedVideosState} = useFlowContext();
  const asset = generatedVideosState.find(
    ({id: generatedVideoId}) => generatedVideoId === id,
  );
  const assetUrl = usePresignedLink(asset?.name);

  if (!id) {
    return <Navigate to=".." />;
  }

  if (!asset) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
        }}
        size="lg"
      />
    );
  }

  let musicParts = [asset.musicTitle, asset.musicAuthor].filter(Boolean);

  if (musicParts.length === 2) {
    musicParts = [musicParts[0], 'by', musicParts[1]];
  }

  return (
    <>
      <ModalHeader>
        <div className="flex w-full text-base font-medium">
          <Trans>Preview Asset</Trans>
        </div>
      </ModalHeader>
      <ModalBody className="mx-auto w-full max-w-lg gap-8">
        <div className="flex w-full flex-col gap-4 pb-4 text-center">
          <div className="relative flex w-full justify-center">
            <video
              ref={videoRef}
              className="mx-auto h-auto max-h-[min(24rem,50dvh)] rounded-lg"
              preload="metadata"
              controls
              loop
              playsInline
              autoPlay
              muted // NOTE mute until we have the sound to avoid autoplay issues https://developer.chrome.com/blog/autoplay/
              src={
                assetUrl
                  ? isSafari
                    ? `${assetUrl}#t=0.01`
                    : assetUrl
                  : undefined
              }
            />
          </div>
          <div className="flex justify-center gap-2 truncate text-sm">
            <MusicIcon className="h-4 w-4 text-foreground/50" />
            {musicParts.map((part, index) => (
              <span
                key={index}
                className={
                  index === 1 ? 'text-foreground/50' : 'text-foreground'
                }>
                {part + ' '}
              </span>
            ))}
          </div>
        </div>
      </ModalBody>
    </>
  );
});

PreviewAsset.displayName = 'PreviewAsset';

export default PreviewAsset;
