import {useFlowContext} from '@/hooks';
import MetaPresets from '@/routes/components/MetaPresets';
import {Trans} from '@lingui/macro';
import {memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

const SelectMetaFlowPreset = memo(() => {
  const {runId, settings, updateSettingValue} = useFlowContext();
  const metaPresetSetting = settings.find(s => s.type === 'meta_preset');
  const navigate = useNavigate();
  const onSave = useCallback(
    (presetId: string | null) => {
      if (presetId && metaPresetSetting) {
        updateSettingValue(metaPresetSetting.id, presetId);
        navigate('..');
      }
    },
    [updateSettingValue, metaPresetSetting],
  );

  return (
    <MetaPresets
      title={<Trans>Select Preset</Trans>}
      basePath={`/create/${runId}/meta-presets`}
      isSaving={false}
      initialSelectedPreset={metaPresetSetting?.value}
      onSave={onSave}
    />
  );
});

SelectMetaFlowPreset.displayName = 'SelectMetaFlowPreset';

export default SelectMetaFlowPreset;
