'use client';

import {ReactNode} from 'react';
import {GetFlowRunQuery, GetUserQuery, Scalars} from '@/__generated__/graphql';
import {TypedFlowSetting} from './flowSettings';

export type User = GetUserQuery['user'];

export type TokenResponse = {
  access_token: string;
  access_token_expires_at?: string;
  refresh_token?: string;
  refresh_token_expires_at?: string;
  scope?: string[];
  token_type: string;
  user?: {
    id: string;
  };
};

export type Toast = {
  id: string;
  type: 'success' | 'error' | 'warning';
  title?: ReactNode;
  message: ReactNode;
};

export enum IntegrationType {
  shopify = 'shopify',
  googleAds = 'google_ads',
  amazonSp = 'amazon_sp',
  amazonAds = 'amazon_ads',
  metaAds = 'meta_ads',
  shipbob = 'shipbob',
  intercom = 'intercom',
  instagram = 'instagram',
  tiktok = 'tiktok',
}

export type DataSourceId = 'shopify' | 'meta_ads' | 'tiktok';

export type BaseErrorURLParam = {
  message: string;
  status?: number;
};

export type AuthErrorURLParam = BaseErrorURLParam & {
  type: 'auth';
  auth_provider: 'google' | 'apple';
};

export type ConnectIntegrationErrorURLParam = BaseErrorURLParam & {
  type: 'integration_connection';
  integration_type: IntegrationType;
};

export type ErrorURLParam = AuthErrorURLParam | ConnectIntegrationErrorURLParam;

export type Maybe<T> = T | null;

export type Asset = {
  __typename?: 'Asset';
  blurHash?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Float']['output']>;
};

export type ResponsiveImage = {
  default?: Maybe<Asset>;
  large?: Maybe<Asset>;
  medium?: Maybe<Asset>;
  small?: Maybe<Asset>;
  xLarge?: Maybe<Asset>;
  xxLarge?: Maybe<Asset>;
};

export enum StepCompletionCondition {
  AllDataSourcesRequired = 'AllDataSourcesRequired',
  NoDataSourcesRequired = 'NoDataSourcesRequired',
  OneDataSourceRequired = 'OneDataSourceRequired',
}

export type FileUpload = {
  url: string;
  file: File;
};

export type PostPlatform = 'meta_ads' | 'tiktok';

export type FlowAsset =
  GetFlowRunQuery['getFlowRun']['assets']['edges'][0]['node'];

export type FlowSetting = Omit<
  GetFlowRunQuery['getFlowRun']['settings']['edges'][0]['node'],
  '__typename' | 'data' | 'value' | 'featured' | 'required' | 'key' | 'label'
> &
  TypedFlowSetting;

export type FlowRun = Omit<
  GetFlowRunQuery['getFlowRun'],
  '__typename' | 'assets' | 'settings'
> & {
  assets: FlowAsset[];
  settings: FlowSetting[];
};

export type GeneratedVideoState = {
  id: string;
  name: string;
  thumbnailName?: string | null;
  headlinePrompt: string;
  captionPrompt: string;
  isSelected: boolean;
} & Pick<
  FlowAsset,
  | 'platform'
  | 'platformId'
  | 'headline'
  | 'caption'
  | 'musicAuthor'
  | 'musicTitle'
  | 'createdAt'
  | 'videoRegenerationsLeft'
  | 'status'
  | 'errorMessage'
  | 'errorStep'
>;
