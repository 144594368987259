'use client';

import {gql} from '@/__generated__';
import ErrorMessage from '@/components/ErrorMessage';
import {useAppContext, useAuthContext, useToastQueue} from '@/hooks';
import {useApolloClient, useMutation} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button} from '@nextui-org/button';
import {ModalHeader, ModalBody, ModalFooter} from '@/components/Modal';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import NextUIInput from '@/components/inputs/NextUIInput';

const DELETE_USER_ACCOUNT = gql(`
  mutation DeleteUserAccount($input: DeleteUserAccountInput!, $id: ID) {
    user: deleteUserAccount(input: $input, id: $id) {
      id
    }
  }
`);

const DeleteAccountForm = () => {
  const {addSuccessToast} = useToastQueue();
  const {_} = useLingui();
  const {logout} = useAppContext();
  const {user} = useAuthContext();
  const apolloClient = useApolloClient();
  const [deleteUser, deleteUserResult] = useMutation(DELETE_USER_ACCOUNT, {
    onCompleted: () => {
      apolloClient.stop();
      addSuccessToast({message: _(msg`Your account has been deleted!`)});
      setTimeout(() => {
        logout(apolloClient);
      }, 5000);
    },
  });
  const fullName = [user?.firstName, user?.lastName].filter(Boolean).join(' ');

  return (
    <Formik
      initialValues={{
        fullName: '',
      }}
      validationSchema={Yup.object({
        fullName: Yup.string()
          .oneOf(
            [fullName],
            _(msg`Enter your full name to confirm: ${fullName}`),
          )
          .required(_(msg`Required`)),
      })}
      onSubmit={async values => {
        return deleteUser({variables: {id: user?.id, input: values}}).catch(
          () => null,
        );
      }}>
      {({isSubmitting, touched, errors, dirty}) => {
        const isFullNameInvalid = Boolean(touched.fullName && errors.fullName);

        return (
          <Form className="flex flex-1 flex-col">
            <ModalHeader>
              <div className="flex flex-col justify-center">
                <h1 className="text-lg font-semibold text-foreground">
                  <Trans>Delete your account</Trans>
                </h1>
                <p className="text-sm font-semibold text-[#e34800]">
                  <Trans>Warning! This action is irreversible!</Trans>
                </p>
              </div>
            </ModalHeader>
            <ModalBody className="gap-5">
              <Field
                as={NextUIInput}
                name="fullName"
                variant="bordered"
                label={_(msg`Full Name`)}
                labelPlacement="outside"
                description={_(
                  msg`Enter your full name to confirm: ${fullName}`,
                )}
                classNames={{label: 'text-sm font-medium'}}
                radius="sm"
                isInvalid={isFullNameInvalid}
                errorMessage={isFullNameInvalid ? errors.fullName : undefined}
                isRequired
                isDisabled={isSubmitting}
              />
              <ErrorMessage message={deleteUserResult.error?.message} />
            </ModalBody>
            <ModalFooter>
              <Button
                aria-label={_(msg`Delete account`)}
                data-amp-track-label={`Delete account`}
                fullWidth
                type="submit"
                variant="solid"
                radius="sm"
                className="bg-[#e34800] text-medium font-semibold text-foreground"
                isLoading={isSubmitting}
                isDisabled={!dirty || isSubmitting}>
                <Trans>Delete my account and all my data</Trans>
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeleteAccountForm;
