'use client';

import {useState, useEffect, useCallback} from 'react';
import {CountryCode, PhoneNumber} from 'libphonenumber-js';

interface PhoneNumberFormatterReturn {
  formatPhoneNumber: (number: string) => string;
  loading: boolean;
}

const usePhoneNumberFormatter = (): PhoneNumberFormatterReturn => {
  const [lib, setLib] = useState<{
    parsePhoneNumberFromString: (
      number: string,
      country?: CountryCode,
    ) => PhoneNumber | undefined;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Dynamically import libphonenumber-js when the hook is used
    import('libphonenumber-js')
      .then(module => {
        setLib({
          parsePhoneNumberFromString: module.parsePhoneNumberFromString,
        });
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to load libphonenumber-js:', error);
        setLoading(false);
      });
  }, []);

  /**
   * Formats a phone number by inferring the country.
   * If valid, returns the formatted number.
   * If invalid or lib is not loaded, returns the original number.
   *
   * @param number - The raw phone number string (e.g., "380123456789").
   * @returns The formatted phone number or the original number.
   */
  const formatPhoneNumber = useCallback(
    (number: string): string => {
      if (!lib) {
        // Library not loaded yet
        return number;
      }

      const phoneNumber = lib.parsePhoneNumberFromString(number);

      if (phoneNumber && phoneNumber.isValid()) {
        const international = phoneNumber.formatInternational();

        // Optional: Apply custom formatting based on inferred country
        // For simplicity, we'll use the standard international format
        return international;
      } else {
        // Invalid phone number
        return number;
      }
    },
    [lib],
  );

  return {formatPhoneNumber, loading};
};

export {usePhoneNumberFormatter};

export default usePhoneNumberFormatter;
