import linguiConfig from '../lingui.config';
import {i18n} from '@lingui/core';

type SupportedLocales = 'en';

export const locales = linguiConfig.locales as [SupportedLocales];

export async function dynamicActivate(locale: SupportedLocales) {
  const {messages} = await import(`./locales/${locale}.po`);

  i18n.load(locale, messages);
  i18n.activate(locale);
}
