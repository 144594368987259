'use client';

import {ReactNode} from 'react';
import {NextUIProvider} from '@nextui-org/react';
import {
  AppProvider,
  AuthProvider,
  ApolloProvider,
} from '@/components/providers';
import {AnalyticsProvider} from '@/components/providers/AnalyticsProvider';

export function Providers({children}: {children: ReactNode}) {
  return (
    <AnalyticsProvider>
      <AppProvider>
        <ApolloProvider>
          <NextUIProvider>
            <AuthProvider>{children}</AuthProvider>
          </NextUIProvider>
        </ApolloProvider>
      </AppProvider>
    </AnalyticsProvider>
  );
}
