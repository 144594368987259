'use client';

import {FC, useState} from 'react';
import {useOutlet} from 'react-router-dom';

const AnimatedOutlet: FC<{context?: unknown}> = ({context}) => {
  const outlet = useOutlet(context);
  const [frozenOutlet] = useState(outlet);

  return <>{frozenOutlet}</>;
};

export default AnimatedOutlet;
