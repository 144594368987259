'use client';

import {FC, memo, useMemo} from 'react';
import {useAppContext, useAuthContext} from '@/hooks';
import {Navigate, useLocation} from 'react-router-dom';
import AnimatedOutlet from '@/components/AnimatedOutlet';
import {AnimatePresence, motion} from 'framer-motion';
import PortalSpinner from '@/components/PortalSpinner';
import {Trans} from '@lingui/macro';
import {ModalBody, ModalHeader} from '@/components/Modal';
import {LegalFooter} from '../components/LegalFooter';

const Login: FC = memo(() => {
  const {pathname, search} = useLocation();
  const {user, isLoading} = useAuthContext();
  const {isAuthenticated} = useAppContext();
  const showLegal = pathname.endsWith('/login');
  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search],
  );

  if (
    isAuthenticated &&
    user &&
    user.verified &&
    queryParams?.showUnlock !== 'true' // showUnlock redirect is handled in useHandleUnlockRedirect hook
  ) {
    return <Navigate to="../.." replace />;
  }

  if (!user && isLoading) {
    return (
      <PortalSpinner
        classNames={{
          base: 'backdrop-blur-sm fixed inset-0 z-[1000]',
        }}
        size="lg"
      />
    );
  }

  return (
    <>
      <ModalHeader className="!px-0 pb-0">
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%)',
          }}
          className="flex w-full gap-3 pb-5 pl-5 pr-[3.25rem] md:pl-8 md:pr-[4.125rem]">
          <div className="flex min-h-8 flex-col justify-center">
            <h1 className="text-xl font-semibold text-foreground">
              {pathname.endsWith('/login/profile') ? (
                <Trans>Create an account</Trans>
              ) : (
                <Trans>Sign Up</Trans>
              )}
            </h1>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="mx-auto w-full max-w-lg gap-8">
        <AnimatePresence mode="wait">
          <motion.div
            className="flex shrink flex-col items-center justify-center self-stretch"
            key={pathname}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2}}>
            <AnimatedOutlet />
          </motion.div>
        </AnimatePresence>
        {showLegal && <LegalFooter />}
      </ModalBody>
    </>
  );
});

Login.displayName = 'LoginV2';

export default Login;
