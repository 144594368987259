import {memo, useEffect, useRef, useState} from 'react';
import {Divider} from '@nextui-org/divider';
import {useMutation} from '@apollo/client';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {msg, Trans} from '@lingui/macro';
import PortalSpinner from '@/components/PortalSpinner';
import {GENERATE_WHATSAPP_AUTH_INFO} from '@/common/mutations';
import CheckCircleSolidIcon from '@/components/icons/CheckCircleSolidIcon';
import {AnimatePresence, motion} from 'framer-motion';
import {useWhatsappNotifications} from '@/hooks/useWhatsappNotifications';
import {ModalBody, ModalHeader} from '@/components/Modal';
import whatsappLogo from '@/assets/whatsapp-logo.svg';
import {useLingui} from '@lingui/react';
import TrackButton from '@/components/buttons/TrackButton';
import {twMerge} from 'tailwind-merge';

const WhatsappNotificationsEnable = memo(() => {
  const {_} = useLingui();
  const backTimerRef = useRef<any>();
  const navigate = useNavigate();
  const [qrIsLoaded, setQrIsLoaded] = useState(false);
  const [authMutation, {data}] = useMutation(GENERATE_WHATSAPP_AUTH_INFO);
  const {
    isInitialLoading,
    optedInPhoneNumbersChanged,
    startPhoneNumberPolling,
    stopPhoneNumberPolling,
  } = useWhatsappNotifications();

  useEffect(() => {
    if (isInitialLoading) {
      return;
    }

    if (optedInPhoneNumbersChanged) {
      stopPhoneNumberPolling();

      if (!backTimerRef.current) {
        backTimerRef.current = setTimeout(() => {
          navigate(-1);
        }, 5000);
      }
    } else {
      authMutation();
      startPhoneNumberPolling();
    }
  }, [isInitialLoading, optedInPhoneNumbersChanged]);

  useEffect(() => {
    return () => {
      clearTimeout(backTimerRef.current);
    };
  }, []);

  if (isInitialLoading) {
    return <PortalSpinner />;
  }

  return (
    <>
      <ModalHeader>
        <div className="flex w-full items-center gap-3 text-base font-medium">
          <img src={whatsappLogo} alt="WhatsApp" className="h-14 w-14" />
          <div className="flex flex-col">
            <div className="text-sm font-medium uppercase text-foreground/50">
              <Trans>WhatsApp</Trans>
            </div>
            <div className="text-lg font-semibold">
              <Trans>Enable Notifications</Trans>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="grow flex-col gap-4">
        <AnimatePresence initial={false} mode={'wait'}>
          {optedInPhoneNumbersChanged ? (
            <motion.div
              key={'success'}
              className="flex w-full flex-col items-center gap-8 text-center">
              <div className="flex min-h-[20rem] flex-col items-center justify-center gap-8">
                <CheckCircleSolidIcon className="h-11 w-11 text-[#34C759]" />
                <div className="flex flex-col items-center gap-2 text-center">
                  <div className="text-base font-medium">
                    <Trans>WhatsApp Notifications Enabled</Trans>
                  </div>
                  <div className="text-sm">
                    <Trans>
                      From now on, you will receive alerts about your ads.
                    </Trans>
                  </div>
                </div>
              </div>
              <div className="text-sm">
                <Trans>You can change it anytime in Settings.</Trans>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key={'active'}
              className="flex w-full flex-col items-center gap-8">
              <div className="text-center text-sm text-foreground">
                <Trans>
                  Scan this QR code or click “Proceed to WhatsApp” and send us
                  the pre-filled message.
                </Trans>
              </div>
              <div className="rounded-2xl bg-foreground p-[0.625rem]">
                <img
                  className={twMerge(
                    'h-[10rem] w-[10rem] transition-opacity duration-400',
                    qrIsLoaded ? 'opacity-100' : 'opacity-0',
                  )}
                  src={data?.generateWhatsappAuthInfo?.qrCode}
                  onLoad={() => setQrIsLoaded(true)}
                  alt="Your QR Code"
                />
              </div>
              <div className="flex w-full items-center gap-3 text-sm text-foreground">
                <Divider className="flex w-auto flex-[1_0_auto] bg-default-200" />
                <Trans>or click</Trans>
                <Divider className="flex w-auto flex-[1_0_auto] bg-default-200" />
              </div>
              <TrackButton
                aria-label={_(msg`Proceed to WhatsApp`)}
                data-amp-track-label="Proceed to WhatsApp"
                as={RouterLink}
                // @ts-expect-error typing issue
                to={data?.generateWhatsappAuthInfo?.linkUrl}
                fullWidth
                variant="solid"
                radius="sm"
                color="primary"
                isLoading={isInitialLoading}
                className={'text-sm font-medium text-foreground'}
                spinner={
                  <PortalSpinner
                    classNames={{
                      circle1: 'border-b-foreground',
                    }}
                    size="sm"
                  />
                }>
                <Trans>Proceed to WhatsApp</Trans>
              </TrackButton>
              <div className="text-center text-xs text-foreground/50">
                <Trans>
                  By scanning the QR code or clicking “Proceed to WhatsApp” you
                  agree to receive WhatsApp notifications from Portal AI.
                </Trans>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ModalBody>
    </>
  );
});

WhatsappNotificationsEnable.displayName = 'WhatsappNotificationsEnable';

export default WhatsappNotificationsEnable;
