'use client';

import {SVGProps} from 'react';

export default function UserPlus02SolidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20 4C20 3.44772 19.5523 3 19 3C18.4477 3 18 3.44772 18 4V6H16C15.4477 6 15 6.44772 15 7C15 7.55228 15.4477 8 16 8H18V10C18 10.5523 18.4477 11 19 11C19.5523 11 20 10.5523 20 10V8H22C22.5523 8 23 7.55228 23 7C23 6.44772 22.5523 6 22 6H20V4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75864 14H11.2412C12.0462 14 12.7106 14 13.2518 14.0442C13.8139 14.0901 14.3306 14.1887 14.8159 14.436C15.5686 14.8195 16.1805 15.4314 16.564 16.184C16.8113 16.6694 16.9098 17.1861 16.9557 17.7482C17 18.2894 17 18.9537 16.9999 19.7587C16.9999 20.0414 17.0128 20.3278 16.9898 20.6098C16.9779 20.755 16.9475 20.963 16.8365 21.181C16.6926 21.4632 16.4632 21.6927 16.1809 21.8365C15.963 21.9476 15.755 21.978 15.6098 21.9899C15.4845 22.0001 15.3422 22.0001 15.2231 22C11.0743 21.9985 6.92556 21.9985 2.7768 22C2.65769 22.0001 2.51543 22.0001 2.39011 21.9899C2.24489 21.978 2.0369 21.9476 1.81896 21.8365C1.53671 21.6927 1.30724 21.4632 1.16343 21.181C1.05238 20.963 1.02194 20.755 1.01007 20.6098C0.987035 20.3278 0.999941 20.0413 0.999941 19.7587C0.999928 18.9537 0.999918 18.2894 1.04414 17.7482C1.09006 17.1861 1.18862 16.6694 1.43592 16.184C1.81941 15.4314 2.43133 14.8195 3.18398 14.436C3.66931 14.1887 4.18602 14.0901 4.74812 14.0442C5.28931 14 5.95367 14 6.75864 14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49994 7.5C4.49994 5.01472 6.51466 3 8.99994 3C11.4852 3 13.4999 5.01472 13.4999 7.5C13.4999 9.98528 11.4852 12 8.99994 12C6.51466 12 4.49994 9.98528 4.49994 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
