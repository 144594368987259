import {StatusBarColor, useStatusBar} from '@/hooks/useStatusBar';
import {ReactNode} from 'react';

const PageContainer = ({
  color,
  children,
}: {
  color?: StatusBarColor;
  children: ReactNode;
}) => {
  useStatusBar({color});

  return (
    <div
      style={
        color === 'primary'
          ? {
              background:
                'linear-gradient(180deg, #25005A 0%, #000 50%) no-repeat, #000',
              backgroundSize: '100% 50dvh, 100% auto',
            }
          : undefined
      }
      className="flex w-full grow flex-col items-center">
      {children}
    </div>
  );
};

export default PageContainer;
