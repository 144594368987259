'use client';

import {SVGProps} from 'react';

export default function Camera01SolidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55773 1C9.44753 0.999977 9.35166 0.999953 9.26018 1.00549C8.10334 1.07556 7.09033 1.80569 6.65804 2.88101C6.62385 2.96605 6.59355 3.05701 6.55873 3.16157L6.55133 3.18378C6.50494 3.32295 6.49488 3.35174 6.48633 3.37301C6.34223 3.73145 6.00456 3.97482 5.61895 3.99818C5.59634 3.99955 5.56241 4.00001 5.4056 4.00001L5.34974 4C5.03489 3.99994 4.80354 3.99989 4.60563 4.0195C2.70837 4.20748 1.20748 5.70837 1.0195 7.60562C0.9999 7.80349 0.999945 8.02554 1.00001 8.32536L1.00001 16.2413C1 17.0463 0.99999 17.7106 1.04421 18.2518C1.09013 18.8139 1.1887 19.3306 1.43599 19.816C1.81948 20.5686 2.4314 21.1805 3.18405 21.564C3.66939 21.8113 4.18609 21.9099 4.74819 21.9558C5.28938 22 5.95373 22 6.75869 22H17.2413C18.0463 22 18.7106 22 19.2518 21.9558C19.8139 21.9099 20.3306 21.8113 20.816 21.564C21.5686 21.1805 22.1805 20.5686 22.564 19.816C22.8113 19.3306 22.9099 18.8139 22.9558 18.2518C23 17.7106 23 17.0463 23 16.2413L23 8.32535C23.0001 8.02554 23.0001 7.80348 22.9805 7.60562C22.7925 5.70837 21.2917 4.20748 19.3944 4.0195C19.1965 3.99989 18.9651 3.99994 18.6503 4L18.5944 4.00001C18.4376 4.00001 18.4037 3.99955 18.3811 3.99818C17.9955 3.97482 17.6578 3.73145 17.5137 3.37301C17.5051 3.35174 17.4951 3.32295 17.4487 3.18378L17.4413 3.16156C17.4065 3.05704 17.3762 2.96603 17.342 2.88101C16.9097 1.80569 15.8967 1.07556 14.7399 1.00549C14.6484 0.999953 14.5525 0.999977 14.4423 1H9.55773ZM16 12.5C16 14.7091 14.2091 16.5 12 16.5C9.79086 16.5 8 14.7091 8 12.5C8 10.2909 9.79086 8.5 12 8.5C14.2091 8.5 16 10.2909 16 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
