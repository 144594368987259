'use client';

import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {ErrorParamHandlerContext} from '@/contexts/ErrorParamHandlerContext';
import {useSearchParam} from '@/hooks';
import {decodeErrorParam} from '@/utils';
import {ErrorURLParam} from '@/common/types';

export const ErrorParamHandlerProvider = memo(
  ({children}: PropsWithChildren) => {
    const [errorParam, setErrorParam] = useSearchParam('error');
    const [error, setError] = useState<ErrorURLParam | null>(null);

    useEffect(() => {
      if (!errorParam) {
        return;
      }

      const decoded = decodeErrorParam(errorParam);

      setError(decoded || null);
      setErrorParam('');
    }, [errorParam]);

    const resetError = useCallback((type?: ErrorURLParam['type']) => {
      setError(prev => {
        if (!prev || !type || prev.type === type) {
          return null;
        }

        return prev;
      });
    }, []);

    const value = useMemo(
      () => ({
        error,
        resetError,
      }),
      [error, resetError],
    );

    return (
      <ErrorParamHandlerContext.Provider value={value}>
        {children}
      </ErrorParamHandlerContext.Provider>
    );
  },
);

ErrorParamHandlerProvider.displayName = 'ErrorParamHandlerProvider';
