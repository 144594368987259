import ReactDOM from 'react-dom/client';
import {MODE} from './config';
import {registerSW} from 'virtual:pwa-register';
import App from '@/App';
import './yupSetup';
import './index.css';
import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev';

if (MODE === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />,
);

registerSW({immediate: true});
