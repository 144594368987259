import {useRef, useCallback} from 'react';

function useCanvasToShare(cb?: () => void) {
  const svgRef = useRef<SVGSVGElement>(null);

  const shareImage = useCallback(() => {
    if (!svgRef.current) {
      console.error('SVG element not found.');
      return;
    }

    const svgElement = svgRef.current;
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);

    const svgBlob = new Blob([svgString], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const url = URL.createObjectURL(svgBlob);

    const image = new Image();

    // Handle cross-origin images
    image.crossOrigin = 'anonymous';

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = svgElement.width.baseVal.value;
      canvas.height = svgElement.height.baseVal.value;

      const context = canvas.getContext('2d');

      if (!context) {
        console.error('Canvas context not available.');
        return;
      }

      context.drawImage(image, 0, 0);

      URL.revokeObjectURL(url);

      canvas.toBlob(
        blob => {
          if (!blob) {
            console.error('Failed to convert canvas to blob.');
            return;
          }

          const file = new File([blob], 'campaign.png', {type: 'image/png'});

          if (navigator.canShare && navigator.canShare({files: [file]})) {
            navigator
              .share({
                files: [file],
                title: 'Campaign Created',
                text: 'I just created a campaign!',
              })
              .then(() => console.log('Share was successful.'))
              .catch(error => {
                console.log('Sharing failed', error);
                // Fallback: Download the image
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'campaign.png';
                link.click();
              })
              .finally(() => {
                cb?.();
              });
          } else {
            console.error(
              'Web Share API is not supported or cannot share files.',
            );
            // Fallback: Download the image
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'campaign.png';
            link.click();
            cb?.();
          }
        },
        'image/png',
        1,
      );
    };

    image.onerror = error => {
      console.error('Image failed to load', error);
    };

    image.src = url;
  }, []);

  return {svgRef, shareImage};
}

export default useCanvasToShare;
