import {GetMetaPresetConnectionQuery} from '@/__generated__/graphql';
import {memo} from 'react';
import {twMerge} from 'tailwind-merge';
import facebookBadge from '@/assets/facebook-badge.png';
import instagramBadge from '@/assets/instagram-badge.png';
import {getUserName} from '@/utils';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {getIntegrationIcon} from '@/common/integrations';

interface Props {
  item: GetMetaPresetConnectionQuery['connection']['edges'][0]['node'];
  showUser?: boolean;
  className?: string;
}

const MetaPresetItem = memo(({item, showUser, className}: Props) => {
  const {_} = useLingui();
  const renderAvatar = (avatar: string, badge: string, className?: string) => (
    <div className={twMerge('relative flex h-11 w-11 shrink-0', className)}>
      <img src={avatar} alt="Avatar" className="h-full w-full rounded-full" />
      {badge && (
        <img
          src={badge}
          alt="Badge"
          className="absolute bottom-0 right-0 h-[1.125rem] w-[1.125rem]"
        />
      )}
    </div>
  );
  const pageAvatars = [item.pageAvatar, item.instagramAvatar].filter(
    Boolean,
  ) as string[];

  return (
    <div className={twMerge('flex w-full flex-col gap-4', className)}>
      {showUser ? (
        <div className="flex w-full items-center gap-3">
          {item.platformUser?.avatar &&
            renderAvatar(item.platformUser.avatar, facebookBadge)}
          <div className="flex grow flex-col">
            <div className="text-xs text-foreground">
              {getUserName(item.platformUser)}
            </div>
            <div className="text-xs text-foreground/50">
              <Trans>Facebook Profile</Trans>
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex w-full items-center gap-3">
        {pageAvatars.length === 2 ? (
          <div className="relative flex h-11 w-11 shrink-0">
            {renderAvatar(
              pageAvatars[0],
              facebookBadge,
              'absolute bottom-0 left-0 z-10 w-8 h-8',
            )}
            {renderAvatar(
              pageAvatars[1],
              instagramBadge,
              'absolute top-0 right-0 z-5 w-8 h-8',
            )}
          </div>
        ) : pageAvatars.length === 1 ? (
          renderAvatar(
            pageAvatars[0],
            item.instagramAvatar ? instagramBadge : facebookBadge,
          )
        ) : null}
        <div className="flex grow flex-col">
          <div className="text-xs text-foreground">
            {[item.pageName, item.instagramUsername]
              .filter(Boolean)
              .join(', @')}
          </div>
          <div className="text-xs text-foreground/50">
            {[
              item.pageName ? _(msg`Facebook Profile`) : null,
              item.instagramUsername ? _(msg`Instagram Account`) : null,
            ]
              .filter(Boolean)
              .join(', ')}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center gap-3">
        <div className="flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-foreground/10">
          <img
            src={getIntegrationIcon('meta_ads', 'small')}
            alt="Meta"
            className="max-h-6 max-w-6 shrink-0 object-contain"
          />
        </div>
        <div className="flex grow flex-col">
          <div className="text-xs text-foreground">
            {[item.adAccountName, item.pixelName ?? item.pixelId]
              .filter(Boolean)
              .join(', ')}
          </div>
          <div className="text-xs text-foreground/50">
            {[
              item.adAccountName ? _(msg`Ad account`) : null,
              (item.pixelName ?? item.pixelId) ? _(msg`Meta Pixel`) : null,
            ]
              .filter(Boolean)
              .join(', ')}
          </div>
        </div>
      </div>
    </div>
  );
});

MetaPresetItem.displayName = 'MetaPresetItem';

export default MetaPresetItem;
