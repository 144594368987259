import {FlowRunAssetTextType} from '@/__generated__/graphql';
import {REGENERATE_FLOW_RUN_ASSET_TEXT} from '@/common/mutations';
import {GET_ASSET} from '@/common/queries';
import {GeneratedVideoState} from '@/common/types';
import TrackButton from '@/components/buttons/TrackButton';
import FlipBackwardIcon from '@/components/icons/FlipBackwardIcon';
import {Modal, ModalContent, ModalHeader} from '@/components/Modal';
import PortalSpinner from '@/components/PortalSpinner';
import {useFlowContext} from '@/hooks';
import {useMutation} from '@apollo/client';
import {msg, Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ModalBody, Textarea} from '@nextui-org/react';
import {memo, useCallback, useMemo, useRef} from 'react';

export type TextPromptType = 'headline' | 'caption';

interface Props {
  asset: GeneratedVideoState;
  promptType: TextPromptType | null;
  setPromptType: (isOpen: TextPromptType | null) => void;
}

const EditAdPrompt = memo(({asset, promptType, setPromptType}: Props) => {
  const {_} = useLingui();
  const {run, updateGeneratedVideoState} = useFlowContext();
  const isChangedRef = useRef(false);
  const [regenerateText, {loading: regenerateLoading}] = useMutation(
    REGENERATE_FLOW_RUN_ASSET_TEXT,
    {
      refetchQueries: [GET_ASSET],
      onCompleted: () => {
        isChangedRef.current = true;
        setPromptType(null);
      },
    },
  );
  const stateKey =
    promptType === 'headline' ? 'headlinePrompt' : 'captionPrompt';

  const label = useMemo(() => {
    switch (promptType) {
      case 'headline':
        return _(msg`Headline Prompt`);
      case 'caption':
        return _(msg`Caption Prompt`);
      default:
        return '';
    }
  }, [promptType, _]);

  const onRevertPress = useCallback(() => {
    updateGeneratedVideoState({
      id: asset.id,
      data: {
        [stateKey]: run?.promptText ?? '',
      },
    });
  }, [asset.id, stateKey, run?.promptText, updateGeneratedVideoState]);

  const onPromptChange = useCallback(
    (value: string) => {
      updateGeneratedVideoState({
        id: asset.id,
        data: {
          [stateKey]: value,
        },
      });
    },
    [asset.id, stateKey, updateGeneratedVideoState],
  );

  const onRegeneratePress = useCallback(() => {
    if (regenerateLoading) {
      return;
    }

    regenerateText({
      variables: {
        input: {
          id: asset.id,
          textType:
            promptType === 'headline'
              ? FlowRunAssetTextType.Headline
              : FlowRunAssetTextType.Caption,
          prompt: asset[stateKey],
        },
      },
    });
  }, [asset, promptType, stateKey, regenerateLoading, regenerateText]);

  return (
    <Modal
      isOpen={Boolean(promptType)}
      onOpenChange={isOpen => {
        if (!isOpen && !isChangedRef.current) {
          onRevertPress();
        }

        setPromptType(isOpen ? promptType : null);
      }}>
      <ModalContent>
        <ModalHeader>
          <div className="flex w-full text-base font-medium">
            <Trans>Edit Prompt</Trans>
          </div>
        </ModalHeader>
        {run ? (
          <ModalBody className="mx-auto w-full max-w-lg gap-7 px-5 pb-5">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="text-sm text-foreground/50">{label}</div>
                <Textarea
                  classNames={{
                    inputWrapper: `relative w-full !shadow-none !opacity-100 px-3 py-2 rounded-lg bg-foreground/10 data-[hover=true]:bg-foreground/10 group-data-[focus=true]:bg-foreground/10`,
                    input: '!text-foreground',
                  }}
                  placeholder={_(msg`Your prompt...`)}
                  value={asset[stateKey]}
                  minRows={3}
                  maxRows={7}
                  isDisabled={regenerateLoading}
                  onValueChange={onPromptChange}
                />
              </div>
              <TrackButton
                aria-label={_(msg`Revert prompt`)}
                data-amp-track-label="Revert prompt"
                disableAnimation
                disableRipple
                className="h-auto min-h-0 w-auto min-w-0 shrink-0 gap-1 self-start bg-transparent p-0 text-sm font-light text-foreground/50"
                isDisabled={regenerateLoading}
                startContent={
                  <FlipBackwardIcon className="h-[0.875rem] w-[0.875rem]" />
                }
                onPress={onRevertPress}>
                <Trans>Revert</Trans>
              </TrackButton>
            </div>
            <TrackButton
              aria-label={_(msg`Regenerate prompt`)}
              data-amp-track-label="Regenerate prompt"
              className="w-full shrink-0 bg-primary px-4 py-2 text-sm font-medium text-foreground"
              isLoading={regenerateLoading}
              onPress={onRegeneratePress}>
              <Trans>Regenerate</Trans>
            </TrackButton>
          </ModalBody>
        ) : (
          <ModalBody className="p-8">
            <PortalSpinner />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
});

EditAdPrompt.displayName = 'EditAdPrompt';

export default EditAdPrompt;
