'use client';

import amazonSmall from '@/assets/integrations/iconsSmall/amazon.png';
import amazonAdsSmall from '@/assets/integrations/iconsSmall/amazon-ads.png';
import googleAdsSmall from '@/assets/integrations/iconsSmall/google-ads.png';
import facebookAdsSmall from '@/assets/integrations/iconsSmall/facebook-ads.png';
import shopifySmall from '@/assets/integrations/iconsSmall/shopify.png';
import amazonMedium from '@/assets/integrations/iconsMedium/amazon.png';
import amazonAdsMedium from '@/assets/integrations/iconsMedium/amazon-ads.png';
import googleAdsMedium from '@/assets/integrations/iconsMedium/google-ads.png';
import facebookAdsMedium from '@/assets/integrations/iconsMedium/facebook-ads.png';
import shopifyMedium from '@/assets/integrations/iconsMedium/shopify.png';
import shipbobMedium from '@/assets/integrations/iconsMedium/shipbob.png';
import shipbobSmall from '@/assets/integrations/iconsSmall/shipbob.png';
import intercomSmall from '@/assets/integrations/iconsSmall/intercom.svg';
import intercomMedium from '@/assets/integrations/iconsMedium/intercom.svg';
import instagramSmall from '@/assets/integrations/iconsSmall/instagram.svg';
import instagramMedium from '@/assets/integrations/iconsMedium/instagram.svg';
import tiktokSmall from '@/assets/integrations/iconsSmall/tiktok.svg';
import tiktokMedium from '@/assets/integrations/iconsMedium/tiktok.svg';

export const getSmallIntegrationIcon = (integration?: string | null) => {
  switch (integration) {
    case 'google_ads':
      return googleAdsSmall;
    case 'shopify':
      return shopifySmall;
    case 'amazon_ads':
      return amazonAdsSmall;
    case 'amazon_sp':
      return amazonSmall;
    case 'meta_ads':
      return facebookAdsSmall;
    case 'shipbob':
      return shipbobSmall;
    case 'intercom':
      return intercomSmall;
    case 'instagram':
      return instagramSmall;
    case 'tiktok':
      return tiktokSmall;
    default:
      return '';
  }
};

export const getMediumIntegrationIcon = (integration?: string | null) => {
  switch (integration) {
    case 'google_ads':
      return googleAdsMedium;
    case 'shopify':
      return shopifyMedium;
    case 'amazon_ads':
      return amazonAdsMedium;
    case 'amazon_sp':
      return amazonMedium;
    case 'meta_ads':
      return facebookAdsMedium;
    case 'shipbob':
      return shipbobMedium;
    case 'intercom':
      return intercomMedium;
    case 'instagram':
      return instagramMedium;
    case 'tiktok':
      return tiktokMedium;
    default:
      return '';
  }
};

export const getIntegrationIcon = (
  integration: string | null | undefined,
  size: 'small' | 'medium',
) => {
  return size === 'small'
    ? getSmallIntegrationIcon(integration)
    : getMediumIntegrationIcon(integration);
};

export const getIntegrationTitle = (integration?: string | null) => {
  switch (integration) {
    case 'google_ads':
      return 'Google Ads';
    case 'shopify':
      return 'Shopify';
    case 'amazon_ads':
      return 'Amazon Ads';
    case 'amazon_sp':
      return 'Amazon SP';
    case 'meta_ads':
      return 'Meta Ads';
    case 'shipbob':
      return 'ShipBob';
    case 'intercom':
      return 'Intercom';
    case 'instagram':
      return 'Instagram';
    case 'tiktok':
      return 'TikTok';
    default:
      return '';
  }
};

export const getDataUsedTitle = (integration?: string | null) => {
  return getIntegrationTitle(integration);
};

export const getActionImage = (integration?: string | null) => {
  switch (integration) {
    case 'google_ads':
      return googleAdsMedium;
    case 'shopify':
      return shopifyMedium;
    case 'amazon_ads':
      return amazonAdsMedium;
    case 'amazon_sp':
      return amazonMedium;
    case 'meta_ads':
      return facebookAdsMedium;
    case 'shipbob':
      return shipbobMedium;
    case 'intercom':
      return intercomMedium;
    case 'instagram':
      return instagramMedium;
    case 'tiktok':
      return tiktokMedium;
    default:
      return '';
  }
};
